@import 'src/style/colors';
@import 'src/style/fonts';

.trailSec {
  background: $color-black-medium;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  margin-left: 75px;
  padding-left: 16px;
  position: fixed;
  transition: width 0.2s;
  @media screen and (max-width: 768px) {
    transition: none !important;
  }

  .pricing {
    align-items: center;
    border-radius: 4px;
    color: #e8e8e8;
    cursor: pointer;
    display: flex;
    flex-grow: 1;
    font-size: 15px;
    font-weight: 500;
    height: 36px;
    letter-spacing: -0.01em;
    line-height: 22px;
    overflow: hidden;
    padding: 3px 10px;
    white-space: nowrap;

    &:hover {
      background: rgba(78, 81, 86, 0.2);
      color: #fff;
    }

    &.active {
      background: rgba(136, 96, 212, 0.2) !important;
      color: #fff;
    }
 
  }


  a {
    align-items: center;
    border-radius: 4px;
    color: #e8e8e8;
    display: flex;
    flex-grow: 1;
    font-size: 15px;
    font-weight: 500;
    height: 36px;
    letter-spacing: -0.01em;
    line-height: 22px;
    overflow: hidden;
    padding: 3px 10px;
    white-space: nowrap;

    &:hover {
      background: rgba(78, 81, 86, 0.2);
      color: #fff;
    }

    &.active {
      background: rgba(136, 96, 212, 0.2) !important;
      color: #fff;
    }
  }

}

.opacity {
  background: linear-gradient(180deg, rgba(31, 33, 37, 0) 0%, #1f2125 100%);
  border-bottom: 1px solid #333539;
  bottom: 147px;
  height: 40px;
  left: 0;
  margin-bottom: 0;
  position: fixed;
}

.header {
  &__sca-menu-item {
    align-items: center;
    color: $color-gray-light;
    cursor: pointer;
    display: flex;
    margin-top: 3px;
    padding: 5px;
    transition: all 0.3s ease;
    white-space: nowrap;

    &:hover {
      color: $color-white;
    }

    svg {
      font-size: 22px;
      margin-left: 10px;
    }
  }
}

.sidebarCTA {
  background: #24262a !important;
  color: $color-light-gray;
  font-size: $font-size-12;
  font-weight: 300;
  margin: 20px 15px 10px;
  
  @media (max-width:992px) {
    display: none;
  }
}

.testPageLink {
  align-items: center;
  border-radius: 4px;
  color: #e8e8e8;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  font-size: 15px;
  font-weight: 500;
  height: 36px;
  letter-spacing: -0.01em;
  line-height: 22px;
  overflow: hidden;
  padding: 3px 10px;
  white-space: nowrap;

  &:hover {
    background: rgba(78, 81, 86, 0.2);
    color: #fff;
  }
}


.postOnboardingBanner {
  background-color: #b98ce426;
  border-radius: 8px;
  color: #fff;
  margin-bottom: 17px;
  margin-left: 2px;
  padding: 10px;
  position: relative;
  text-align: left;

  &.dockedSidebar {
    background-color: transparent;
    padding-left: 8px;
  }

  .postOnboardingCloseIcon {
    cursor: pointer;
    display: none;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &:hover {
    .postOnboardingCloseIcon {
      display: block;
    }
  }
}

.keywordBannerButton {
  align-items: center;
  display: flex;
  margin-top: 13px;

  span {
    align-items: center;
    display: flex;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 600;
  }
}

.menu {
  left: 70px !important;
  @media screen and (max-width: 768px) {
    max-height: 500px;
    overflow: scroll;
  }

  .modifiedItem {
    cursor: pointer;
    padding: 0 10px;
  }

  .tierButton {
    align-items: center;
    // background: rgba(70, 71, 74, 0.8);
    border-radius: 5px;
    color: #e8e8e8;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    height: 50px;
    line-height: 15px;
    margin-bottom: 10px;
    padding-left: 12px;
    text-align: left;
    width: 100%;

    .tierText {
      color: #e8e8e8;
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
      text-transform: capitalize;
    }
  }

  // .tierButton:hover {
  //   background: rgba(70, 71, 74, 0.8);
  //   color: #e8e8e8;
  // }

  .menuBtn {
    color: #000;
    font-weight: 400;
    padding-left: 0;
    text-align: left;
    width: 100%;

    &:hover {
      color: #000;
    }

    :global(.anticon) {
      color: $color-icons;
    }
  }

  .zoomTextWithLink {
    color: #e8e8e8;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    padding: 5px 12px;

    a {
      color: #34aef3;
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-weight: 400;
      text-decoration-line: underline;
    }
  }

  .zoomBtnWrapper {
    display: flex;
    flex-direction: column;
    padding: 5px 12px;
    
    .zoomBtnText {
      color: #e8e8e8;
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 8px;
      text-align: center;
    }

    .zoomBtn {
      align-items: center;
      background-color: #2ac155;
      border-color: transparent;
      border-radius: 6px;
      color: #fff;
      display: flex;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 500;
      gap: 8px;
      justify-content: center;
      padding: 6px 15px !important;

      &:hover {
        background-color: rgba(42, 193, 85, 0.8);
      }
    }
  }

  .logout {
    color: $color-red;
    font-weight: 500;
    height: 100%;
    position: relative;
    text-align: center;
    width: 100%;
  }

  ul,
  li {
    // background: #313337;
  }

  :global(.ant-dropdown-menu) {
    border-radius: 8px !important;
    min-width: 200px;
    padding: 10px 0 4px !important;
  }

  :global(.ant-dropdown-menu-item) {
    height: 32px;

    &:hover {
      background: rgba(127, 78, 173, 0.17);

      svg {
        color: #936bda !important;
      }
    }

    &:active {
      background: rgba(127, 78, 173, 0.17);

      svg {
        color: #936bda !important;
      }
    }

    &:focus {
      background: rgba(127, 78, 173, 0.17);

      svg {
        color: #936bda !important;
      }
    }
  }

  :global(.ant-dropdown-menu-item-divider) {
    background: rgba(255, 255, 255, 0.1);
    margin: auto;
    width: 80%;
  }
}

.dropdownOffset {
  margin-top: -18px !important;
  @media screen and (max-width: 768px) {
    margin-top: -22px !important;
  }
}

.dropdown {
  align-items: center;
  color: #ffff;
  cursor: pointer;
  display: flex;
  gap: 5px;

  span {
    color: #e8e8e8;
  }

  .avatar {
    border: 2px solid rgba(255, 255, 255, 0.65);
  }

  .down-icon {
    color: #a3a4a4;
    height: 40px;

    svg {
      width: 10px;
    }
  }

  &:hover {
    .avatar {
      border: 2px solid rgba(255, 183, 226, 0.65);
    }

    .down-icon {
      svg {
        path {
          fill: $color-white;
        }
      }
    }
  }
}
