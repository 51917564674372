@import '@/style/colors';
@import 'src/style/fonts';

.commonComponentsCheckbox {
  :global(.ant-checkbox-inner) {
    border-radius: 5px !important;
  }

  :global(.ant-checkbox + span) {
    color: #121212;
    cursor: pointer;
    padding-right: 0;
  }

  :global(.ant-checkbox-wrapper) {
    align-items: center;
    display: flex;
    margin-right: 5px;
  }

  :global(.ant-checkbox-checked .ant-checkbox-inner) {
    background-color: #7f4ead !important;
  }

  :global(.ant-checkbox-checked::after ) {
    border: 0;
  }

  :global(.ant-checkbox-input:focus + .ant-checkbox-inner) {
    border-color: #d3d3d3;
  }

  :global(.ant-checkbox-input:hover + .ant-checkbox-inner) {
    border-color: #8b8585;
  }

  :global(.rc-checkbox-inner) {
    border-radius: none;
    height: 14px;
    width: 15px;

    &::after {
      border-width: 2px;
      left: 4px;
      top: 0;
    }
  }

  :global(.label) {
    font-family: Inter;
    font-size: $font-size-14;
    font-style: normal;
    font-weight: normal;
    left: 7px;
    line-height: 17px;
    position: relative;
  }
}

.commonComponentsCheckbox.darken {
  :global(.rc-checkbox-checked) {
    :global(.rc-checkbox-inner) {
      background: linear-gradient(180deg, #383542 0%, #25242b 100%);
      border: 1px solid $color-light-gray-secondary;

      &::after {
        border-color: $color-non-primary-text;
      }
    }
  }

  :global(.label) {
    color: $color-non-primary-text;
  }
}

.commonComponentsCheckbox.primary {
  :global(.rc-checkbox-checked) {
    :global(.rc-checkbox-inner) {
      background: $color-purple-medium;
      border-color: $color-purple-medium;

      &::after {
        border-color: $color-non-primary-text;
      }
    }
  }
}

.transparentCheckbox.darken {
  :global(.rc-checkbox-inner) {
    background: transparent;
  }

  :global(.rc-checkbox-inner::after) {
    opacity: 0;
  }

  :global(.rc-checkbox-checked) {
    :global(.rc-checkbox-inner) {
      background: transparent;
      border-color: $color-light-gray-secondary;
    }
  }

  :global(.rc-checkbox:hover .rc-checkbox-inner),
  :global(.rc-checkbox-input:focus + .rc-checkbox-inner) {
    border-color: $color-light-gray-secondary;
  }

  :global(.rc-checkbox-checked) {
    :global(.rc-checkbox-inner::after) {
      border-color: $color-non-primary-text !important;
      opacity: 1;
    }
  }

  :global(.label) {
    color: $color-non-primary-text;
  }
}
