@import '@/style/colors';
@import 'src/style/fonts';

.searchConsolePopup {
  height: 580px;
  width: 570px;

  :global(.rc-dialog-close-x) {
    &::before {
      background: transparent !important;
      color: #fff !important;
    }

    &::after {
      background: transparent !important;
      color: #fff !important;
      content: '×';
      font-size: $font-size-46 !important;
      height: 10px;
      margin-top: -10;
      position: absolute;
      right: -2rem;
      top: 0;
      transform: matrix(1, 0, 0, -1, 0, 0);
      width: 10px;
    }
  }

  :global(.rc-dialog-body) {
    background: #f2f2f5;
    border-radius: 8px;
    box-shadow: 0 4px 21px rgba(0, 0, 0, 0.02);
    padding: 50px;

    .body {
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .consoleCheckbox {
          display: flex;
          flex-direction: column;
          margin: 0 auto;
          position: relative;

          .blurSection {
            backdrop-filter: blur(3px);
            background: rgba(255, 255, 255, 0.2);
            height: 55px;
            left: 50%;
            position: absolute;
            top: 21%;
            transform: translateX(-50%);
            width: 250px;
          }

          label {
            animation: scaleUp 0.3s ease-in;
            background: $color-white;
            border-radius: 5px;
            box-shadow: 0 3px 11px rgba(0, 0, 0, 0.25);
            left: 0;
            padding: 20px 16px;
            position: absolute;
            top: 74%;
            transform: scale(1);
            width: 385px;
          }
        }

        img {
          height: 290.02px;
          width: 368px;
        }

        p {
          color: #121212;
          font-size: 14px;
          font-weight: normal;
          line-height: 22px;
          text-align: center;
          width: 460px;
        }

        h2 {
          color: #121212;
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          padding-top: 30px;
          text-align: center;
        }
      }

      .footer {
        display: flex;
        justify-content: center;

        button {
          background: #2d6cca;
          border-radius: 5px;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          height: 38px;
          line-height: 22px;
          min-width: 206px;
          text-align: center;
          width: auto;
        }
      }
    }
  }

}
//Animation
@keyframes scaleUp {
  from {
    left: -2px;
    top: 80%;
    transform: scale(0.68);
  }
}
