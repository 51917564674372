@import 'src/style/colors';
@import 'src/style/fonts';

.upgradeDrawer {
  z-index: 9999 !important;

  :global(ant-drawer-content-wrapper) {
    overflow: auto;
  }

  :global(.ant-drawer-body) {
    :global(.ant-drawer-wrapper-body) {
      overflow: auto;

      :global(.ant-drawer-header) {
        background: #fff !important;
        border-bottom: transparent !important;
        padding: 32px 24px 16px;
      }

      :global(.ant-drawer-title) {
        color: #121212;

        .title {
          display: flex;
          justify-content: space-between;
        }

        p {
          color: #4e5156;
          font-size: 14px;
          font-weight: normal;
          line-height: 22px;
          margin-bottom: 0;
        }

        h2 {
          font-size: 20px;
          font-weight: 600;
          line-height: 32px;
        }
      }
    }
  }

  .content {
    position: relative;


  }

  .planDetail {
    display: flex;
    justify-content: space-between;

    .planCardSecond {
      background: #592d82;
      border-radius: 12px !important;
      border-radius: 5px;
      box-sizing: border-box;
      display: flex;
      height: auto;
      justify-content: flex-start;
      width: 240px;

      &.planCardActive {
        background: $color-white;
        border: 1px solid #b98ce4;
        box-shadow: 0 6px 17px rgba(0, 0, 0, 0.07);
        box-sizing: border-box;
      }

      .cardIcon {
        margin-bottom: 1rem;
          
      }

      .planBox {
        margin-bottom: 10px;
        overflow: hidden;
        padding: 20px 0 0 20px;
        width: 240px !important;

        .planName {
          color: #fff;
          display: flex !important;
          flex-wrap: wrap;
          font-family: 'Inter';
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;

          .popular {
            align-items: center;
            background: #7f4ead;
            border-radius: 34px;
            color: #fff;
            display: flex;
            font-size: 12px;
            font-weight: 500;
            height: 23px;
            justify-content: center;
            justify-content: space-between;
            line-height: 15px;
            margin-left: 5px;
            width: 102px;
          }

          h3 {
            color: #121212;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            margin-bottom: 0;
          }
        }

        .planDescription {
          color: #e8e8e8;
          font-family: 'Inter';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          margin-bottom: 10px;

        }

        .planPrice {
          color: #fff;
          font-family: 'Inter';
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 39px;
          margin-bottom: 15px;

          span {
            font-size: 14px;
            font-weight: normal;
          }
        }

        .planUpgradeButtonSection {
          margin-bottom: 15px;
        }

        .planUpgradeButton {
          background: #7f4ead;
          border-radius: 5px;
          color: #fff;
          height: 44px;
          justify-content: center;
          width: 175px;

        }

        .disabledButton {
          background: #fff;
          border: 1px solid #e8e8e8;
          color: #4e5156;
          height: 44px;
          width: 175px;

        }

        .activeBtn {
          background: #7f4ead;
          color: #fff;
          height: 44px;
          width: 175px;
        }

        .planIconArea {
          align-items: baseline;
          display: flex;
          margin-bottom: 6px;
        }

        .comingSoon {
          align-items: center;
          background: #daf1e2;
          border-radius: 34px;
          color: #2ac155;
          display: flex;
          font-size: 12px;
          font-weight: normal;
          height: 19px;
          justify-content: center;
          line-height: 15px;
          margin-left: 5px;
          width: 93px;
        }

        .planProviderIcon {
          align-items: center;
          background: #3f1a63;
          border: 1px solid #3f1a63;
          border-radius: 34px;
          box-sizing: border-box;
          display: flex;
          height: 23px;
          justify-content: center;
          margin-right: 5px;
          width: 30px;
        }

        .planDescriptionText {
          color: #e8e8e8;
          font-family: 'Inter';
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 17px;
        }

        .projects {
          color: #121212;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          margin-bottom: 5px;
          margin-top: 5px;

        }

        .details {
          color: #4e5156;
          font-size: 14px;
          font-weight: normal;
          line-height: 20px;
          white-space: break-spaces;

        }

        .priceArea {
          color: #4e5156;
          font-size: 14px;
          font-weight: normal;
          line-height: 20px;
          margin-bottom: 0;

          span {
            color: #121212;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
          }

        }
      }
    }

    .planCard {
      // background: $color-white-primary;
      // border: 1px solid $color-light-gray;
      border-radius: 5px;
      box-sizing: border-box;
      display: flex;
      height: auto;
      justify-content: flex-start;
      width: 240px;

      &.planCardActive {
        background: $color-white;
        border: 1px solid #b98ce4;
        box-shadow: 0 6px 17px rgba(0, 0, 0, 0.07);
        box-sizing: border-box;
      }

      .cardIcon {
        margin-bottom: 1rem;
          
      }

      .planBox {
        margin-bottom: 10px;
        overflow: hidden;
        padding: 20px 0 0 20px;
        width: 240px !important;

        .planName {
          color: #121212;
          display: flex !important;
          flex-wrap: wrap;
          font-family: 'Inter';
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;

          .popular {
            align-items: center;
            background: #ede8f3;
            border-radius: 34px;
            color: #7f4ead;
            display: flex;
            font-size: 12px;
            font-weight: 500;
            height: 23px;
            justify-content: center;
            line-height: 15px;
            margin-left: 5px;
            width: 102px;
          }

          h3 {
            color: #121212;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            margin-bottom: 0;
          }
        }

        .planDescription {
          color: #4e5156;
          font-family: 'Inter';
          font-size: 14px;
          height: 36px;
          line-height: 18px;
          margin-bottom: 10px !important;

        }

        .planPrice {
          color: #121212;
          font-family: 'Inter';
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 39px;
          margin-bottom: 15px;
          

          span {
            font-size: 14px;
            font-weight: normal;
          }
        }

        .planUpgradeButtonSection {
          margin-bottom: 15px;
        }

        .planUpgradeButton {
          background: #7f4ead;
          border-radius: 5px;
          color: #fff;
          height: 44px;
          justify-content: center;
          width: 175px;

        }

        .disabledButton {
          background: #fff;
          border: 1px solid #e8e8e8;
          color: #4e5156;
          height: 44px;
          width: 175px;
        }

        .activeBtn {
          background: #7f4ead;
          color: #fff;
          height: 44px;
          width: 175px;
        }

        .planIconArea {
          align-items: baseline;
          display: flex;
          margin-bottom: 6px;
        }

        .comingSoon {
          align-items: center;
          background: #daf1e2;
          border-radius: 34px;
          color: #2ac155;
          display: flex;
          font-size: 12px;
          font-weight: normal;
          height: 19px;
          justify-content: center;
          line-height: 15px;
          margin-left: 5px;
          width: 93px;
        }

        .planProviderIcon {
          align-items: center;
          background: #fff;
          border: 1px solid #e8e8e8;
          border-radius: 34px;
          box-sizing: border-box;
          display: flex;
          height: 23px;
          justify-content: center;
          margin-right: 5px;
          width: 30px;
        }

        .planDescriptionText {
          color: #4e5156;
          font-family: 'Inter';
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 17px;
        }

        .projects {
          color: #121212;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          margin-bottom: 5px;
          margin-top: 5px;

        }

        .details {
          color: #4e5156;
          font-size: 14px;
          font-weight: normal;
          line-height: 20px;
          white-space: break-spaces;

        }

        .priceArea {
          color: #4e5156;
          font-size: 14px;
          font-weight: normal;
          line-height: 20px;
          margin-bottom: 0;

          span {
            color: #121212;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
          }

        }
      }
    }

  }


  :global(.ant-drawer-body) {
    background: #fff !important;

    .planArea {
      .planHeading {
        align-items: baseline;
        display: flex !important;
        justify-content: space-between;

        .customizePlan {
          background-color: transparent;
          color: #1890ff;
          cursor: pointer;
          font-size: 12px;
          font-weight: normal;
          line-height: 15px;
          outline: none;
          padding: 0;
          text-align: right;
          text-decoration: none;
          transition: color 0.3s;
        }

        h2 {
          color: #121212;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }

        span {
          color: #4e5156;
          font-size: 12px;
          font-weight: normal;
          line-height: 15px;
          text-align: right;
        }
      }
    }
  }

  .additionalOption {
    display: flex;
    flex-direction: column;

    .heading {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      h2 {
        color: #121212;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }

      a {
        background: transparent;
        color: #2d6cca;
        font-size: 14px;
        line-height: 17px;
        padding: 0;
        text-align: right;
      }

      span {
        color: #4e5156;
        font-size: 12px;
        font-weight: normal;
        line-height: 15px;
        text-align: right;
      }
    }
  }

  .footerSection {
    display: flex;
    justify-content: space-between;

    .sectionBox1 {
      background: #18191b;
      border-radius: 8px;
      box-shadow: 0 4px 21px rgba(0, 0, 0, 0.02);
      padding: 20px;
      position: relative;
      width: 492px;

      h2 {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }

      p {
        color: #e8e8e8;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        width: 300px;
      }

      button {
        align-items: center;
        background: #2d6cca;
        border-radius: 5px;
        color: #fff;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        height: 38px;
        justify-content: center;
        line-height: 17px;
        padding: 0;
        width: 209px;
      }

      img {
        bottom: 0;
        position: absolute;
        right: 0;
      }
    }

    .sectionBox2 {
      background: #f2edf7;
      border-radius: 8px;
      box-shadow: 0 4px 21px rgba(0, 0, 0, 0.02);
      padding: 20px;
      position: relative;
      width: 492px;

      h2 {
        color: #121212;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }

      p {
        color: #121212;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        width: 300px;
      }

      a {
        align-items: center;
        background: #7f4ead;
        border-radius: 5px;
        color: #fff;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        height: 38px;
        justify-content: center;
        line-height: 17px;
        padding: 0;
        width: 157px;
      }

      img {
        bottom: 0;
        position: absolute;
        right: 0;
      }
    }
  }
}

.scaPlanIconArea {
  align-items: baseline;
  display: flex;

  .scaPlanDescriptionText {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.01em;
    line-height: 24px;
  }
}
