@import '@/style/colors';

.numberBlock {
  border-radius: 3px;
  color: $color-white;
  display: inline-block;
  min-width: 25px;
  padding: 0 3px;
  text-align: center;
}

.red {
  background: $color-red;
}

.orange {
  background: $color-orange;
}

.green {
  background: $color-green;
}
