@import 'src/style/colors';
@import 'src/style/fonts';



// .modal {
//   width: 320px;

//   :global(.rc-dialog-content) {
//     height: 790px;
//   }

//   :global(.rc-dialog-header) {
//     padding-top: 3rem;
//   }

//   :global(.rc-dialog-close ) {
//     color: #fff;
//     font-size: 32px;
//     opacity: 1;
//     right: -40px;
//     top: -10px;
//   }

//   &Header {
//     background: #f9f9fb;
//     border-radius: 8px 8px 0 0;
//     box-sizing: border-box;
//     display: flex;
//     padding: 30px 27px 23px;
//     @media (max-width: 700px) {
//       border-radius: 0 0 8px 8px;
//       box-shadow: 4px 6px 25px rgba(0, 0, 0, 0.17);
//       padding: 0 2.7em 1rem;
//     }

//     &Text {
//       flex: 1;

//       > h4 {
//         color: #121212;
        
//         font-size: 14px;
//         font-weight: normal;
//         line-height: 18px;
//         max-width: 180px;
        
//       }
//     }
//   }
// }

.changePaymentMethod {
  width: 720px;

  @media (max-width: 830px) {
    width: 100%;
    
    :global(.rc-dialog-content) {
      margin-left: auto;
      margin-right: auto;
      width: 85% !important;
    }
  }

  :global(.rc-dialog-body) {
    padding: 0;
  }

  .content {
    border-bottom: 1px solid $color-gray-light;
    padding: 15px;

    .heading {
      color: $color-gray-dark;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }

    .cardDetail {
      height: 55vh;
      margin-bottom: 20px;
      overflow: auto;
      padding-top: 10px;

      :global(.ant-radio-group) {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        margin-left: 0;
        @media (max-width: 830px) {
          display: flex;
          justify-content: space-between;
          margin-left: 0;
        }
        @media (max-width: 730px) {
          align-items: center;
          flex-direction: column;
        }
      }
    }

    .lock {
      align-items: center;
      display: flex;
      justify-content: flex-start;

      .labelBottom {
        color: #a3a4a4;
      }

      img {
        margin-right: 10px;
      }
    }
  }

  .paymentCard {
    align-items: center;
    background: transparent;
    border: 1px solid $color-white-secondary;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    height: 190px;
    justify-content: center;
    margin: 6px;
    width: 320px;
    @media screen and (max-width: 830px) {
      margin-left: 0 !important;
      margin-right: 0 !important;
      max-width: 360px;
      width: 49% !important;
    }
    @media screen and (max-width: 560px) {
      width: 100% !important;
    }


    &.newPaymentCard {
      border: 1px dashed $color-white-secondary;
    }

    &.paymentCardActive {
      background: $color-white;
      border: 1px solid #b98ce4;
      box-shadow: 0 6px 17px rgba(0, 0, 0, 0.07);
      box-sizing: border-box;
      position: relative;

      .checked {
        position: absolute;
        right: -8px;
        top: -8px;
      }
    }

    .newCard {
      align-items: center;
      background: transparent;
      display: flex;
      height: 190px;
      justify-content: center;
      text-align: center;
      width: 260px;

      img {
        background: transparent;
        height: 72.58px;
        width: 93px;
      }
    }

    :global(.ant-radio-wrapper) {
      margin-right: 0 !important;

      :global(.ant-radio) {
        display: none;
      }
    }

    :global(.ant-radio-checked .ant-radio-inner) {
      border-color: $color-purple-medium !important;
      display: none;
    }

    :global(.ant-radio-checked .ant-radio-inner::after) {
      background-color: $color-purple-medium;
    }

    :global(.ant-radio:hover .ant-radio-inner) {
      border-color: $color-purple-medium;
    }

    .paymentDetail {
      display: flex;
      flex-direction: column;
      height: 190px;
      width: 265px;

      .images {
        display: flex;
        justify-content: space-between;

      }
      

      h2 {
        color: $color-gray-light;
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        margin-bottom: 0;
      }

      img {
        height: 32px;
        width: 32px;
      }

      .removeCardText {
        color: #121212;
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        line-height: 150.52%;
        margin-top: 10px;
      }

      .removeCardButtonsContainer {
        display: flex;
        margin-top: 14px;

        .removeCardButtons {
          color: #2d6cca;
          font-size: 14px;
          font-style: normal;
          font-weight: normal;
          line-height: 150.52%;
        }
      }

      .paymentInfo {
        display: flex;
        flex-direction: column;

        span {
          color: $color-gray-dark;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          padding: 4px 0;

        }

        .numberLabel { 
          color: #a3a4a4;
          font-size: 12px;
          font-weight: 600;
          line-height: 15px;
          padding: 5px 0;
          text-transform: uppercase;
        }

        p {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0;

          span {
            color: $color-gray-dark-secondary;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
          }
        }

        .nameLabel {
          color: #a3a4a4;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 15px;
          text-transform: uppercase;
        }
      }
    }
  }

  .removeButton {
    display: none;
  }

  .setPrimaryButton {
    display: none;
  }

  .setPrimaryCardChecked {
    align-items: center;
    background: rgba(127, 78, 173, 0.1);
    border-radius: 34px;
    color: #7f4ead;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    padding: 0 13px 0 9px;
  }

  .paymentCard:hover {
    .removeButton {
      color: #2d6cca;
      display: block;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      line-height: 150.52%;
      margin-right: 12px;
      padding: 5px;
    }

    .setPrimaryButton {
      background: rgba(45, 108, 202, 0.11);
      border-radius: 5px;
      color: #2d6cca;
      display: block;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      line-height: 22px;
      padding: 5px 15px;
    }
  }


  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 30px;
    @media screen and (max-width: 560px) {
      justify-content: center;
      padding: 15px;
    }

    button {
      height: 38px;
    }

    .cancel {
      align-items: center;
      background: $color-white;
      border: 1px solid $color-white-secondary;
      border-radius: 5px;
      box-sizing: border-box;
      color: #4e5156;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      height: 38px;
      justify-content: center;
      line-height: 22px;
      margin-right: 10px;
      width: 86px;
    }

    .payForInvoice {
      align-items: center;
      display: flex;
      @media (max-width: 720px) {
        padding: 0;
      }
      @media (max-width: 560px) {
        margin: 0 !important;
        padding: 0 10px;
      }

      img {
        margin-right: 5px;
        margin-top: -4px;
      }
    }

  }
}


.closeIcon {
  color: rgb(255, 255, 255);
  opacity: 0.6;
  position: absolute;
  right: -65px;
  top: 25%;
  transform: matrix(1, 0, 0, -1, 0, 0);
}
  
// .closeIcon:hover {
//  opacity: 1 !important;
// }
  
.closeIcon {
  svg {
    height: 20px;
    overflow: visible;
    position: absolute;
    right: 0;
    top: -10px;
    width: 44px;
    
  }
}
