.notification {
  align-items: center;
  border-radius: 8px !important;
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.18) !important;
  display: flex;
  padding-bottom: 20px;
  padding-left: 7px;
  padding-right: 25px;
  padding-top: 20px;
  z-index: 999;

  :global(.ant-notification-notice-message) {
    margin-left: 0 !important;
    @media screen and (max-width: 450px) {
      padding-right: 10px !important;
    }
  }

  :global(.ant-notification-notice-icon) {
    bottom: 1px !important;
    font-size: 17px;
    right: 6px !important;

    &::before {
      border-radius: 30px;
      /* margin-top: 40px; */
      bottom: -18px;
      content: '';
      display: inline-block;
      height: 60px;
      position: absolute;
      right: -20px;
      width: 60px;
    }
  }

  :global(.ant-notification-notice-with-icon) {
    align-items: center;
    display: flex;
  }

  .timeStamp {
    color: #121212;
    font-size: 12px;
  }

  .issueTypeContainer,
  .issueUrl {
    font-size: 12px;
  }

  .issueUrl {
    cursor: pointer;
    line-height: 12px;
  }

  .issueStatus {
    font-size: 12px;
    font-weight: 500;
    margin-left: 5px;
  }

  .notificationContent {
    border-left: 3px solid #f4434380;
    display: flex;
    flex-direction: column;
    max-width: 325px;
    padding-left: 15px;

    .title {
      color: #121212;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
    }

    .description {
      color: #4e5156;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      line-height: 22px;
      margin-bottom: 10px;
      white-space: pre-wrap;
    }

    .buttons {
      display: flex;
      justify-content: space-between;

      .contactSupportLink {
        margin-left: 20px;
      }
    }

    .btn {
      color: #2d6cca !important;
      cursor: pointer;
      float: right !important;
      font-size: 14px;
    }
  }
}

.errorNotification {
  :global(.ant-notification-notice-icon) {
    color: #f44343 !important;

    &::before {
      background: #f4434333 !important;
    }
  } 
}

.successNotification {
  z-index: 1000;

  :global(.ant-notification-notice-icon) {
    color: #2ac155 !important;

    &::before {
      background: #2ac15540 !important;
    }
  }

  .notificationContent {
    border-left-color: #2ac15540 !important;
  }
}

.infoNotification {
  :global(.ant-notification-notice-icon) {
    color: #2d6cca !important;

    &::before {
      background: #2d6cca40 !important;
      right: -26px !important;
    }
  }

  .notificationContent {
    border-left-color: #2d6cca40 !important;
  }
}

.warningNotification {
  :global(.ant-notification-notice-icon) {
    color: #f1aa3e !important;

    &::before {
      background: #f0a83d40 !important;
      right: -21px !important;
    }
  }

  .notificationContent {
    border-left-color: #f0a83d40 !important;
  }
}

.link {
  color: #121212 !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: underline;
}
