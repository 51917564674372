@import 'src/style/colors';
@import 'src/style/fonts';

.tableContainer {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 8px !important;
  margin-top: 20px;
  padding: 10px;
  width: 100%;

  :global(.ant-table-content) {
    padding-bottom: 20px;

    :global(.ant-table-tbody tr:last-child td) {
      border-bottom: 0 !important;
    }
  }
}

.errorBannerContainer {
  background-color: transparent;
  height: auto;
  margin-bottom: 20px;
  margin-top: 5px;
  padding: 0 30px;
  width: 100%;
}

.errorBanner {
  background-color: #f4434326;
  border-radius: 5px;
  color: #f44343;
  height: 40px;
  padding: 8px 10px;
  width: 100%;
}

.ContentDiv {
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;

  .Title {
    color: $color-gray-dark-secondary;
    display: flex;
    font-size: $font-size-12;
    font-weight: 400;
    justify-content: space-between;
  }

  .InputIcon {
    font-size: $font-size-16;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 0;
  
    &:hover {
      cursor: pointer;
    }
  }

  .CommonInput {
    background: #f9f9fb;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    box-sizing: border-box;
  }
}

.FooterDiv {
  border-radius: 0 0 8px 8px;
  box-shadow: 0 4px 21px rgba(0, 0, 0, 0.02);
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;

  .Textarea {
    background: #f9f9fb;
    border: 1px solid #e8e8e8;
    height: 110px;
    padding: 10px !important;
    resize: none;

    &::placeholder {
      color: #a3a4a4;
      font-size: 14px;
    }
  }

  .Title {
    color: $color-gray-dark-secondary;
    font-size: $font-size-12;
    font-weight: 400;
  }

  .FooterFlex {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
  }

  .Btn {
    margin-left: 15px;
  }

  .BtnCancel {
    background: #fff;
    border: 1px solid #e8e8e8;

  }
}
