@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/components/common-components/scss/mixins.scroll';

.tableStyles {
  :global(.ant-table-body) {
    @include scrollbars(5px, rgba(138,138,138,0.32), rgba(138,138,138,0.12));
    // margin-top: 50px;
  }

  :global(.ant-table-tbody) {
    :global(.ant-table-cell) {
      // min-width: 840px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.projectsDropdown {
  width: fit-content !important;

  :global(.ant-select-item):hover {
    background-color: rgba(45, 108, 202, 0.1) !important;
    color: #2d6cca;
    font-weight: 400 !important;
  }

  :global(.ant-select-item-option-selected:not(.ant-select-item-option-disabled)) {
    background-color: rgba(45, 108, 202, 0.1) !important;
    color: #2d6cca;
    font-weight: 400 !important;
  }

  .activeOption {
    background-color: rgba(45, 108, 202, 0.1) !important;
    color: #2d6cca;
    font-weight: 400 !important;
  }
}

.divider {
  margin-bottom: 0;
  margin-top: 0;
}

.editUrlBtnContainer {
  :global(.ant-form-item-control-input-content) {
    display: flex;
  }

}

.divider {
  margin-bottom: 0;
  margin-top: 0;
}

.editUrlBtnContainer {
  :global(.ant-form-item-control-input-content) {
    display: flex;
  }

}

.projectSelectorContainer {
  :global(.ant-select) {
    min-width: 146px;
  }
}

.keywordsPopover {
  :global(.ant-popover-inner-content) {
    max-height: 300px;
    max-width: 300px;
    overflow-x: auto;
    overflow-y: auto;
    width: 300px;
  }

  :global(.ant-popover-inner-content::-webkit-scrollbar) {
    border-radius: 10px;
    height: 8px;
    width: 8px;
  }

  :global(.ant-popover-inner-content::-webkit-scrollbar-thumb) {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  :global(.ant-popover-inner-content::-webkit-scrollbar-track) {
    background-color: #fff;
  }
}

.keywordItemTag {
  background: #f7f7f8 !important;
  border-radius: 6px !important;
  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;
}
