@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/components/common-components/scss/mixins.scroll';

.contentIdeasDrawer {
  .popoverDivider {
    border-top: 1px solid $color-light-gray-third;
    height: 1px;
  }
}

.contentIGeneratorDrawerContent {
  margin-top: 10px;

  .descriptionText {
    color: $color-black;
    font-size: $font-size-14;
    margin-top: 10px;
  }

  
  .contentFragmentInput {
    background-color: $color-white !important;
    border: 1px solid $color-light-gray-third !important;
  }

  :global(.ant-form) {
    .inputLabel {
      color: $color-gray-dark;
      font-size: $font-size-12;
      margin-bottom: 5px;
    }

    .ant-form-item {
      background-color: $color-white !important;
      border: 1px solid $color-light-gray-third !important;
    }

  }

  .contentGeneratorForm {
    margin-bottom: 20px;
  }



  .countrySelector {
    .selectInput {
      margin-right: 15px;
      width: 195px;

      :global(.ant-select-selector) {
        border-radius: 5px;
        height: 38px !important;
      }

      :global(.ant-select-selection-item) {
        padding-top: 2px !important;
      }

      :global(.ant-select-selection-search) {
        padding-top: 0 !important;
      }
    }
  }

  .generateIdeasButton {
    height: 38px !important;
  }

  .generatedItemsNumb {
    color: $color-black;
    cursor: default;
    font-size: $font-size-16;
    font-weight: 500;
  }


  .ContentFragmentsContainer {
    background-color: $color-white;
    border-radius: 6px;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.02);
    height: auto;
    margin-bottom: 30px;
    padding: 25px 28px;
    width: 100%;

    .ContentFragmentTitle {
      color: $color-black;
      font-size: $font-size-16;
      font-weight: 600;
    }

    .ContentFragment {
      color: $color-black;
      font-size: $font-size-12;

      span {
        display: block;
        font-weight: bold;
      }
    }

    .controlButtons {
      align-items: center;
      display: flex;

      .downloadButton {
        color: $color-gray-dark;
        cursor: pointer;
        margin: auto;
        margin-right: 10px;
        width: 14px;
      }

      .copyButton ,
      .addToArticleBtn {
        cursor: pointer;
        margin-left: 17px;
      }

      .copyButton {
        color: $color-black;
      }
    }
  }
}


.tabsContainer {
  :global(.rc-tabs-nav) {
    background: transparent !important;
    height: 59px;
    padding: 0;
    padding-top: 6px;

  }

  :global(.rc-tabs-ink-bar) {
    background: $color-purple-medium !important;
  }

  :global(.rc-tabs-tab-btn) {
    color: #4e5156 !important;
    font-size: 1rem !important;
    font-weight: normal;
    line-height: 26px;

    padding: unset !important;
  }

  :global(.rc-tabs-nav-wrap) {
    border-bottom: 1px solid #e8e8ea;
  }

  :global(.rc-tabs-content-holder) {
    margin-top: 2rem;
  }

  :global(.rc-tabs-tabpane) {
    background-color: rgb(242, 242, 245);
  }
}

.ContentIdeaContainer {
  border-radius: 6px;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.02);
  height: auto;
  margin-bottom: 30px;
  padding: 25px 40px;
  position: relative;
  width: 100%;

  .ideaOrder {
    background: $color-white;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    left: 0;
    padding: 2px 8px;
    position: absolute;
    top: 26px;
  }

  .ContentIdea {
    color: $color-black;


   

    span {
      display: block;
      font-weight: bold;
    }
  }

  .controlButtons {
    align-items: center;
    display: flex;

    .downloadButton {
      color: $color-gray-dark;
      cursor: pointer;
      margin: auto;
      margin-right: 10px;
      width: 14px;
    }

    .copyButton {
      color: $color-black;
      cursor: pointer;
      margin-left: 17px;
    }
  }
}

.customFocusTermsTabs {
  :global(.rc-tabs-extra-content) {
    background-color: transparent !important;
    border: 1px solid $color-gray-dark-medium;
    border-radius: 5px;
    cursor: pointer;
    font-size: $font-size-12;
    height: 25px;
    margin-bottom: auto;
    margin-left: 5px;
    margin-top: auto;
    padding: 0 5px;
    transition: all 0.2s ease-in;
  }

  .focusTermsTabTitleValue {
    color: $color-gray-light;
    display: block;
    font-size: $font-size-12;
    font-weight: 400;
    margin-left: 5px;

    :global(.ant-spin-spinning) {
      height: 10px;
    }

    .customSpinner {
      font-size: 12px !important;
    }
  }

  :global(.rc-tabs-tab) {
    margin-right: 15px !important;
  }

  :global(.rc-tabs-content-holder) {
    background-color: transparent !important;
  }

  :global(.rc-tabs-nav-operations) {
    display: none !important;
  }


  :global(.rc-tabs-tab-btn) {
    font-weight: 600;
    line-height: 100% !important;
    padding: 5px 0 !important;
  }

  :global(.rc-tabs-ink-bar) {
    background-color: $color-orange-primary !important;
    border-radius: 2px 2px 0 0;
  }
}

.tableOuterTerms {
  background-color: $color-white;
  padding-bottom: 0 !important;


  &.roundEdgesTable {
    border-radius: 10px;
  }
 

  :global(.ant-table-body) {
    @include scrollbars(5px, rgba(138, 138, 138, 0.32), rgba(138, 138, 138, 0.12));
    max-height: 175px !important;
    overflow: auto auto !important;
  }


  table tr th {
    background-color: #fff;
  }

  .ant-table-content {
    border-radius: 0 !important;
  }



  table {
    th::before {
      width: 0 !important;
    }

    :global(.ant-table-container) {
      padding: 5px;
    }

    :global(.ant-table-column-sorter) {
      display: flex;
      padding-left: 3px !important;
    }

    :global(.ant-table-column-sorters) {
      display: inline-flex !important;
      padding: 5px !important;
    }

    tr td {
      /*   padding-right: 20px !important; */

      &:first-child {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
    }

    :global(.ant-table-tbody) {
      tr:first-child {
        height: 0;

        td {
          border-bottom: 0 solid 'transparent' !important;
        }
      }

      tr:nth-child(even) {
        td {
          background: $color-white !important;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:hover {
          button {
            background: #eef4fe !important;
            border: 0 !important;
            color: #2d6cca;
            font-weight: 500;
          }
        }

        &.purpleRow {
          &:hover {
            :global(button) {
              background-color: transparent !important;
            }
          }
        } 
      }

      tr:nth-child(odd) {
        td {
          background: $color-white !important;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:hover {
          button {
            background: #eef4fe !important;
            border: 0 !important;
            color: #2d6cca;
            font-weight: 500;
          }
        }

        &.purpleRow {
          &:hover {
            :global(button) {
              background-color: transparent !important;
            }
          }
        } 
      }
    }

    :global(.ant-table-thead) {
      background: #f7f7f7 !important;
      border-radius: 8px;

      :global(.ant-table-column-has-sorters) {
        // text-align: right !important;
  
        :global(.ant-table-column-title) {
          text-align: center;
          z-index: 0 !important;
        }
      }

      th {
        background: #f7f7f7 !important;
        color: #121212 !important;
        font-size: $font-size-10 !important;
        font-weight: 600;
        line-height: 14px;
        padding: 0 !important;
      }

      :global(.ant-table-cell-ellipsis) {
        &:first-child {
          padding-left: 20px !important;
        }
      }


      :global(.ant-table-cell-fix-left) {
        text-align: left !important;

        &:first-child {
          border-bottom-left-radius: 8px !important;
          border-top-left-radius: 8px !important;
        }
      }

      th:first-child {
        background: #f7f7f7 !important;
        border-bottom-left-radius: 8px !important;
        border-top-left-radius: 8px !important;
        color: #121212;
        font-size: $font-size-12;
        font-weight: 600;
      }

      th:nth-child(2) {
        background: #f7f7f7 !important;
        color: #121212;
        font-size: $font-size-12;
        font-weight: 600;
      }

      th:nth-child(3) {
        background: #f7f7f7 !important;
        color: #121212;
        font-size: $font-size-12;
        font-weight: 600;
        text-align: center;
      }

      th:last-child {
        background-color: #f7f7f7 !important;
        border-bottom-right-radius: 8px !important;
        border-top-right-radius: 8px !important;
        padding-left: 25px !important;
      }
    }

    :global(.ant-table-cell) {
      white-space: pre-wrap;
    }

    :global(.ant-table-cell-fix-left-last) {
      text-align: left !important;
      // background: $color-white !important;
    }

    tr:first-child td {
      background: $color-white !important;
    }

    tr:nth-child(even) td {
      background: $color-white-primary !important;
    }

    tr:nth-child(odd) td {
      background-color: $color-white !important;
    }

    tr th {
      height: 40pxpx !important;
      line-height: 22px;
      white-space: nowrap;
    }

    :global(.ant-column-sorters) {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    tr td {
      padding-bottom: 15px;
      text-align: center;
    }

    tr th:first-child,
    tr td:first-child {
      padding-left: 25px !important;
      padding-right: 25px !important;
    }

    tr th:last-child,
    tr td:last-child {
      padding-left: 0 !important;
      padding-right: 25px !important;
      text-align: center;
    }
  }
}

.numberInput {
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.02);
  height: 38px;
  min-width: 55px;
  padding-left: 10px;

  &:focus-visible {
    outline: none !important;
  }
}






