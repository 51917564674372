@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/components/common-components/scss/mixins.scroll';


.activeProject {
  .ModalHeader {
    color: $color-white;
    text-align: center;


    h2 {
      color: #121212;
      display: flex;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
  }

  .SummaryContainer {
    p {
      border-bottom: 1px solid #e8e8e8;
      color: #121212;
      font-size: 14px;
      line-height: 22px;
      padding-bottom: 20px;

    }
  }

  :global(.rc-dialog-body) {
    padding: 0 30px;
  }

  :global(.rc-dialog-footer) {
    .footer {
      display: flex;
      justify-content: flex-end;
      margin-right: 30px;

      .active {
        background: #2d6cca;
        border-radius: 5px;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        margin-left: 10px;
      }

      .cancel {
        background: #f9f9fb;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        box-sizing: border-box;
        color: #4e5156;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }


}
