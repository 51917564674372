@import 'src/style/colors';
@import 'src/style/fonts';

.innerContent {
  display: flex;
  flex-direction: column;
  
}

.MainContainer {
  align-items: baseline;
  // background: #fff;
  border-radius: 8px;
  // box-shadow: 0 3px 2px rgba(0, 0, 0, 0.02);
  box-sizing: border-box;
  cursor: default;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 12px;
  // padding: 27px 35px 0 27px;
  text-align: center;
  width: 100%;
  

  .mainInner {
    align-items: flex-start;
    background: #fff;
    border: 1px solid #eaeaea;
    border-radius: 16px;
    box-shadow: 0 3px 2px 0 #00000005;
    display: flex;
    flex-direction: column;
    padding: 20px 10px 25px 20px;
    width: 225px;

    svg {
      margin-left: -10px;  
    }

    p {
      color: #121212;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    h5 {
      font-size: 20px;
      font-weight: 600;
      margin-right: 10px;
    }
  }

  .explanationText {
    color: #4e5156;
    font-size: 12px;
    padding-bottom: 15px;
  }
}

.chartStyled {
  margin-left: -40px;
  margin-top: 35px;
}

.cardsContainer {
  background-color: #fff;
  border-radius: 12px;
  margin: 20px 0;
  padding: 4px;
  padding: 20px;
  width: 49%;
}

.textBlack {
  color: #121212;
  font-size: 16px;
  font-weight: 600;
}

.tableText {
  margin-right: 7px;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 600px;
}
