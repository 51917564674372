@import '@/style/colors';

.moreOptionsMenu {
  background-color: $color-white;
  box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.04) !important;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  width: 100px;
  z-index: 2;
}

.undoButton {
  color: $color-link-primary;
  font-size: 14px;
  padding-right: 2px;
  padding-top: 4px;

  &:hover {
    color: $color-link-secondary;
  }
}


.deleteModeOn {
  align-items: center;
  background-color: #ff000021;
  display: flex;
  justify-content: flex-end;
  left: -378px;
  padding: 8px;
  position: absolute;
  /* padding: 13px; */
  top: -25px;
  width: 412px;
}
