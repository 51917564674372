.reviewPopover {
  :global(.ant-popover-content > .ant-popover-arrow) {
    display: none !important;
  }

  :global(.ant-popover-content) {
    :global(.ant-popover-inner) {
      min-width: 442px;
    }
  }
}

.youtube-input-delete-icon {
  .ant-row .ant-form-item-row {
    width: 100% !important;
  }
}
