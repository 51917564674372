@import '@/style/colors';
@import 'src/style/fonts';

button.buttonGoogle {
  font-size: $font-size-14;
  margin-bottom: 2rem;
  position: relative;
  width: 100%;

  .loginGoogleButton {
    align-items: center;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), $color-purple-medium;
    border-radius: 2px;
    display: flex;
    height: 40px;
    justify-content: center;
    left: 0;

    position: absolute;
    top: 0;
    width: 40px;
  }

  .loginGoogleIcon {
    height: 20px;
    position: absolute;
    width: 20px;
  }
}
