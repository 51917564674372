@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/components/common-components/scss/mixins.scroll';

.tableHeader {
  background: $color-gray-dark-medium;
  border-radius: 8px 8px 0 0;
  height: 60px;
  padding-bottom: 0 !important;
  padding-left: 20px !important;
  padding-right: 20px;
  padding-top: 18px;
  position: relative;

  :global(.main-input) {
    align-items: center !important;
    display: flex !important;
    flex: 1 1;
    margin-top: 5px !important;
    margin-top: -5px;
    width: auto;
  

    :global(.common-component-input) {
      background-color: $color-white !important;

      &::placeholder {
        background-color: $color-white !important;
      }
    }
  }

  .tableSearch {
    height: 60px;
    padding: 0 25px;
  }
}

.competitorsTableWrapper {
  background-color: $color-white;
  padding: 10px;
  padding-bottom: 0 !important;
  position: relative;


  &.roundEdgesTable {
    border-radius: 10px;
  }
 

  :global(.ant-table-body) {
    overflow: auto auto !important;
  
    // overflow-y: hidden !important;
  }

  &.trackedCompatitorsTableWrapper {
    :global(.ant-table-body) {
      max-height: 315px !important;
    }
  }

  table tr th {
    background-color: #fff;
  }

  .ant-table-content {
    border-radius: 0 !important;
  }

  :global(.ant-table-column-sorters-with-tooltip):not(:first-child) {
    text-align: center;
  }

  table {
    :global(button) {
      background-color: #f7f7f8;
      border: 0;
      border-radius: 4px;
      box-sizing: border-box;
      color: $color-gray-dark-secondary;
      cursor: pointer;
      font-size: $font-size-14;
      outline: none !important;
      padding: 4px 15px;
    }

    th::before {
      width: 0 !important;
    }

    :global(.ant-table-column-sorter) {
      display: flex;
      padding-left: 3px !important;
    }

    :global(.ant-table-column-sorters) {
      display: inline-flex !important;
      padding: 5px !important;
    }

    tr td {
      /*   padding-right: 20px !important; */

      &:first-child {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
    }

    :global(.ant-table-tbody) {
      tr:nth-child(even) {
        td {
          background: $color-white !important;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:hover {
          td {
            background: #eef4fe !important;
          }

          button {
            background: #eef4fe !important;
            border: 0 !important;
            color: #2d6cca;
            font-weight: 500;
          }
        }

        &.purpleRow {
          &:hover {
            td {
              background-color: #ece5f3 !important;
            }

            :global(button) {
              background-color: transparent !important;
            }
          }
        } 
      }

      tr:nth-child(odd) {
        td {
          background: $color-white !important;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:hover {
          td {
            background: #eef4fe !important;
          }

          button {
            background: #eef4fe !important;
            border: 0 !important;
            color: #2d6cca;
            font-weight: 500;
          }
        }

        &.purpleRow {
          &:hover {
            td {
              background-color: #ece5f3 !important;
            }

            :global(button) {
              background-color: transparent !important;
            }
          }
        } 
      }
    }

    :global(.ant-table-thead) {
      background: #f7f7f7 !important;
      border-radius: 8px;

      :global(.ant-table-column-has-sorters) {
        // text-align: right !important;
  
        :global(.ant-table-column-title) {
          text-align: center;
          z-index: 0 !important;
        }
      }

      th {
        background: #f7f7f7 !important;
        color: #121212 !important;
        font-size: $font-size-12;
        font-weight: 600;
        line-height: 14px;
        padding: 0 !important;
      }

      :global(.ant-table-cell-ellipsis) {
        &:first-child {
          padding-left: 20px !important;
        }
      }


      :global(.ant-table-cell-fix-left) {
        text-align: left !important;

        &:first-child {
          border-bottom-left-radius: 8px !important;
          border-top-left-radius: 8px !important;
        }
      }

      th:first-child {
        background: #f7f7f7 !important;
        border-bottom-left-radius: 8px !important;
        border-top-left-radius: 8px !important;
        color: #121212;
        font-size: $font-size-12;
        font-weight: 600;
      }

      th:nth-child(2) {
        background: #f7f7f7 !important;
        color: #121212;
        font-size: $font-size-12;
        font-weight: 600;
      }

      th:nth-child(3) {
        background: #f7f7f7 !important;
        color: #121212;
        font-size: $font-size-12;
        font-weight: 600;
        text-align: center;
      }

      th:last-child {
        background-color: #f7f7f7 !important;
        border-bottom-right-radius: 8px !important;
        border-top-right-radius: 8px !important;
        padding-left: 25px !important;
      }
    }

    :global(.ant-table-cell) {
      white-space: pre-wrap;
    }

    :global(.ant-table-cell-fix-left-last) {
      text-align: left !important;
      // background: $color-white !important;
    }

    tr:first-child td {
      background: $color-white !important;
    }

    tr:nth-child(even) td {
      background: $color-white-primary !important;
    }

    tr:nth-child(odd) td {
      background-color: $color-white !important;
    }

    tr th {
      height: 60px !important;
      line-height: 22px;
      white-space: nowrap;
    }

    :global(.ant-column-sorters) {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    tr td {
      padding-bottom: 15px;
      padding-top: 15px;
      text-align: center;
    }

    tr th:first-child,
    tr td:first-child {
      padding-left: 25px !important;
      padding-right: 25px !important;
    }

    tr th:last-child,
    tr td:last-child {
      padding-left: 0 !important;
      padding-right: 25px !important;
      text-align: center;
    }
  }
}

.positionColumnContainer {
  align-items: center;
  display: flex;
}

.deltaWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.sfDropdownContainer {
  color: #4e5156;
  cursor: pointer;
  text-decoration: underline;
}

.sfTooltipOverlay {
  :global(.ant-popover-arrow) {
    display: none;
  }

  :global(.ant-popover-arrow) {
    display: none;
  }

  :global(.ant-popover-inner) {
    border-radius: 5px;
  }

}


.QickSortMenu {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
  display: block;
  position: absolute;
  right: 20px;
  top: 60px;
  z-index: 4;

  span {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;

    &:hover {
      background: rgba(127, 78, 173, 0.1);
      color: #7f4ead;
    }
  }

  p {
    align-items: center;
    display: flex;
    margin-bottom: 0 !important;
    padding-left: 40px;
  }
}


.diagram {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

  svg {
    height: 250px !important;
    margin-top: 15px;
    max-width: 100%;
    overflow: visible !important;

    g > text {
      font-size: 14px;
      text-shadow: 2px 1px 3px #fff;
    }

    g.venn-intersection > text {
      display: none !important;
    }

    // g:nth-child(4) text {
    //   fill: transparent !important;
    // }

    // g:nth-child(5) text {
    //   fill: transparent !important;
    // }

    // g:nth-child(6) text {
    //   fill: transparent !important;
    // }

    // g:nth-child(7) text {
    //   fill: transparent !important;
    // }
  }
}
