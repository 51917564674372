@import 'src/style/colors';
@import 'src/style/fonts';

.titleEditor {
  border: unset;
  border: 1px solid transparent;
  font-size: $font-size-20;
  font-weight: 500;
  height: 36px;
  line-height: 20px;
  margin-bottom: 2px;
  margin-left: -6px;
  margin-top: 5px;
  overflow: hidden;
  padding: 5px;
  text-overflow: ellipsis;
  width: 100%;

  @media only screen and (max-height: 800px) {
    font-size: $font-size-18;
    height: 30px;
    margin-top: 0;
  }

  &:focus {
    border: 1px solid $color-light-gray;
    border-radius: 5px;
    box-sizing: border-box;
    outline: none;
    padding: 5px;
  }

  &.isError {
    border: 1px solid $color-red-primary;
  }
}

.linkAndCopyContainer {
  display: flex;
  padding-left: 5px;
}

.subtitle {
  color: $color-gray-dark-secondary !important;
  display: flex;
  font-size: $font-size-14;
  font-weight: 400;
  justify-content: space-between;
  line-height: 22px;
  margin-top: 0;
  padding-left: 0;
  text-align: left;
  text-decoration: none !important;

  &:hover {
    color: $color-link !important;
  }

  span {
    padding-right: 80px;
  }
}

.cloudIcon {
  color: $color-gray-dark-secondary !important;
  font-size: 16px;
  margin-left: 5px;

  &:hover {
    color: #4e5156 !important;
  }
}

.penIcon {
  color: #4e5156 !important;
  font-size: 16px;
  margin-left: 5px;

  &:hover {
    color: #4e5156 !important;
  }
}

.downloadIcon {
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 2px;
}

.uploadIcon {
  color: #4e5156 !important;
  cursor: pointer;
  font-size: 16px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 2px;

  &:hover {
    color: #4e5156 !important;
  }
  
}

.wpIcon {
  font-size: 16px;
  margin-bottom: -2px;
  margin-right: 5px;
  width: 14px;
}

.editIcon {
  color: $color-gray-dark-secondary !important;
  font-size: $font-size-16;

  &:hover {
    color: $color-blue !important;
  }
}

.editUrlBtnContainer {
  margin-bottom: 0;
  margin-top: 12px !important;
  
  :global(.ant-form-item-control-input-content) {
    display: flex;
    justify-content: flex-end;
  }

  .cancelImportUrlBtn,
  .importUrlButton {
    font-size: $font-size-14;
    line-height: 22px;
    padding: 4px 15px !important;
  }
    
  .cancelImportUrlBtn {
    color: $color-gray-dark-secondary;
    margin-right: 10px;
  }
}

.copyTooltip {
  :global(.ant-tooltip-content) {
    background-color: $color-gray-dark;
    border-radius: 10px !important;
  }

  :global(.ant-tooltip-arrow) {
    display: none !important;
  }

  :global(.ant-tooltip-inner) {
    border-radius: 10px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}


.Url {
  color: $color-gray-dark-secondary;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-right: 5px;
  overflow: hidden;
  text-align: left;
  text-decoration: underline;
  text-overflow: ellipsis;

  &:hover {
    color: $color-blue;
  }
}

.peopleEditingContainer {
  bottom: 10px;
  position: fixed;
  transition: all 0.2s ease-in;
  width: 100%;
  z-index: 999;

  // @media only screen and (max-width: 1550px) {
  //   padding-right: 10px;
  // }

  // @media only screen and (max-width: 1210px) {
  //   left: 10px;
  // }
}

.wpPublishPopupContent {
  border-radius: 10px;
  width: 360px;
  
  :global(.ant-popover-inner-content) {
    padding: 0 !important;
  }

  :global(.ant-popover-arrow-content) {
    display: none;
  }
}

.wpPublishPopupContentV2 {
  border-radius: 12px;
  width: 360px;
  
  :global(.ant-popover-inner-content) {
    padding: 0 !important;
  }

  :global(.ant-popover-arrow-content) {
    display: none;
  }
}

.noWpSitesModal {
  background-color: #121212;
  border-radius: 8px;
  cursor: default;
  height: 550px !important;
  width: 530px !important;

  :global(.ant-modal-content) {
    background-color: #121212;
    border-radius: 8px;
  }

  :global(.ant-modal-close) {
    right: -60px;
    top: -15px;

    :global(.ant-modal-close-x) {
      color: #fff;
      font-size: 25px;
    }
  }
  
  :global(.ant-modal-body) {
    padding: 24px 37px !important;
  }
}

.wpAddSiteModal {
  background-color: #144198;
  border-radius: 8px;
  cursor: default;
  min-height: 500px !important;
  width: 450px !important;
  @media screen and (max-width: 600px) {
    height: 400px !important;
    width: 280px !important;
    
    :global(.ant-modal-body) {
      padding: 12px;
    }
  }

  :global(.ant-modal-content) {
    background-color: #144198;
    border-radius: 8px;
  }

  :global(.ant-modal-close) {
    right: -60px;
    top: -15px;
    @media screen and (max-width: 600px) {
      right: -50px;
    }

    :global(.ant-modal-close-x) {
      color: #fff;
      font-size: 25px;
    }
  }
}

.uploadDownloadPopup {
  :global(.ant-tooltip-inner) {
    background-color: #16171a;
    border-radius: 8px;
    padding: 10px;
  }

  :global(.ant-tooltip-arrow) {
    color: #16171a;
  }
} 

.metaDescPopup {
  padding: 18px 20px;

  :global(.ant-popover-content) {
    width: 380px;
  }
}

.spinnerLoader {
  font-size: 12px;
  margin-right: 5px;
  width: 14px;
}

.alphaBadge {
  align-items: center;
  background-color: #4a4c51;
  border-radius: 50px;
  color: #fff;
  font-size: 11px;
  height: 19px;
  margin-left: 7px;
  padding: 0 9px;
  width: max-content;
}
