@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/components/common-components/scss/mixins.scroll';

.layout:global(.ant-layout) {
  cursor: default;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.sidebar {
  @include scrollbars(3px,  #4E5156, $color-navbar-background);
}

.layout {
  :global(.ant-layout-sider) {
    @include scrollbars(5px, $color-gray-light, $color-navbar-background);
    box-shadow: 4px 0 40px rgba(0, 0, 0, 0.22);

    @media screen and (max-width: 992px) {
      overflow: visible !important;
    }
  }

  :global(.ant-layout-sider-zero-width-trigger) {
    @media screen and (max-width: 992px) {
      background: transparent;
      left: 1rem;
      top: 1.05rem !important;
    }
  }
}

.content-layout:global(.ant-layout) {
  background-color: $color-body-background;
}

.content:global(.ant-layout-content) {
  overflow-x: hidden;
}

.contentDisabled:global(.ant-layout-content) {
  max-height: calc(100vh - 78px);
  overflow: hidden;
}

.deletePadding {
  padding-left: 0 !important;
}

.content {
  /* color: $color-green; */
  margin: 0;
  min-height: 280px;
  padding-left: 230px;

  :not(:global(.ant-pagination-item))
  > a:not(:global(.ant-pagination-item-link)) {
    color: $color-link;
    text-decoration: underline;
  }

  @media screen and (max-width: 991px) {
    padding-left: 0 !important;
  }
}

.checkoutSidebar:global(.ant-layout) {
  aside {
    display: none !important;
    position: absolute !important;
    @media screen and (max-width: 991px) {
      display: block !important;
    }
  }

  :global(.ant-layout-sider-zero-width-trigger) {
    @media screen and (max-width: 991px) {
      background-color: transparent !important;
      position: absolute !important;
      top: 20px !important;
    }
  }
}

.Modal {
  max-width: 550px;
  z-index: 3;

  :global(.rc-dialog-header) {
    background-color: $color-body-background;
    padding: 35px 0 0;
  }

  :global(.rc-dialog-body) {
    background-color: $color-body-background;
    border-radius: 0 0 10px 10px;
    padding: 0 25px 25px;
  }

  :global(.rc-dialog-close) {
    color: $color-white;
    opacity: 1;
    outline: none;
    right: 0;
    top: -30px;
  }
}
