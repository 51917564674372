@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/components/common-components/scss/mixins.scroll';

.tabsContainer {
  height: calc(100vh - 60px);
  margin-top: 30px;

  :global(.rc-tabs-nav) {
    background-color: transparent !important;
    height: 59px;
    padding: 0;
    padding-top: 6px;

  }

  :global(.rc-tabs-tab-btn) {
    color: #4e5156 !important;
    font-size: 1rem !important;
    font-weight: normal;
    line-height: 26px;

    padding: unset !important;
  }

  :global(.rc-tabs-nav-wrap) {
    border-bottom: 1px solid #e8e8ea;
  }

  :global(.rc-tabs-content-holder) {
    margin-top: 20px;
  }

  // :global(.rc-tabs-tabpane) {
  //   background-color: #f9f9fb;
  // }

  :global(.rc-tabs-ink-bar) {
    background-color: $color-purple-medium !important;
  }
}
