@import '@/style/colors';
@import 'src/style/fonts';

.SelectWithSearch {
  &.KrtSelectWithSearch {
    :global(.ant-select-selection-item) {
      display: none;
    }
  }


  :global(.ant-select-selector) {
    background-color: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
    color: #fff !important;
    font-family: 'Inter' !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    height: 23px !important;
    letter-spacing: -0.02em;
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap !important;
  }

  :global(.ant-select-selection-placeholder) {
    display: block;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :global(.ant-select-arrow) {
    color: $color-secondary-text !important;
    display: none !important;
    font-weight: 400 !important;
  }

  :global(.ant-select-dropdown-placement-bottomLeft) {
    background: $color-navbar-background;

  }

  :global(.ant-select-selection-placeholder) {
    color: $color-white;
    opacity: 1;
  }
}

.Option {
  align-items: center !important;
  border-bottom: 1px solid rgb(62, 64, 67) !important;
  color: #e1dfe7 !important;
  font-weight: 400 !important;
  margin-left: 15px;
  margin-right: 15px;
  min-height: 32px !important;
  padding: 5px !important;
  @media screen and (max-width: 768px) {
    max-width: 370px;
    min-width: fit-content;
    width: auto !important;
  }

  &:last-child {
    margin-bottom: 13px;
  }
}

.KrtOption {
  align-items: center !important;
  border-bottom: 1px solid rgb(62, 64, 67) !important;
  color: #e1dfe7 !important;
  font-weight: 400 !important;
  margin-left: 15px;
  margin-right: 15px;
  min-height: 65px !important;
  padding: 5px !important;

  &:last-child {
    margin-bottom: 13px;
  }
}

.OptionCountry {
  align-items: center !important;
  color: #e1dfe7 !important;
  font-weight: 400 !important;
  margin-left: 10px;
  margin-right: 10px;
  min-height: 40px !important;
  padding: 5px !important;

  &:last-child {
    margin-bottom: 13px;
  }
}

.topDiv {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 8px;
  margin-top: 10px;
  padding-bottom: 15px;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    margin-left: 0 !important;
    margin-right: 10px;
    row-gap: 8px;
  }
}

.SearchField {
  background: #47494d !important;
  border-radius: 8px !important;
  color: #d8d6de !important;
  margin-left: 11px;
  padding: 7px !important;
  padding-left: 0.7rem !important;
  width: 370px !important;

  :global(.ant-input) {

    color: #d8d6de !important;
    font-weight: 500 !important;
    padding-left: 0.4rem !important;

    &::placeholder {
      color: $color-gray-light;
      font-size: $font-size-14;
      font-weight: 300;
      line-height: 17px;
      margin-left: 2rem !important;
    }

  }
}

.Center {
  background-color: transparent !important;
  border: 1px solid #4e5156;
  border-radius: 10px 10px 0 0;

  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 1rem;
  text-align: center !important;
  width: 90%;
}

.LinkNewProject {
  align-items: center;
  background-color: transparent !important;
  border: 1px solid #4e5156;
  border-radius: 5px;
  color: #e1dfe7 !important;
  display: flex;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  text-align: center !important;
}

.noHover {
  cursor: default !important;
  padding: 10px 20px !important;
  
  &:hover {
    background: transparent !important;
  }
}

.PropertyDropDown {
  background: #313337 !important;
  border-radius: 8px !important;
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.18);
  left: 230px !important;
  min-width: 430px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  top: 110px !important;
  transition: all 0.2s ease;
  @media screen and (max-width: 500px) {
    left: 50px !important;
    min-width: auto !important;
    top: 164px !important;
    width: 300px !important;
  }

  &.leftSpacing {
    left: 105px !important;
  }

  :global(.ant-empty-description) {
    color: #e1dfe7;
  }

  :global(.ant-select-item-option-active:not(.ant-select-item-option-selected)) {
    background-color: inherit !important;

    &:hover {
      background: rgba(127, 78, 173, 0.25) !important;
      border-radius: 8px;
    }
  }

  :global(.ant-select-item) {
    padding: 8px 14px !important;
  }

  :global(.ant-select-item-option-selected) {
    background: #47325c !important;
  }

  :global(.ant-select-item-option-selected)> div > div > div {
    span {
      color: $color-white !important;
      padding-top: 1px;
    }
  }

  &.KrtPropertyDropDown {
    :global(.ant-select-item-option-active:not(.ant-select-item-option-selected)) {
      &:hover {
        background: #ff853633 !important;
      }
    }
  
    :global(.ant-select-item-option-selected) {
      background: #ff853633 !important;
    }
  }

}

.PropertySCADropDown {
  background: #313337 !important;
  border-radius: 8px !important;
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.18);
  min-width: 546px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  transition: all 0.2s ease;
  @media screen and (max-width: 768px) {
    min-width: auto !important;
    width: 320px !important;

    .ant-select-item-option-content {
      white-space: normal !important;
    }
  }
  @media screen and (max-width: 500px) {
    min-width: auto !important;
    width: 250px !important;
  }
  @media screen and (min-width: 1100px) {
    left: 107px !important;
    top: 307px !important;
  }

  &.leftSpacing {
    left: 105px !important;
  }

  :global(.ant-empty-description) {
    color: #e1dfe7;
  }

  :global(.ant-select-item-option-active:not(.ant-select-item-option-selected)) {
    background-color: inherit !important;

    &:hover {
      background: rgba(127, 78, 173, 0.25) !important;
      border-radius: 8px;
    }
  }

  :global(.ant-select-item) {
    padding: 8px 14px !important;
  }

  :global(.ant-select-item-option-selected) {
    background: #47325c !important;
  }

  :global(.ant-select-item-option-selected)> div > div > div {
    span {
      color: $color-white !important;
      padding-top: 1px;
    }
  }

  &.KrtPropertyDropDown {
    :global(.ant-select-item-option-active:not(.ant-select-item-option-selected)) {
      &:hover {
        background: #ff853633 !important;
      }
    }
  
    :global(.ant-select-item-option-selected) {
      background: #ff853633 !important;
    }
  }

}

.PropertyDropDownBacklink {
  background: #313337 !important;
  border-radius: 5px;
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.18);
  left: 200px !important;
  min-width: 350px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  top: 70px !important;
  transition: all 0.2s ease;
  
  &.leftSpacing {
    left: 65px !important;
  }

  :global(.ant-empty-description) {
    color: #e1dfe7;
  }

  :global(.ant-select-item-option-active:not(.ant-select-item-option-selected)) {
    background-color: inherit !important;
  }

  :global(.ant-select-item-option-selected) {
    background: transparent !important;
  }

  :global(.ant-select-item-option-selected)> div > div > div {
    span {
      color: $color-white !important;
      padding-top: 1px;
    }
  }

}

.siderClosedScaSelect {
  background: #313337 !important;
  border-radius: 8px !important;
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.18);
  left: 270px !important;
  min-width: 546px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  top: 307px !important;
  transition: all 0.2s ease;

  &.leftSpacing {
    left: 105px !important;
  }

  :global(.ant-empty-description) {
    color: #e1dfe7;
  }

  :global(.ant-select-item-option-active:not(.ant-select-item-option-selected)) {
    background-color: inherit !important;

    &:hover {
      background: rgba(127, 78, 173, 0.25) !important;
      border-radius: 8px;
    }
  }

  :global(.ant-select-item) {
    padding: 8px 14px !important;
  }

  :global(.ant-select-item-option-selected) {
    background: #47325c !important;
  }

  :global(.ant-select-item-option-selected)> div > div > div {
    span {
      color: $color-white !important;
      padding-top: 1px;
    }
  }

  &.KrtPropertyDropDown {
    :global(.ant-select-item-option-active:not(.ant-select-item-option-selected)) {
      &:hover {
        background: #ff853633 !important;
      }
    }
  
    :global(.ant-select-item-option-selected) {
      background: #ff853633 !important;
    }
  }
}

.displayCountries {
  display: none !important;
}

.PropertyDropDownCountries {
  background: #313337 !important;
  border-radius: 5px;
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.18);
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  right: 446px !important;
  top: 70px !important;
  width: 237px !important;

  :global(.ant-empty-description) {
    color: #e1dfe7;
  }

  :global(.ant-select-item-option-active:not(.ant-select-item-option-selected)) {
    background-color: inherit !important;
  }

  :global(.ant-select-item-option-selected) {
    background: transparent !important;
  }

  :global(.ant-select-item-option-selected)> div > div > div {
    span {
      color: $color-white !important;
      padding-top: 1px;
    }
  }

}

.countrySearch {
  background-color: transparent !important;
  border: unset;
  color: #fff;
  outline-width: 0;

  &::placeholder {
    color: #a3a4a4;
  }
}

.settingBtn {
  align-items: center !important;
  border-radius: 6px !important;
  box-sizing: border-box !important;
  cursor: pointer !important;
  display: flex !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  height: 32px !important;
  justify-content: center !important;
  width: 132px !important;
}
