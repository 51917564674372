@import '@/style/colors';
@import 'src/style/fonts';

.popupClasses {
  left: 0 !important;
  top: 2.5rem !important;
  z-index: 3;
}

.checkBoxFiltersStyled {
  left: 0 !important;
  top: 37px !important;
  z-index: 9;

  :global(.ant-checkbox-group-item) {
    width: 100% !important;
  }
}

.OptionContainer {
  padding: 0 !important;
  width: 220px;

  :global(.ant-popover-arrow) {
    display: none;
  }

  :global(.ant-popover-content) {
    background: #fff;
    border-radius: 5px;
    position: relative;
    top: 0;
  }

  :global(.ant-popover-inner) {
    border-radius: 5px;
  }

  :global(.ant-popover-inner-content) {
    border-radius: 5px;
    padding: 0 !important;
  }
}

.countrySelectStyles {
  :global(.ant-form-item-control) {
    :global(.ant-form-item-control-input-content) {
      :global(.ant-select) {
        align-items: center;
        background: #fff !important;
        border: 1px solid #cdcdcd !important;
        border-radius: 6px !important;
        display: flex;
        height: 28px !important;

        &:hover {
          background: #e8e8e8 !important;
          border: 1px solid #cdcdcd !important;
        }

        &:global(.ant-select-open) {
          background: #e8e8e8 !important;
          border: 1px solid #cdcdcd !important;
        }
      }
    }
  }
}

.manageColumnWrapper {
  display: flex;
  justify-content: space-between;
};

.exportBtn {
  display: flex;
  justify-content: space-between;
};

@media screen and (max-width:1500px) {
  .manageColumnWrapper {
    flex-direction: column-reverse;
  }
}
