@import 'src/style/colors';

.loaderContainer {
  align-items: center;
  display: flex;
  height: 103px;
  justify-content: center;
  max-width: 300px;
}

.loader {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
  width: 34px;

  div {
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border: 3px solid $color-white;
    border-color: $color-purple-medium transparent transparent;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    height: 34px;
    margin: 8px;
    position: absolute;
    width: 34px;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
