@import 'src/style/colors';
@import 'src/style/fonts';
@import './../../scss/mixins.button';

.button {
  @include button-default-styles;

  &:hover,
  &:focus {
    background-color: #777676;
  }

  &:focus,
  &:active {
    outline: none;
  }

  &.buttonTypePrimary {
    @include button-primary;
  }

  &.buttonSizeLg {
    @include button-size-lg;
  }

  &.buttonSizeSm {
    @include button-size-sm;
  }

  &.buttonSizeXs {
    @include button-size-xs;
  }

  .buttonLoading {
    display: inline-block;
    margin-right: 7px;
    position: relative;
    top: 1px;
  }

  .loaderSpinner {
    animation: spin 2s linear infinite;
    border: 2px solid $color-border-medium;
    border-radius: 50%;
    border-top: 2px solid $color-purple-medium;
    height: 14px;
    margin: auto;
    width: 14px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .buttonLoadingSpin {
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: loadingCircle;
  }

  &.disabled {
    @include button-disabled;
    
  }
}

.buttonList {
  > button {
    margin-right: 10px;
  }
}

.buttonTypeTransparent {
  background: transparent;
  color: $color-secondary-text;

  &:focus,
  &:hover,
  &:active {
    background: transparent;
    color: #323134;
  }
}

.buttonTypePink {
  background: #e15e83;
  color: $color-secondary-text;

  &:focus,
  &:hover,
  &:active {
    background: darken(#e15e83, 30%);
  }
}

.buttonTypeTransparentWithBorder {
  background: transparent;
  border: 1px solid #a3a4a4;
  border-radius: 5px;
  color: #ddd;

  &:focus,
  &:hover,
  &:active {
    background: transparent;
    color: #323134;
  }
}

.buttonTypeBlueOpaque {
  background: #2d6cca1a;
  border: 1px solid #2d6cca80;
  border-radius: 5px;
  color: #2d6cca;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: background 0.3s ease-in-out;

  &:focus,
  &:hover,
  &:active {
    background: #2d6cca1a;
  }
}

.buttonTypePurpleOpaque {
  background: #7f4ead1a;
  border: 1px solid #7f4ead;
  border-radius: 5px;
  color: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: background 0.3s ease-in-out;

  &:focus,
  &:hover,
  &:active {
    background: darken(#7f4ead, 30%);
  }
}

.buttonTypePurpleLight {
  background: #e6d8f2;
  border-radius: 5px;
  border-width: 0;
  color: #7f4ead;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: background 0.3s ease-in-out;

  &:focus,
  &:hover,
  &:active {
    background: hsl(272, 50%, 98%);
  }
}

.buttonTypeGreen {
  background: #2ac155;
  border-radius: 5px;
  border-width: none;
  color: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: background 0.3s ease-in-out;

  &:focus,
  &:hover,
  &:active {
    background: darken(#2ac155, 30%);
  }
}

.buttonTypeGreenOpaque {
  background: #52d5771a;
  border: 1px solid #52d577;
  border-radius: 5px;
  color: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: background 0.3s ease-in-out;

  &:focus,
  &:hover,
  &:active {
    background: darken(#52d5771a, 30%);
  }
}

.buttonTypeGreenDark {
  background: rgba(42, 193, 85, 0.15);
  border-radius: 6px;
  color: #fff;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  transition: background 0.3s ease-in-out;

  &:focus,
  &:hover,
  &:active {
    background: rgba(42, 193, 85, 0.09);
  }
}

.buttonTypePurpleLight {
  background: rgba(127, 78, 173, 0.15);
  border-radius: 5px;
  color: #7f4ead;
  transition: background 0.3s ease-in-out;

  &:focus,
  &:hover,
  &:active {
    background: darken(rgba(127, 78, 173, 0.15), 30%);
  }
}

.buttonTypeLightGray {
  background: #f7f7f8;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  color: #121212;

  &:focus,
  &:hover,
  &:active {
    background: transparent;
    color: #323134;
  }
}

.buttonTypePurple {
  background: $color-purple-medium;

  &:focus,
  &:hover,
  &:active {
    background: transparent;
    border: 1px solid $color-purple-medium;
    color: #cdacee;
  }
}

.buttonTypeBlue {
  background: $color-blue;
  color: $color-white;

  &:focus,
  &:hover,
  &:active {
    background: $color-blue;
    color: $color-white;
  }
}

.buttonTypeSuperLightgray {
  background: #54565a1a;
  border: 1px solid $color-gray-dark-secondary;
  color: $color-white;

  &:focus,
  &:hover,
  &:active {
    background: #54565a1a;
    color: $color-white;
  }
}

.outlinePrimary {
  background-color: transparent !important;
  color: #cdacee !important;
  font-family: Inter;
  font-size: $font-size-14;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
}
