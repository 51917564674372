@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/components/common-components/scss/mixins.scroll';

.globeContainer {
  align-items: center;
  display: flex;
}

.globeIcon {
  padding-left: 3px;
  width: 20px;
}

.tabs {
  max-width: 960px;
  min-width: 960px;

  :global(.rc-tabs-nav) {
    border-bottom: 1px solid #e8e8e8;
    padding-top: 0 !important;
  }

  :global(.rc-tabs-nav-wrap) {
    background: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  :global(.rc-tabs-ink-bar-animated) {
    background-color: #2d6cca !important;
    height: 4px !important;
  }

  :global(.rc-tabs-tab:first-child) {
    margin-left: 25px;
  }

  :global(.rc-tabs-tab:not(.rc-tabs-tab-active)) {
    :global(.rc-tabs-tab-btn) {
      color: #4e5156 !important;
      padding: 0 !important;
    }
  }
  

  :global(.rc-tabs-tab-btn) {
    color: #121212 !important;
    padding: 0 !important;
  }

  :global(.rc-tabs-extra-content) {
    background-color: #fff !important;
  }


}

.tablePagesStyle {
  padding-bottom: 0 !important;

  table {
    :global(button) {
      background-color: #f7f7f8;
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      box-sizing: border-box;
      color: $color-gray-dark-secondary;
      cursor: pointer;
      font-size: $font-size-14;
      outline: none !important;
      padding: 4px 15px;
    }

    th::before {
      width: 0 !important;
    }

    :global(.ant-table-tbody) {
      tr:nth-child(even) {
        td {
          background: $color-white !important;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:hover {
          td {
            background: #eef4fe !important;
          }

          button {
            background: #eef4fe !important;
            border: 0 !important;
            color: #2d6cca;
            font-weight: 500;
          }
        }
      }

      tr:nth-child(odd) {
        td {
          background: $color-white;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:hover {
          td {
            background: #eef4fe !important;
          }

          button {
            background: #eef4fe !important;
            border: 0 !important;
            color: #2d6cca;
            font-weight: 500;
          }
        }
      }
    }

    :global(.ant-table-thead) {
      background: #f7f7f7 !important;
      height: 50px !important;

      th {
        background: #f7f7f7 !important;
        color: #121212 !important;
        font-size: $font-size-12;
        font-weight: 600;
        line-height: 14px;
      }
    }
  }
}

.tableStyle {
  margin: auto;

  :global(.main-filter) {
    padding-left: 15px;
    padding-right: 15px;
  }

  :global(.ant-table-thead > tr > th:first-child ) {
    border-bottom-left-radius: 8px !important;
    border-top-left-radius: 8px !important;
  }

  :global(.ant-table-row) {
    cursor: pointer;
  }

  .rankingPotential {
    background: #f9f9fb;
    border-radius: 4px;
    height: 32px;
    padding: 5px 15px;
    width: 112px;
    width: fit-content;}
}

.keywordExplorerHeader {
  margin: auto;
}

.dropdownStyles {
  font-size: 18px;
  margin-left: 5px;
}

.exportButton {
  align-items: center;
  background-color: #f9f9fb;
  border: 1px solid #e8e8e8 !important;
  border-radius: 4px !important;
  color: #121212 !important;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  margin-right: 25px;
  padding: 5px 14px;

  a {
    color: #121212;
    text-decoration: none;
  }
}

.keywordInput {
  background: #2d2f34;
  border-radius: 5px;
  margin-bottom: 0 !important;
  margin-top: -10px !important;
  min-height: 38px !important;
  resize: auto !important;
  width: 320px !important;

  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }
}

.cardLight {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.03);
  margin-top: 57px;
  min-height: 94.9% !important;
}

.topPagesCard {
  border-radius: 8px;
  min-height: auto !important;
  padding: 0;
}

.keywordSearchTable {
  :global(.rc-tabs-tab:first-child) {
    margin-left: 25px !important;
  }

  :global(.rc-tabs-nav) {
    background: $color-gray-dark-medium !important;
    border-radius: 5px !important;
    height: 60px !important;
    padding-top: 0 !important;
  }

  :global(.rc-tabs-tab-btn) {
    color: $color-white !important;
    padding: 0 !important;
  }

  :global(.rc-tabs.rc-tabs-top) {
    border-radius: 8px !important;
  }

  :global(.rc-tabs-ink-bar.rc-tabs-ink-bar-animated) {
    background-color: $color-orange-primary !important;
    height: 4px !important;
  }

  :global(.rc-tabs-tab-active .rc-tabs-tab-btn) {
    background-color: transparent !important;
  }
}

.tableHeader {
  background: $color-white;
  border-radius: 8px 8px 0 0;
  line-height: 22px;
  padding-bottom: 0 !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  position: relative;

  :global(.main-input) {
    margin-top: 5px !important;

    :global(.common-component-input) {
      background-color: $color-white !important;
      margin-left: 30px;

      &::placeholder {
        background-color: $color-white !important;
      }
    }
  }

  :global(.filters) {
    :global(.filterInputItem) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  :global(.main-input) {
    align-items: center !important;
    display: flex !important;
    flex: 1 1 0;
    margin-top: -5px;
    width: auto;

    svg {
      left: 8px;
      position: absolute;
    }
  }

  .exportButton {
    align-items: center;
    background: #f7f7f8;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: center;
    margin-top: 13px;
    padding: 7px 15px;
    position: relative;
    transition: all 0.35s ease-in-out;
    width: max-content;
  }
}

.tableOuterPages {
  background-color: $color-white;
  padding-bottom: 0 !important;

  :global(.ant-table-body) {
    overflow: auto scroll;
    // overflow-y: hidden !important;
  }

  table tr th {
    background-color: #fff;
  }

  .ant-table-content {
    border-radius: 0 !important;
  }

  :global(.ant-table-column-sorters-with-tooltip):not(:first-child) {
    text-align: center;
  }

  table {
    :global(button) {
      background-color: #f7f7f8;
      border: 0;
      border-radius: 4px;
      box-sizing: border-box;
      color: $color-gray-dark-secondary;
      cursor: pointer;
      font-size: $font-size-14;
      font-weight: 400 !important;
      outline: none !important;
      padding: 4px 15px;
    }

    th::before {
      width: 0 !important;
    }

    :global(.ant-table-column-sorter) {
      display: flex;
      padding-left: 3px !important;
    }

    :global(.ant-table-column-sorters) {
      display: inline-flex !important;
      padding: 5px !important;
    }

    tr td {
      /*   padding-right: 20px !important; */

      &:first-child {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
    }

    :global(.ant-table-tbody) {
      tr:nth-child(even) {
        td {
          background: $color-white;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:hover {
          td {
            background: #eef4fe !important;
          }

          button {
            background: #eef4fe !important;
            border: 0 !important;
            color: #2d6cca;
            font-weight: 500;
          }
        }

        &.purpleRow {
          &:hover {
            td {
              background-color: #ece5f3 !important;
            }

            :global(button) {
              background-color: transparent !important;
            }
          }
        } 
      }

      tr:nth-child(odd) {
        td {
          background: $color-white;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:hover {
          td {
            background: #eef4fe !important;
          }

          button {
            background: #eef4fe !important;
            border: 0 !important;
            color: #2d6cca;
            font-weight: 500;
          }
        }

        &.purpleRow {
          &:hover {
            td {
              background-color: #ece5f3 !important;
            }

            :global(button) {
              background-color: transparent !important;
            }
          }
        } 
      }
    }

    :global(.ant-table-thead) {
      background: #f7f7f7 !important;
      border-radius: 8px;

      :global(.ant-table-column-has-sorters) {
        text-align: right;
  
        :global(.ant-table-column-title) {
          text-align: center;
          z-index: 0 !important;
        }
      }

      th {
        background: #f7f7f7 !important;
        color: #121212 !important;
        font-size: $font-size-12;
        font-weight: 600;
        line-height: 14px;
        padding: 0 !important;
      }

      :global(.ant-table-cell-ellipsis) {
        &:first-child {
          padding-left: 20px !important;
        }
      }


      :global(.ant-table-cell-fix-left) {
        text-align: left !important;

        &:first-child {
          border-bottom-left-radius: 8px !important;
          border-top-left-radius: 8px !important;
        }
      }

      th:first-child {
        background: #f7f7f7 !important;
        border-bottom-left-radius: 8px !important;
        border-top-left-radius: 8px !important;
        color: #121212;
        font-size: $font-size-12;
        font-weight: 600;
      }

      th:nth-child(2) {
        background: #f7f7f7 !important;
        color: #121212;
        font-size: $font-size-12;
        font-weight: 600;
      }

      th:nth-child(3) {
        background: #f7f7f7 !important;
        color: #121212;
        font-size: $font-size-12;
        font-weight: 600;
        text-align: center;
      }

      th:last-child {
        background-color: #f7f7f7 !important;
        border-bottom-right-radius: 8px !important;
        border-top-right-radius: 8px !important;
        padding-left: 25px !important;
      }
    }

    :global(.ant-table-cell) {
      white-space: pre-wrap;
    }

    :global(.ant-table-cell-fix-left-last) {
      text-align: left !important;
      // background: $color-white !important;
    }

    tr:first-child td {
      background: $color-white !important;
    }

    tr:nth-child(even) td {
      background: $color-white !important;
    }

    tr:nth-child(odd) td {
      background-color: $color-white !important;
    }

    tr th {
      height: 60px !important;
      line-height: 22px;
      white-space: nowrap;
    }

    :global(.ant-column-sorters) {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    tr td {
      padding-bottom: 15px;
      padding-top: 15px;
      text-align: center;
    }

    tr th:first-child,
    tr td:first-child {
      padding-left: 15px !important;
      padding-right: 25px !important;
    }

    tr th:last-child,
    tr td:last-child {
      padding-left: 25px !important;
      padding-right: 25px !important;
      text-align: center;
    }
  }

  .rowBackground {
    // filter: saturate(6);

    :global(.ant-table-cell) {
      background: #eef4fe !important;
    }

    button {
      background: #eef4fe !important;
      border: 0 !important;
      color: #2d6cca;
      font-weight: 500;
    }
  }
}

.gainContainer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.gainLoss {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  // padding-left: 20px;
  position: relative;

  :global(.icon) {
    margin-bottom: -2px !important;
  }

  .innerSpan {
    margin-right: 2px !important;
    min-width: 13px;
    text-align: right !important;
    white-space: nowrap;
    // width: 40%;
  }
}

.cardLight {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.03);
  margin-top: 20px;
  min-height: 94.9% !important;
}

.topCard {
  border-radius: 8px;
  min-height: 96% !important;
  padding: 0;

  :global(.rc-tabs-nav) {
    background: $color-gray-dark-medium !important;
    border-radius: 5px 5px 0 0 !important;
    color: $color-white !important;
    height: 70px !important;
  }
  @media only screen and (max-width: 992px) {
    margin: 15px;
  }
}

.topHeading {
  align-items: center;
  display: flex;

  h4 {
    color: $color-gray-dark;
    font-size: $font-size-12;
    font-style: normal;
    font-weight: bold;
    line-height: 15px;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
  }

  :global(.ant-checkbox-wrapper) {
    margin-right: 5px;
  }

  :global(.ant-checkbox-checked .ant-checkbox-inner) {
    background-color: $color-purple-medium;
    border-color: $color-purple-medium;
  }
}

.topCardHeading {
  padding: 30px 25px;
  padding-bottom: 10px;
}

.darkHeading {
  background: $color-gray-dark-medium !important;
  border-radius: 5px 5px 0 0;
  color: $color-white !important;
  justify-content: space-between;
  padding: 25px;

  h4 {
    color: $color-white !important;
    font-size: $font-size-14 !important;
    font-weight: 500 !important;
    margin-bottom: 0 !important;
    text-transform: unset !important;
  }
}

.filterInputDiv {
  background-color: #fff;
  padding-left: 25px;
  padding-right: 10px;
  width: 100%;

  div {
    align-items: center;
  }

  :global(.common-component-input) {
    background: #fff !important;
  }

  ::placeholder {
    background: #fff !important;
  }
  @media only screen and (max-width: 992px) {
    border-radius: 8px;
  }
}

.secondRow {
  align-items: center;
  display: block;
}

.myArticleTable {
  :global(.ant-table-content) {
    overflow: auto !important;
  }
}

.paginationContainer {
  border-radius: 0 0 8px 8px;
  border-top: 1px solid $color-white-secondary;
  box-shadow: 0 -1px 0 $color-white-secondary, 0 -3px 10px rgba(0, 0, 0, 0.02);
  display: flex;
  justify-content: flex-end;
  padding: 20px 25px;

  :global(.ant-select-selector) {
    color: #121212;
  }

  p {
    color: $color-secondary-text;
    font-size: $font-size-14;
    line-height: 28px;
    margin-bottom: 0;
    padding-left: 20px;
    width: 40%;
  }

  :global(.ant-select) {
    z-index: 2 !important;
  }
}

.PageDiv {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.WrapperDiv {
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.Wrapper {
  align-items: center;
  background: #f7f7f8;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  padding: 7px 15px;
  width: 65%;

  .DotRed {
    background: #f44343;
    border-radius: 50%;
    height: 6px;
    margin-right: 10px;
    width: 6px;
  }

  .DotGreen {
    background: #2ac155;
    border-radius: 50%;
    height: 6px;
    margin-right: 10px;
    width: 6px;
  }

  .DotOrange {
    background: #ff8536;
    border-radius: 50%;
    height: 6px;
    margin-right: 10px;
    width: 6px;
  }

  .Icon {
    font-size: 12px;
    padding-left: 5px;
  }

  &:hover {
    background: #fefefe;
  }
}

.Id {
  color: $color-blue;
  cursor: pointer;
  font-size: $font-size-14;
  font-weight: 400;
}

.Image {
  cursor: pointer;
  padding-left: 10px;
}

.Subtext {
  color: #4e5156;
}

.DropdownMenu {
  .Title {
    font-weight: 600;
    padding-bottom: 20px;
  }

  .ContentDiv {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    padding-top: 5px;
  }

  span {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;

    // &:hover {
    //   background: rgba(127, 78, 173, 0.1);
    //   color: #7f4ead;
    // }
  }
}

.drawer {
  background-color: #f2f2f5 !important;
}

.Bad {
  align-items: center;
  background: rgba(244, 67, 67, 0.15);
  border-radius: 3px;
  display: flex;
  font-size: $font-size-14;
  font-weight: 400;
  margin-left: 25px;
  padding: 3px 10px;

  .Dot {
    background: #f44343;
    border-radius: 50%;
    height: 6px;
    margin-right: 3px;
    width: 6px;
  }

  span {
    color: #b62222;
  }
}

.Good {
  align-items: center;
  background: rgba(42, 193, 85, 0.15);
  border-radius: 3px;
  display: flex;
  font-size: $font-size-14;
  font-weight: 400;
  margin-left: 25px;
  padding: 3px 10px;

  .Dot {
    background: #2ac155;
    border-radius: 50%;
    height: 6px;
    margin-right: 3px;
    width: 6px;
  }

  span {
    color: #0c872f;
  }
}

.Average {
  align-items: center;
  background: rgba(255, 133, 54, 0.15);
  border-radius: 3px;
  display: flex;
  font-size: $font-size-14;
  font-weight: 400;
  margin-left: 25px;
  padding: 3px 10px;

  .Dot {
    background: #ff8536;
    border-radius: 50%;
    height: 6px;
    margin-right: 3px;
    width: 6px;
  }

  span {
    color: #c95b14;
  }
}

.keywordItemTag {
  background: #f7f7f8 !important;
  border-radius: 4px !important;
  margin-left: 8px;
  margin-right: 8px;
}

.hideMoreTags {
  order: 1;
}

.keywordItemTagContainer {
  border: 0 !important;
  padding: 7px 12px !important;
  width: auto;
}

.keywordItemLabel {
  max-width: 215px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.QickSortMenu {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
  display: block;
  position: absolute;
  right: 50px;
  z-index: 4;

  span {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;


    &:hover {
      background: rgba(127, 78, 173, 0.1);
      color: #7f4ead;
    }
  }

  p {
    align-items: center;
    display: flex;
    margin-bottom: 0 !important;
    padding-left: 40px;
  }
}

.filterInputStyle {
  padding-bottom: 0 !important;
  padding-right: 20px !important;
  padding-top: 0 !important;
  width: auto;

  :global(.main-input) {
    align-items: center;
    display: flex;
    margin-left: 15px;
    
    :global(.search-icon) {
      color: $color-gray;
      width: 1.5em;
    }
    
    :global(.common-component-input::placeholder),
    :global(.common-component-input) {
      background: #fff;
      color: $color-gray;
      font-size: 14;
      max-width: 200px;
    }
    
    :global(img) {
      height: 23px;
      margin: 0 5px;
      margin-left: 0;
      position: relative;
      top: 8px;
    }
  }

  .filters {
    margin-right: 30px;
  }
}

.customPopover {
  z-index: 3 !important;

  :global(.ant-popover-content) {
    :global(.ant-popover-arrow) {
      border-color: #25242b;
    }

    :global(.ant-popover-inner) {
      :global(.ant-popover-inner-content) {
        background-color: $color-white;
        border-radius: 10px;
        color: $color-secondary-text;
        padding: 8px 15px;

        p {
          cursor: pointer;
          font-size: $font-size-14;
          margin-bottom: 0;
          padding: 4px 0;
        }

        span {
          color: $color-secondary-text;
          margin-right: 4px;
        }
      }
    }
  }
}

.tooltipContent {
  background-color: #121212;
  border-radius: 8px;
  color: #fff;
  padding: 10px 15px;
}

.removeStyle {
  border-radius: 5px;
  height: 30px;
  padding: 5px;
  width: 30px;

  &:hover {
    background-color: $color-light-gray;
  }

}

.displayEnd {
  align-items: center;
  display: flex;
}

.completeTag {
  background: rgba(31, 172, 71, 0.1);
  border: 1px solid rgba(31, 172, 71, 0.5);
  border-radius: 3px;
  color: #1fac47;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  height: 18px;
  justify-content: center;
  line-height: 15px;
  margin-left: 5px;
  padding: 0 6px;
  width: auto;
}

.gscFavicon {
  border-radius: 6px;
  height: 20px;
  width: 20px;
}
