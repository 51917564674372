@import 'src/style/colors';
@import 'src/style/fonts';

.modal {
  :global(.rc-dialog-header) {
    padding: 19px 0 2px 19px;

    :global(.rc-dialog-title) {
      color: $color-gray-dark;
      font-family: Inter;
      font-size: $font-size-20;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }
  }

  :global(.rc-dialog-body) {
    padding: 30px;
  }

  :global(.rc-dialog-header),
  :global(.rc-dialog-footer) {
    border-color: transparent;
  }

  :global(.rc-dialog-footer) {
    padding: 16px 9px;
    padding-top: 8px;
  }
}

:export {
  backgroundColor: $color-body-background; /* stylelint-disable-line property-no-unknown */
}
