@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/components/common-components/scss/mixins.scroll';


.headerDiv {
  display: flex;
  justify-content: space-between;
  padding: 35px 15px;

  .subtext {
    color: #4e5156;
  }

  a {
    text-decoration: underline;
  }
}

.tableHeader {
  background: $color-white;
  line-height: 22px;
  padding-bottom: 0 !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  position: relative;

  :global(.main-input) {
    padding-bottom: 0 !important;

    :global(.common-component-input) {
      background-color: $color-white !important;
      margin-left: 30px;

      &::placeholder {
        background-color: $color-white !important;
      }
    }
  }

  :global(.filters) {
    :global(.filterInputItem) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  :global(.main-input) {
    align-items: center !important;
    display: flex !important;
    flex: 1 1 0;
    margin-top: -5px;
    width: auto;

    svg {
      left: 8px;
      position: absolute;
    }
  }
}

.check {
  margin-bottom: 0 !important;
  margin-left: 20px !important;
  margin-right: -20px !important;
}

.spanDisplay {
  display: flex;
  justify-content: space-between;
}

.tableOuterPages {
  overflow: auto;
  padding-bottom: 0 !important;

  table tr th {
    background-color: #fff;
  }

  :global(.ant-table-content) {
    border-radius: 0 !important;
    overflow: visible !important;
  }

  :global(.ant-table-column-sorters-with-tooltip):not(:first-child) {
    text-align: center;
  }

  .noScroll {
    :global(.ant-table-body) {
      overflow: hidden !important;
    }
  }

  table {
    padding-left: 10px;
    padding-right: 10px;

    th::before {
      width: 0 !important;
    }

    :global(.ant-table-column-sorter) {
      display: flex;
      padding-left: 3px !important;
    }

    :global(.ant-table-column-sorters) {
      display: inline-flex !important;
      padding: 5px !important;
    }

    tr td {
      padding-right: 20px !important;

      &:first-child {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
    }

    :global(.ant-table-tbody) {
      max-height: 700px !important;

      tr:nth-child(even) {
        td {
          background: $color-white !important;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:hover {
          td {
            background: #eef4fe !important;
          }

          button {
            background: #eef4fe !important;
            border: 0 !important;
            color: #2d6cca;
            font-weight: 500;
          }
        }
      }

      tr:nth-child(odd) {
        td {
          background: $color-white;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:hover {
          td {
            background: #eef4fe !important;
          }

          button {
            background: #eef4fe !important;
            border: 0 !important;
            color: #2d6cca;
            font-weight: 500;
          }
        }
      }
    }

    :global(.ant-table-thead) {
      background: #f7f7f7 !important;
      height: 50px !important;

      th {
        background: #f7f7f7 !important;
        color: #121212 !important;
        font-size: $font-size-12;
        font-weight: 600;
        line-height: 14px;
        padding: 0 !important;
      }

      :global(.ant-table-cell-fix-left) {
        text-align: left !important;

        &:first-child {
          border-bottom-left-radius: 8px !important;
          border-top-left-radius: 8px !important;
        }
      }

      th:first-child {
        background: #f7f7f7 !important;
        border-bottom-left-radius: 8px !important;
        border-top-left-radius: 8px !important;
        color: #121212;
        font-size: $font-size-12;
        font-weight: 600;
      }

      th:nth-child(2) {
        background: #f7f7f7 !important;
        color: #121212;
        font-size: $font-size-12;
        font-weight: 600;
      }

      th:last-child {
        background-color: #f7f7f7 !important;
        border-bottom-right-radius: 8px !important;
        border-top-right-radius: 8px !important;
        padding-left: 25px !important;
      }
    }

    :global(.ant-table-cell-fix-left-last) {
      text-align: left !important;
      // background: $color-white !important;
    }

    tr:first-child td {
      background: $color-white !important;
    }

    tr:nth-child(even) td {
      background: $color-white-primary !important;
    }

    tr:nth-child(odd) td {
      background-color: $color-white !important;
    }

    tr th {
      height: 60px !important;
      line-height: 22px;
      white-space: nowrap;
    }

    :global(.ant-column-sorters) {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    tr td {
      padding-bottom: 15px;
      padding-top: 15px;
      text-align: center;
    }

    tr th:first-child,
    tr td:first-child {
      padding-left: 25px !important;
      padding-right: 25px !important;
    }

    tr th:last-child,
    tr td:last-child {
      padding-left: 25px !important;
      padding-right: 25px !important;
      text-align: center;
    }
  }
}

.bottomTable {
  border-radius: 0 0 8px 8px;
  box-shadow: 0 -1px 0 $color-white-secondary, 0 -3px 10px rgba(0, 0, 0, 0.02);
  display: flex;
  padding-bottom: 20px;
  padding-top: 20px;
}

.WrapperDiv {
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.Wrapper {
  align-items: center;
  background: #f7f7f8;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  min-width: 65%;
  padding: 7px 15px;

  .DotRed {
    background: #f44343;
    border-radius: 50%;
    height: 6px;
    margin-right: 10px;
    width: 6px;
  }

  .DotGreen {
    background: #2ac155;
    border-radius: 50%;
    height: 6px;
    margin-right: 10px;
    width: 6px;
  }

  .DotGrey {
    background: #a3a4a4;
    border-radius: 50%;
    height: 6px;
    margin-right: 10px;
    width: 6px;
  }
}

.popoverContainer {
  z-index: 1200 !important;

  :global(.ant-popover-arrow) {
    display: none !important;
  }
}

.ActionButtonsContainer {
  background: #fff;
  border-radius: 5px;
  bottom: 20px;
  margin: -12px -16px;

  button {
    flex-grow: 1;
  }
}

.RefetchBtn,
.DisconnectBtn {
  background: transparent;
  font-size: $font-size-14;
  transition: all 0.2s ease;

  &.RefetchBtn {
    color: #121212;
    display: block;
    padding-left: 12px;
    padding-right: 36px;
    text-align: left;
    width: 100%;

    &:hover {
      background: rgba(127, 78, 173, 0.1);
      color: #7f4ead;
      
    }
  }

  &.DisconnectBtn {
    color: #fe6767;
    display: block;
    padding-left: 12px;
    padding-right: 36px;
    text-align: left;
    width: 100%;

    &:hover {
      background: rgba(127, 78, 173, 0.1);
    }
  }
}

.Modal {
  // margin-top: 20%;
  box-shadow: 0 4px 21px 0 #12121230;
  width: 700px;

  :global(.rc-dialog-body) {
    padding: 0 !important;
  }
}

.ContentDiv {
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;

  .Title {
    color: $color-gray-dark-secondary;
    display: flex;
    font-size: $font-size-12;
    font-weight: 400;
    justify-content: space-between;
  }

  .InputIcon {
    font-size: $font-size-16;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 0;
  
    &:hover {
      cursor: pointer;
    }
  }

  .CommonInput {
    background: #f9f9fb;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    box-sizing: border-box;
  }
}

.FooterDiv {
  border-radius: 0 0 8px 8px;
  box-shadow: 0 4px 21px rgba(0, 0, 0, 0.02);
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;

  .Textarea {
    background: #f9f9fb;
    border: 1px solid #e8e8e8;
    height: 110px;
    padding: 10px !important;
    resize: none;

    &::placeholder {
      color: #a3a4a4;
      font-size: 14px;
    }
  }

  .Title {
    color: $color-gray-dark-secondary;
    font-size: $font-size-12;
    font-weight: 400;
  }

  .FooterFlex {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
  }

  .Btn {
    margin-left: 15px;
  }

  .BtnCancel {
    background: #fff;
    border: 1px solid #e8e8e8;

  }
}

.Heading {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;

  .Title {
    font-size: $font-size-20;
    font-weight: 600;
  }

  .Icons {
    align-items: center;
    display: flex;
    margin-bottom: 10px;
  }

  .Icon {
    color: $color-gray-dark-secondary;
    font-size: $font-size-20;
    padding-left: 10px;

    &:hover {
      cursor: pointer;
    }
  }
}

.closeIcon {
  color: rgb(255, 255, 255);
  opacity: 0.6;
  position: absolute;
  right: -65px;
  top: 25%;
  transform: translateY(-50%);
}
  
.closeIcon:hover {
  opacity: 1 !important;
}
  
.closeIcon {
  svg {
    height: 40px;
    overflow: visible;
    position: absolute;
    right: -20px;
    top: -25px;
    width: 40px;
  }
}
  
.closeIcon:active,
.closeIcon:focus {
  border: 0;
  outline: none;
}

.SocialMediaBtn {
  display: flex;
}

.Popup {
  position: absolute;
  right: 20px;
  top: 80px;
}


.errorBannerContainer {
  background-color: transparent;
  height: auto;
  margin-bottom: 20px;
  margin-top: 5px;
  padding: 0 30px;
  width: 100%;
}

.errorBanner {
  background-color: #f4434326;
  border-radius: 5px;
  color: #f44343;
  height: 40px;
  padding: 8px 10px;
  width: 100%;
}

