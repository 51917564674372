@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/components/common-components/scss/mixins.scroll';

.navBar {
  background-color: #24262a;
  min-width: 259px;
  // background-color: #24262A;
  position: static;
  width: 259px;

  :global(.ant-menu-item-selected) {
    background-color: #4e5156 !important;
    border-radius: 4px !important;
    color: #fff !important;
    margin-left: 40px !important;
    padding-left: 24px !important;
    width: calc(70% + 1px) !important;

    &::after {
      display: none !important;
    }
 
  }

  .navBarItem {
    border-radius: 4px;
    margin-bottom: 0 !important;
    margin-left: 40px !important;
    margin-right: 15px;
    max-width: calc(70% + 1px) !important;
    padding-left: 24px !important;
    width: calc(70% + 1px) !important;


    &:hover {
      background-color: #4e5156;
      border-radius: 4px;
      border-radius: 4px;
      color: #fff !important;
      margin-bottom: 0 !important;
      margin-left: 40px !important;
      margin-right: 15px;
      max-width: calc(70% + 1px) !important;
      padding-left: 24px !important;
      width: calc(70% + 1px) !important;

    }

    .navBarItem::after {
      border-right: transparent !important;
    }

    &:global(.ant-menu-item) {
      &::after {
        border-right: transparent;

      }
    }

    &:global(.ant-menu-item-selected) {
      background-color: #4e5156 !important;
      border-radius: 4px;
      color: #fff !important;
      margin-left: 40px;
      padding-left: 24px !important;
      width: calc(70% + 1px) !important;
   
    }

    .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content {
      margin-left: 20px;
      overflow: none !important;
    }

    
    .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content::after {
      margin-left: 20px;
    }
  }
}

.activeItem {
  background-color: #4e5156;
  border-radius: 4px;
  color: #fff !important;
  margin-left: 40px !important;
  max-width: calc(70% + 1px) !important;
  padding-left: 24px !important;
  width: calc(70% + 1px) !important;
  

}
