
@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/components/common-components/scss/mixins.scroll';

.border {
  border: 1px solid $color-white-secondary;
  border-radius: 85px;
  box-sizing: border-box;
  padding: 5px;
}

.mainContainer {
  // display: flex;
  margin-bottom: 20px;

  .improvedKeywords {
    align-items: center;
    background: rgba(31, 172, 71, 0.15);
    border-radius: 8px;
    color: #a3a4a4;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 600;
    height: 78px;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    // width: 125px;

    
  
    .improvedSpan {
      color: #1fac47;
      font-family: 'Inter';
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
    }
  }

  .declinedKeywords {
    align-items: center;
    background: rgba(244, 67, 67, 0.15);
    border-radius: 8px;
    color: #a3a4a4;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 600;
    height: 78px;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    // width: 125px;


    .declinedSpan {
      color: #f44343;
      font-family: 'Inter';
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
    }
  }

  .serpPositon {
    background: #282b2f;
    border-radius: 8px;


    .serpPositonInner {
      align-items: center;
      border-radius: 8px;
      color: #a3a4a4;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      font-weight: 600;
      height: 78px;
      justify-content: center;
      text-align: center;
      text-transform: uppercase;
      width: 125px;

      .serpPositionInnerSpan {
        color: #fff;
        font-family: 'Inter';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
      }
    }
  }
}

.backlinkData {
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  width: 100%;

  .serpPositonInner {
    align-items: center;
    border-radius: 8px;
    color: #a3a4a4;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 600;
    height: 66px;
    justify-content: space-around;
    margin-top: 9px;
    text-align: center;
    text-transform: uppercase;
    width: 100px;

    .serpPositionInnerSpan {
      color: #fff;
      font-family: 'Inter';
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
    }
  }}

.sitemapStatusCards {
  background-color: $color-body-background;
  border-radius: 5px;
  padding: 5px 10px;

  .statusCardsDot {
    border-radius: 5px;
    display: inline-block;
    height: 5px;
    margin-bottom: 2px;
    margin-right: 5px;
    width: 5px;

    &.redStatusDot {
      background-color: $color-red;
    }

    &.orangeStatusDot {
      background-color: $color-orange-primary;
    }
  }
}

.ant-drawer {
  background-color: #f2f2f5 !important;
}

.settingsDrawer {
  :global(.ant-drawer-content-wrapper) {
    width: 580px !important;
  }
}

.trackSerpInfoText {
  margin: 0;
  max-width: 350px;
  padding: 8px;
}

.tooltipPosition {
  color: $color-white;
  line-height: 15px;
  margin-bottom: 1px;
  margin-left: 5px;

}

.countryBreakdown {
  word-break: keep-all !important;

  table {
    table-layout: fixed !important;
  }

  .countryContainer {
    align-items: center;
    display: flex;

    @media screen and (max-width: 1200px) {
      .countryName {
        font-weight: 500;
        margin-left: 5px;
        max-width: 90px;
        white-space: normal;
        word-break: break-word;
      }

      tbody {
        tr {
          .ant-table-cell {
            padding-right: 0 !important;
          }
        }
      }
    }
  }

  thead > tr {
    th {
      color: $color-secondary-text;
      font-weight: 600;
    }
  }

  tbody {
    tr {
      // background: #f9f9fb !important;

      td {
        padding-bottom: 13px !important;
        padding-right: 0 !important;
        padding-top: 13px !important;


        &:first-child {
          color: $color-black;
        }
      }
    }
  }
}

.tableTopBorder {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.googleSearchConsoleDisabled {
  background: rgba(25, 26, 29, 0.46);
}

.refreshBtn {
  background: rgba(241, 170, 62, 0.11);
  border: 1px solid #f1aa3e;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px 20px;
  width: 20%;

  &:hover {
    background: rgba(241, 170, 62, 0.11);

  }
}

.googleSearchConsole {
  padding-bottom: 1rem;

  div {
    div {
      div {
        min-height: 0 !important;
      }
    }
  }

  .smallContainer {
    padding: 0 25px 25px;
    @media (max-width:1400px) {
      padding: 0 0 25px 10px;
    }
  }

  .container {
    padding: 0 40px;
    @media (max-width:1400px) {
      padding: 0 25px;
    }
    @media (max-width:600px) {
      padding: 0 20px;
    }

    .disabledClass {
      filter: brightness(69%);
      opacity: 0.98;
      pointer-events: none;
    }

    .headerCustomStyle {
      padding-bottom: 380px; 
    }
  }

  .topChart {
    margin-top: 40px;
    position: relative;

    div:first-child {
      // height: 450px !important;
      // margin-bottom: 20px;
      // margin-top: -50px;

      canvas {
        height: 100% !important;
      }
    }
  }

  .innerCardWithTooltip {
    align-items: center;
    display: flex;

    .info {
      display: flex;
      margin-left: 5px;

      svg {
        color: $color-white;
      }
    }

    :global(.ant-checkbox-wrapper) {
      margin-right: 5px;
    }
  }

  .chartsNoWidth:first-child {
    div {
      &:first-child {
        width: unset !important;
      }
    }
  }

  // TO DO: Remove after completely updating both GSC Overview and Keywords page
  // as they both use TrackYourSerp component
  .headerContent {
    align-items: center;
    color: $color-white;
    display: flex;
    font-size: $font-size-24;
    justify-content: space-between;
    padding: 40px 0 20px;
    @media (max-width:600px) {
      padding: 20px 0;
    }

    h4 {
      color: $color-secondary-text;
      font-family: Inter;
      font-size: $font-size-12;
      font-style: normal;
      font-weight: bold;
      line-height: 15px;
      margin: 0;
    }

    svg {
      cursor: pointer;
      position: relative;
    }

    .toolsContainer {
      align-items: flex-end;
      display: flex;
      margin-right: 40px;

      .actualizeBtn:disabled {
        cursor: not-allowed;
        opacity: 0.7;
        pointer-events: auto;
      }
    }
  }
  // Modified CSS for V2 of TrackYourSerps coponent
  // To be final in use
  .headerContentV2 {
    align-items: center;
    color: $color-white;
    display: flex;
    font-size: $font-size-24;
    justify-content: space-between;
    padding: 40px 0 0;
    @media (max-width:600px) {
      padding: 20px 0;
    }

    h4 {
      color: $color-secondary-text;
      font-family: Inter;
      font-size: $font-size-12;
      font-style: normal;
      font-weight: bold;
      line-height: 15px;
      margin: 0;
    }

    svg {
      cursor: pointer;
      position: relative;
    }

    .toolsContainer {
      align-items: flex-end;
      display: flex;
      margin-right: 40px;

      .actualizeBtn:disabled {
        cursor: not-allowed;
        opacity: 0.7;
        pointer-events: auto;
      }
    }
  }

  .Banner {
    background: transparent;
    color: #fff;
    font-size: 20px;
    
    font-style: normal;
    font-weight: bold;
    line-height: 32px;
    padding: 10px 221px 10px 10px;

    .bannerSubtitle {
      color: #e8e8e8;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      line-height: 22px;
    }
  }

  .LowDataBanner {
    background: #4e5156;
    border-radius: 5px;
    display: flex;
    height: 70px !important;
    margin-left: 40%;
    margin-top: -270px;
    padding: 15px;
    position: absolute;

    span {
      color: #fff;
    }

    span:first-child {
      padding-right: 15px;
    }

    img {
      height: 32px;
      margin-top: 4px;
      width: 32px;
    }

    a {
      display: block;
      text-decoration: none !important;
    }
  }

  .LowDataBannerTrafficAnalysis {
    background: #fff;
    // border: 1px solid #e8e8e8;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 184px;
    margin-left: -15px;
    margin-top: -12px;
    padding: 15px;
    position: absolute;
    width: 96%;
    z-index: 2;

    span {
      color: #121212;
      font-size: 14px !important;
      font-weight: 400 !important;
      text-transform: none !important;
    }

    span:first-child {
      padding-right: 15px;
    }

    img {
      height: 32px;
      margin-top: 4px;
      width: 32px;
    }

    a {
      display: block;
      padding-top: 5px;
      text-decoration: none !important;
    }

    :global(svg) {
      color: #a3a4a4 !important;
    }
  }

  .LowDataBannerDiffPos {
    background: #f9f9fb;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    display: flex;
    margin-bottom: 15px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 15px;

    span {
      color: #121212;
    }

    span:first-child {
      padding-right: 15px;
    }

    img {
      height: 32px;
      margin-top: 4px;
      width: 32px;
    }

    a {
      display: block;
      text-decoration: none !important;
    }
  }

  .GscImportLeft {
    display: flex;
    flex-direction: column;
    padding-right: 20px;

    :global(.ant-progress) {
      display: flex;
      flex-direction: column-reverse;
      margin-top: auto;
    }

    .ProgressInformation {
      .ProgressRight {
        span {
          color: $color-secondary-text;
          font-size: $font-size-12;
        }
      }

      .ProgressLeft {
        .ProgressText {
          color: #fff;
          font-size: 12px;
          font-style: normal;
          font-weight: normal;
          line-height: 15px;
          margin-bottom: 5px !important;
        }

        .ProgressPercentage {
          margin-left: 5px;
        }
      }
    }

    :global(.ant-progress-text) {
      color: $color-orange-primary;
      font-size: $font-size-12;
      margin-left: 0;
      padding-right: 36px;
      width: 100%;
    }

    :global(.ant-progress-inner),
    :global(.ant-progress-bg) {
      border-radius: 8px;
      height: 5px !important;
    }

    .gscProgressNote {
      color: #e8e8e8;
      
      font-family: Inter;
      font-size: $font-size-12;
      font-style: normal;
      font-weight: 400;
      font-weight: normal;
      letter-spacing: 0;
      line-height: 15px;
      padding-top: 10px;
      text-align: left;
    }
  }

  .GscImportRight {
    .DataBox {
      background-color: rgba(36, 38, 42, 1);
      border-radius: 5px;
      height: 190px;
      
      max-height: 255px;
      padding: 21px 20px 2px;
      width: 300px;

      .DataBoxContent {
        @include scrollbars(5px, #797979, #474550);
        display: flex;
        flex-direction: column;
        height: stretch;
        overflow-y: auto;
        padding: 0 20px 0 0;

        span {
          color: $color-white;
          display: flex;
          font-family: Inter;
          font-size: 14px;
          font-weight: normal;
          justify-content: space-between;
          line-height: 17px;
        }

        .DataBoxTitle {
          color: #fff;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          text-transform: uppercase;
        }

        .DataBoxRows {
          color: #a3a4a4;

          p {
            margin-bottom: 12px !important;
          }
        }
      }

      .SectionName {
        cursor: default;
        max-width: 60%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .GscImportContainerDisabled {
    background: #1b1c1f !important;
  }

  .GscImportContainer {
    background: #24262a;
    margin: 0 -40px;
    padding: 30px 50px 0 40px;
  }

  .bsnnerHeadingSuccess {
    background: linear-gradient(147.93deg, rgba(62, 241, 76, 0.2) 0.18%, rgba(0, 0, 0, 0) 18.77%), linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04)), #24262a;
    border-radius: 8px;
    padding: 20px;
  }

  .bsnnerHeadingFailed {
    background: linear-gradient(147.93deg, rgba(241, 62, 62, 0.2) 0.18%, rgba(0, 0, 0, 0) 18.77%), linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04)), #24262a;
    border-radius: 8px;
    padding: 20px;
  }

  .bannerHeading {
    background: linear-gradient(147.93deg, rgba(241, 170, 62, 0.2) 0.18%, rgba(0, 0, 0, 0) 18.77%), linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04)), #24262a;
    border-radius: 8px;
    padding: 20px;
  }

  .heading {
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: space-between;

    .info {
      align-items: center;
      cursor: pointer;
      display: inline-table;
      margin-left: 7px;

      svg {
        color: $color-gray-light;
        cursor: pointer;
        height: 15px;
        width: 15px;
      }
    }

    h2 {
      color: $color-white;
      font-family: Inter;
      font-size: $font-size-38;
      font-style: normal;
      font-weight: 800;
      line-height: 44px;
      margin-top: 0;
    }

    .gscSubtitle {
      color: $color-white;
      font-family: Inter;
      font-size: $font-size-14;
      font-style: normal;
      font-weight: 500;
      line-height: 175%;
    }
  }

  .cardInner {
    h2 {
      color: $color-white;
      font-family: Inter;
      font-size: $font-size-20;
      font-style: normal;
      font-weight: bold;
      line-height: 150%;
      margin: 0;
    }

    .subpara {
      color: $color-non-primary-text;
      font-size: $font-size-14;
      font-style: normal;
      font-weight: 500;
      line-height: 175%;
      opacity: 0.7;
    }

    h5 {
      color: $color-white;
      font-size: $font-size-12;
      font-style: normal;
      font-weight: bold;
      line-height: 15px;
      margin-bottom: 0;
      margin-top: 0;
    }

    .headingBottom {
      color: $color-secondary-text;
      margin-top: 40px;
    }

    p {
      color: $color-non-primary-text;
      font-family: Inter;
      font-size: $font-size-14;
      font-style: normal;
      font-weight: 500;
      line-height: 175%;
    }

    .darken {
      margin-bottom: 20px !important;
    }


    .checkStyle {
      opacity: 0;
    }

    .checkboxCustom {
      margin-bottom: 10px;
    }

    .bulletsOuter {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      padding-left: 100px;
      padding-right: 100px;


      .dots {
        border-radius: 30px;
        padding: 5px;
        position: absolute;
        top: 3px;
      }

      .bullets {
        color: $color-white;
        font-size: $font-size-12;
        line-height: 15px;
        margin-bottom: 12px;
        padding-left: 50px;
        position: relative;
      }
    }
  }

  .scheduleCard {
    h2 {
      margin-bottom: 18px;
    }

    p {
      margin-bottom: 50px;
    }
  }

  .cardLightTop {
    background-color: rgb(36, 38, 42);
    margin-bottom: -30px;
    margin-top: 25px;
  }

  .cardLight {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.03);
    margin-top: 20px;
    min-height: 94.9%;
  }

  .NumberStats {
    background: rgb(36, 38, 42);
    border-radius: 0;
    box-shadow: none;
    // margin-left: 10px;
    padding: 0;
    // width: -moz-fit-content;
    // width: fit-content;
    width: 97%;
    @media (max-width: 500px) {
      padding: 0;
    }
  }

  .borderNone {
    border: 0 !important;
  }

  .topHeading {
    align-items: center;
    display: flex;

    h4 {
      color: $color-gray-dark;
      font-size: $font-size-12;
      font-style: normal;
      font-weight: bold;
      line-height: 15px;
      margin-bottom: 0;
      margin-top: 0;
      text-transform: uppercase;
    }

    :global(.ant-checkbox-wrapper) {
      margin-right: 5px;
    }

    :global(.ant-checkbox-checked .ant-checkbox-inner) {
      background-color: $color-purple-medium;
      border-color: $color-purple-medium;
    }
  }

  .tableHeader {
    background: $color-white-primary;
    line-height: 22px;
    padding: 10px 20px !important;
    position: relative;

    :global(.main-input) {
      :global(.common-component-input) {
        background-color: $color-white-primary !important;
        margin-left: 30px;

        &::placeholder {
          background-color: $color-white-primary !important;
        }
      }
    }

    :global(.filters) {
      :global(.filterInputItem) {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }

    :global(.main-input) {
      align-items: center !important;
      display: flex !important;
      margin-top: -5px;
      width: 100% !important;

      svg {
        left: 8px;
        position: absolute;
      }
    }
  }

  .ranking {
    .rankingContent {
      border-bottom: 1px solid $color-border;
      display: flex;
      flex-wrap: wrap;
      padding-top: 15px;

      .rankingItem {
        text-align: center;
      }

      .rankingMain {
        flex: 0 0 30%;

        .rankingPara {
          color: $color-secondary-text;
          font-size: $font-size-14;
          font-style: normal;
          font-weight: normal;
          line-height: 17px;
          margin-bottom: 0;
        }

        .rankingMainPara {
          color: $color-gray-dark;
          font-size: $font-size-30;
          font-style: normal;
          font-weight: normal;
          line-height: 34px;
          margin-bottom: 18px;
        }

        &:last-child {
          background: $color-black;
          margin-bottom: 0;
        }
      }

      .rating {
        flex: 0 0 20%;

        .ratingMainPara {
          color: $color-green;
          font-size: $font-size-16;
          font-style: normal;
          font-weight: normal;
          line-height: 19px;
          margin-bottom: 10px;
        }

        .ratingPara {
          color: $color-secondary-text;
          font-size: $font-size-14;
          font-style: normal;
          font-weight: normal;
          line-height: 17px;
          margin-top: 0;
        }
      }

      .falling {
        flex: 0 0 20%;

        .failingMainPara {
          color: $color-red;
          font-size: $font-size-16;
          font-style: normal;
          font-weight: normal;
          line-height: 19px;
          margin-bottom: 10px;
        }

        .failingPara {
          color: $color-secondary-text;
          font-size: $font-size-14;
          font-style: normal;
          font-weight: normal;
          line-height: 17px;
          margin-top: 0;
        }
      }

      .static {
        flex: 0 0 20%;

        .staticMainPara {
          color: $color-gray-dark;
          font-size: $font-size-16;
          font-style: normal;
          font-weight: normal;
          line-height: 19px;
          margin-bottom: 10px;
        }

        .staticPara {
          color: $color-secondary-text;
          font-size: $font-size-14;
          font-style: normal;
          font-weight: normal;
          line-height: 17px;
          margin-top: 0;
        }
      }
    }
  }

  .tableHeader {
    box-shadow: $color-shadow;
    padding: 10px 40px 0;
  }

  .paginationContainer {
    border-radius: 0 0 8px 8px;
    border-top: 1px solid $color-white-secondary;
    box-shadow: 0 -1px 0 $color-white-secondary, 0 -3px 10px rgba(0, 0, 0, 0.02);
    display: flex;
    justify-content: flex-end;
    padding: 20px 25px;
    @media (max-width:600px) {
      padding-right: 2em;
      
    }

    :global(.ant-select-selector) {
      color: #121212;
    }
  
    p {
      color: $color-secondary-text;
      font-size: $font-size-14;
      line-height: 28px;
      margin-bottom: 0;
      padding-left: 20px;
      width: 40%;
    }
  
    :global(.ant-select) {
      z-index: 2 !important;
    }
  }

  .BottomTable {
    border-radius: 0 0 8px 8px;
    box-shadow: 0 -1px 0 $color-white-secondary, 0 -3px 10px rgba(0, 0, 0, 0.02);
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .tableOuterKeywords {
    padding-bottom: 0 !important;

    .ant-table-content {
      border-radius: 0 !important;
    }


    :global(.ant-table-thead > tr > th:nth-child(2)) {
      padding-left: 20px;
    }

    :global(.ant-table-thead > tr > th:nth-child(7)) {
      padding-right: 0 !important;
    }

    :global(.ant-table-thead > tr > th:nth-child(5)) {
      text-align: center;
    }

    table {
      :global(button) {
        background-color: $color-white !important;
        border: 1px solid $color-white-secondary;
        border-radius: 3px;
        color: $color-gray-dark-secondary;
        cursor: pointer;
        font-size: 14px;
        outline: none !important;
        padding: 4px 15px;
      }

      tr td {
        padding-left: 25px !important;
        padding-right: 25px !important;
        text-align: center;

        &:first-child {
          padding-bottom: 0 !important;
          padding-top: 0 !important;
          
        }
      }

      :global(.ant-table-thead) {
        background: $color-white !important;

        :global(.ant-table-cell-fix-left) {
          &:first-child {
            border-top-left-radius: 8px !important;
          }
        }

        th:first-child {
          background: $color-white !important;
          color: rgba(78, 81, 86, 1);
          font-size: 12px;
          font-weight: 700;
        }

        th:nth-child(2) {
          background: $color-white !important;
          color: rgba(78, 81, 86, 1);
          font-size: 12px;
          font-weight: 700;
        }

        th:last-child {
          background-color: $color-white !important;
          border-top-right-radius: 8px !important;
          padding-left: 25px !important;
        }
      }

      :global(.ant-table-cell-fix-left-last) {
        text-align: left !important;
        // background: $color-white !important;
      }

      tr:first-child td {
        background: $color-white !important;
      }

      tr:nth-child(even) td {
        background: $color-white-primary !important;
      }

      tr:nth-child(odd) td {
        background-color: $color-white !important;
      }

      tr th {
        height: 60px !important;
        line-height: 22px;
        white-space: nowrap;
      }

      :global(.ant-column-sorters) {
        padding-left: 20px !important;
        padding-right: 20px !important;
      }

      tr td {
        padding-bottom: 15px;
        padding-top: 15px;
      }

      tr th:first-child,
      tr td:first-child {
        padding-left: 25px !important;
        padding-right: 25px !important;
      }

      tr th:last-child,
      tr td:last-child {
        padding-left: 25px !important;
        padding-right: 25px !important;
        text-align: center;
      }
    }
  }

  .gainLoss {
    display: flex;
    justify-content: center;
    position: relative;
    top: 4px;

    .innerSpan {
      margin-bottom: 10px;
      margin-right: 5px;
      margin-top: auto;
      min-width: 13px;
      white-space: nowrap;
    }

    :global(.gain) {
      align-items: center;
      border: 1px solid $color-white-secondary;
      border-radius: 16px;
      box-sizing: border-box;
      color: $color-pale-green;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0 0 7px !important;
      padding: 3px 9px 3px 7px;
      word-break: normal;
    }

    :global(.loss) {
      align-items: center;
      border: 1px solid $color-white-secondary;
      border-radius: 16px;
      box-sizing: border-box;
      color: $color-red-secondary;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0 0 7px !important;
      padding: 3px 9px 3px 7px;
      word-break: normal;
    }

    :global(.icon) {
      margin-bottom: 0 !important;
      margin-right: 0.1px !important;
    }
  }

  .horizontalOuter {
    h5 {
      color: $color-secondary-text;
      font-size: $font-size-12;
      font-style: normal;
      font-weight: bold;
      line-height: 15px;
    }
  }

  :global(.rc-tabs-tab-active .rc-tabs-tab-btn) {
    background-color: $color-purple-medium !important;
    color: $color-white;
  }

  :global(.rc-tabs-nav) {
    background-color: transparent;
    height: 60px !important;
    padding-top: 0 !important;
  }

  :global(.rc-dialog) {
    @media screen and (max-width: 1024px) {
      max-width: 95%;
    }
  }

  .keywordSearchTable {
    :global(.rc-tabs-tab:first-child) {
      margin-left: 25px !important;
    }

    :global(.rc-tabs-nav) {
      background: $color-gray-dark-medium !important;
    }

    :global(.rc-tabs-tab-btn) {
      padding: 0 !important;
    }

    :global(.rc-tabs.rc-tabs-top) {
      border-radius: 8px !important;
    }

    :global(.rc-tabs-ink-bar.rc-tabs-ink-bar-animated) {
      background-color: $color-orange-primary !important;
      height: 4px !important;
    }

    :global(.rc-tabs-tab-active .rc-tabs-tab-btn) {
      background-color: transparent !important;
    }
  }
}

.cardLight {
  position: relative;

  .assignButton {
    position: absolute;
    right: 40px;
    top: 8px;
    z-index: 1;

    button {
      background: transparent;
      color: $color-non-primary-text;
      font-weight: normal;

      span {
        display: flex;
        position: relative;
        top: 6px;

        svg {
          padding-right: 5px;
          width: 20px;
        }
      }
    }
  }

  .urlTag {
    display: inline-block;
    //max-width: 160px;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: stretch;
    //white-space: nowrap;
  }

  @media screen and (max-width: 1024px) {
    .urlTag {
      max-width: 100px;
    }
  
  }
}

.customModal {
  position: relative;
  top: 34%;

  .asignGroup {
    // text-align: center;
    padding: 1rem 0;

    .drop {
      background: $color-body-background;
      border: 0;
      border-radius: 5px;
      height: 25px;
      margin-top: 10px;
      padding: 8px;

      .ant-select-selector {
        padding: 20px !important;
      }
    }

    h3 {
      color: $color-gray-dark;
      font-size: $font-size-14;
      font-style: normal;
      font-weight: 500;
      line-height: 17px;
      margin-bottom: 0;
    }

    p {
      color: $color-secondary-text;
      font-size: $font-size-14;
      font-style: normal;
      font-weight: 500;
      line-height: 17px;
      margin-top: 10px;
    }
  }
}

.keywordRankingHeading {
  margin-bottom: 20px;
}

.topPagesCard {
  border-radius: 8px;
  min-height: 96% !important;
  padding: 0;
}

.topPagesCardNoHeight {
  border-radius: 8px;
  padding: 0;
}

.topPagesCardHeading {
  padding: 30px 25px;
  padding-bottom: 10px;
}

.tableOuterPages {
  padding-bottom: 0 !important;

  table tr th {
    background-color: #fff;
  }

  table tr td {
    @media (max-width:768px) {
      max-width: 170px;
      width: 50%;
    }

  }

  :global(.ant-table-content) {
    border-radius: 0 !important;
    /*  overflow: visible !important; */
  }

  :global(.ant-table-column-sorters-with-tooltip):not(:first-child) {
    text-align: center;
  }

  table {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;

    :global(button) {
      background-color: #f7f7f8;
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      box-sizing: border-box;
      color: $color-gray-dark-secondary;
      cursor: pointer;
      font-size: $font-size-14;
      outline: none !important;
      padding: 4px 15px;
    }

    th::before {
      width: 0 !important;
    }

    :global(.ant-table-column-sorter) {
      display: flex;
      padding-left: 3px !important;
    }

    :global(.ant-table-column-sorters) {
      display: inline-flex !important;
      padding: 5px !important;
    }

    tr td {
      // padding-right: 20px !important;

      &:first-child {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
    }

    :global(.ant-table-tbody) {
      tr:nth-child(even) {
        td {
          background: $color-white !important;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:hover {
          td {
            background: #eef4fe !important;
          }

          button {
            background: #eef4fe !important;
            border: 0 !important;
            color: #2d6cca;
            font-weight: 500;
          }
        }
      }

      tr:nth-child(odd) {
        td {
          background: $color-white;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:hover {
          td {
            background: #eef4fe !important;
          }

          button {
            background: #eef4fe !important;
            border: 0 !important;
            color: #2d6cca;
            font-weight: 500;
          }
        }
      }
    }

    :global(.ant-table-thead) {
      background: #f7f7f7 !important;
      height: 40px !important;

      th {
        background: #f7f7f7 !important;
        color: #121212 !important;
        font-size: $font-size-12;
        font-weight: 600;
        line-height: 14px;
        padding: 0 !important;
      }

      :global(.ant-table-cell-fix-left) {
        text-align: left !important;

        &:first-child {
          border-bottom-left-radius: 8px !important;
          border-top-left-radius: 8px !important;
        }
      }

      th:first-child {
        background: #f7f7f7 !important;
        border-bottom-left-radius: 8px !important;
        border-top-left-radius: 8px !important;
        color: #121212;
        font-size: $font-size-12;
        font-weight: 600;
      }

      th:nth-child(2) {
        background: #f7f7f7 !important;
        color: #121212;
        font-size: $font-size-12;
        font-weight: 600;
      }

      th:last-child {
        background-color: #f7f7f7 !important;
        border-bottom-right-radius: 8px !important;
        border-top-right-radius: 8px !important;
        padding-left: 25px !important;
      }
    }

    :global(.ant-table-cell-fix-left-last) {
      text-align: left !important;
      // background: $color-white !important;
    }

    tr:first-child td {
      background: $color-white !important;
    }

    tr:nth-child(even) td {
      background: $color-white-primary !important;
    }

    tr:nth-child(odd) td {
      background-color: $color-white !important;
    }

    tr th {
      height: 60px !important;
      line-height: 22px;
      white-space: nowrap;
    }

    :global(.ant-column-sorters) {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    tr td {
      padding-bottom: 15px;
      padding-top: 15px;
      text-align: center;
    }

    tr th:first-child,
    tr td:first-child {
      padding-left: 25px !important;
      padding-right: 25px !important;
    }

    tr th:last-child,
    tr td:last-child {
      padding-left: 25px !important;
      padding-right: 25px !important;
      text-align: center;
    }
  }
}

.secondaryText {
  color: $color-secondary-text;
}

.googleSearchConsoleSearchTermsAndPagesTableStat {
  background: transparent;
}

.chartTooltip {
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 14, 88, 0.1);
  padding: 10px;
  text-align: left;

  .title {
    font-size: $font-size-12;

  }

  .googleEmojiTitle {
    display: block;
    font-size: $font-size-14;
    font-weight: bold;
    max-width: 250px;
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .googleEmojiSubtitle {
    font-weight: normal;
  }

  .inner {
    display: flex;
    justify-content: flex-start;

    .dot {
      border-radius: 50%;
      height: 9px;
      margin-right: 7px;
      margin-top: 7px;
      width: 9px !important;
    }

    .label {
      font-size: $font-size-14;
      font-weight: bold;
      margin-right: 4px;
    }

    .value {
      color: #5f5f5f;
      font-size: $font-size-12;
    }
  }

  .upgradeTextInTooltip {
    background: $color-gray-dark-medium;
    border-radius: 8px;
    color: $color-white;
    font-size: $font-size-14;
    line-height: 20px;
    margin-top: 10px;
    padding: 10px;
    width: 247px;
  }
}

.progressToast {
  background: $color-gray-medium;
  border-radius: 5px;
  padding: 20px;
  position: absolute;
  right: 10px;
  top: 25px;
  z-index: 2;

  .progressToastInner {
    display: flex;

    .description {
      color: $color-white;
    }

    .dismiss {
      color: $color-secondary-text !important;
      margin-left: 10px;
      text-decoration: none !important;
    }
  }
}

.detailsDrawer {
  :global(.ant-drawer-close) {
    color: $color-white;
    left: -55px;
    opacity: 0.7;
    position: absolute;
    top: 15px;

    &:hover {
      opacity: 1;
    }
  }

  :global(.ant-drawer-header) {
    padding: 0 !important;
  }

  :global(.ant-drawer-body) {
    background: #f9f9fb;
  }

  :global(.ant-drawer-content) {
    overflow: visible !important;
  }

  .closeIcons {
    color: #fff;
    font-size: 30px;
  }
}

.understandBtn {
  background: #2d6cca;
  border-radius: 4px;
  margin-bottom: 20px;
  margin-top: 15px;
  width: 45%;
}

.gettingStartedContainer {
  background: #34323e;
  min-height: 100%;

  .gettingStartedContent {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(0deg, #2d2f34, #2d2f34);
    bottom: 0;
    display: flex;
    flex-direction: row;
    height: 150px;
    margin-left: -40px;
    opacity: 0.96;
    padding-bottom: 30px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
    position: fixed;
    width: calc(100% - 230px);
    z-index: 5;

    @media(max-width: 991px) {
      width: 100%;
    }

    .gettingStartedLeft {
      max-width: 50%;
      padding-left: 30px;
      padding-top: 15px;
      width: 100%;

      .gettingStartedTitle {
        color: #fff;
        font-size: $font-size-20;
        font-weight: bold;
        margin-bottom: 5px;
      }

      .gettingStartedDesc {
        color: #dedede;
        font-size: $font-size-14;
        margin-bottom: 28px;
        white-space: nowrap;
      }

      .gettingStartedCtaBtns {
        a {
          align-items: center;
          background: rgba(45, 108, 202, 0.1);
          border: 1px solid rgba(45, 108, 202, 1);
          border-radius: 5px;
          color: #fff;
          display: flex;
          justify-content: center;
          padding: 8px 20px;
          text-decoration: none;
        }
      }
    }

    .closeIcon {
      right: 15px;
      top: 15px;

      &:hover {
        cursor: pointer;
      }
    }

    .gettingStartedRight {
      img {
        border-radius: 10px;
        height: 90px;
      }
    }
  }
}

.errorMessage {
  background-color: #313337;
  border-left: 4px solid #f443a3;
  border-radius: 8px;
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.18);
  opacity: 1;
  padding-bottom: 20px;
  padding-left: 25px;
  position: absolute;
  right: 20px;
  top: 100px;
  width: 340px;
  z-index: 1;

  .title {
    color: $color-white;
    font-weight: 600;
  }

  .subtitle {
    color: $color-white-secondary;
    padding-top: 5px;
  }

  .googleIconDiv {
    background: #4e5156;
    border-top-left-radius: 100%;
    bottom: 0;
    font-size: $font-size-38;
    height: 75px;
    opacity: 0.2;
    position: absolute;
    right: 0;
    width: 75px;

  }

  .googleIcon {
    color: #fff;
    display: flex;
    justify-content: center;
    padding-left: 20px;
    padding-top: 30px;
  }
}

.divWrapper {
  font-size: $font-size-14;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.errorLinkBtn {
  color: #34aef3 !important;
  text-decoration: none !important;
}

.closeIcon {
  color: rgb(255, 255, 255);
  height: 10px;
  opacity: 0.6;
  position: absolute;
  right: -25px;
  top: 5%;
  transform: translateY(-50%);
  width: 10px;
}

.closeIcon:hover {
  opacity: 1 !important;
}

.closeIcon {
  svg {
    height: 40px;
    overflow: visible;
    position: absolute;
    right: -20px;
    top: -25px;
    width: 40px;
  }
}

.closeIcon:active,
.closeIcon:focus {
  border: 0;
  outline: none;
  -moz-outline-style: none;
  outline-style: none;
}

.demoBanner {
  align-items: center;
  background-color: #2d6cca;
  border-radius: 5px;
  color: #fff;
  display: flex;
  height: 50px;
  justify-content: center;
  margin-left: 0 !important;
  position: fixed;
  top: 80px;
  transition: all 0.1s ease;
  width: 85%;
  z-index: 2 !important;

  button {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    color: $color-white;
    font-size: $font-size-14;
    font-weight: 400;
    padding: 3px 15px 4px;

    &:hover {
      background: rgba(0, 0, 0, 0.4);
    }
  }

  img {
    margin-bottom: 3rem;
  }

  p {
    font-size: $font-size-14;
    font-weight: 400;
    margin: 0;
    margin-right: 1rem;
  }

  a {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    color: #fff;
    color: $color-white !important;
    font-size: $font-size-14;
    font-weight: 300;
    height: 30px;
    height: fit-content;
    padding: 0;
    padding: 4px 15px;
    text-decoration: none !important;
    transition: all 500ms ease;
    white-space: nowrap;
    width: 154px;

    &:hover {
      background: rgba(0, 0, 0, 0.4);
    }

    &:focus {
      background: rgba(0, 0, 0, 0.4);
    }
  }
}

.googleContainer {
  align-items: center;
  background: #34323e;
  display: flex;
  justify-content: center;
  min-height: 100%;

  .inner {
    background: $color-body-background;
    border-radius: 5px;
    height: 215px;
    padding: 40px;
    width: 50%;

    .title {
      color: $color-gray-dark;
      font-size: $font-size-20;
      font-weight: 600;
      line-height: 25px;
      padding-bottom: 10px;
      text-align: center;
    }

    .subTitle {
      color: $color-secondary-text;
      font-size: $font-size-16;
      font-weight: normal;
      line-height: 20px;
      text-align: center;
    }

    .actions {
      padding-top: 20px;
      text-align: center;

      .btn {
        background: #4285f4;
        border: 1px solid #ebebfc;
        border-radius: 5px;
        box-sizing: border-box;
      }
    }
  }
}

.menuDots {
  background: none;
  cursor: pointer;
  font-size: $font-size-30;
  height: 35px;
  position: absolute;
  right: 10px;
  width: 35px;
  z-index: 1;

  &.normal {
    top: 50px;
    @media (max-width:600px) {
      top: 30px;
    }
    @media (max-width:390px) {
      top: 50px;
    }
  }

  &.alter {
    top: 103px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.17) !important;

  }

  &:active {
    background: rgba(0, 0, 0, 0.25) !important;
  }

  &:focus {
    background: rgba(0, 0, 0, 0.25) !important;
  }

  &::before {
    background: hsl(0, 0%, 100%);
    border-radius: 50%;
    box-shadow: 00.1px 8px $color-white, 0.01px 16px $color-white;
    content: '';
    height: 3px;
    left: 50%;
    margin-left: -2px;
    position: absolute;
    top: 6px;
    width: 3px;
  }
}

.menuDotsNoProps {
  background: none;
  cursor: pointer;
  font-size: $font-size-30;
  height: 35px;
  position: absolute;
  right: 10px;
  top: 93px;
  transition: all 0.3s ease;
  width: 35px;

  &:hover {
    background: rgba(0, 0, 0, 0.17) !important;

  }

  &:active {
    background: rgba(0, 0, 0, 0.25) !important;
  }

  &:focus {
    background: rgba(0, 0, 0, 0.25) !important;
  }

  &::before {
    background: hsl(0, 0%, 100%);
    border-radius: 50%;
    box-shadow: 00.1px 8px $color-white, 0.01px 16px $color-white;
    content: '';
    height: 3px;
    left: 50%;
    margin-left: -2px;
    position: absolute;
    top: 6px;
    width: 3px;
  }
}

.DashboardTrigger {
  color: $color-purple-medium;
  cursor: pointer;
  font-size: $font-size-30;
  padding-top: 10px;
  position: absolute;
  right: -25px;
  top: 35px;
  transition: all 0.3s ease;

  &:hover {
    font-size: $font-size-38;
  }
}

.darkHeading {
  align-items: center;
  background: $color-gray-dark-medium !important;
  border-radius: 8px 8px 0 0;
  color: $color-white !important;
  display: flex;
  padding-bottom: 19px;
  padding-left: 25px;
  padding-top: 19px;

  h4 {
    color: $color-white !important;
    font-size: $font-size-14 !important;
    font-weight: 500 !important;
    margin-bottom: 0 !important;
    text-transform: unset !important;
  }
}

.lightHeading {
  align-items: center;
  background: $color-white !important;
  border-radius: 8px 8px 0 0;
  color: $color-black !important;
  display: flex;
  padding-bottom: 19px;
  padding-left: 25px;
  padding-top: 19px;

  h4 {
    color: $color-black !important;
    font-size: $font-size-14 !important;
    font-weight: 500 !important;
    margin-bottom: 0 !important;
    text-transform: unset !important;
  }
}

.sectionTitle {
  font-family: Inter;
  font-size: $font-size-20;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 0 !important;
  margin-top: 50px;
}

.sectionTitleAdditional {
  font-family: Inter;
  font-size: $font-size-20;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 0 !important;
  margin-top: 20px;
}

.trafficAnalysisHeading {
  height: 55px;
  padding-left: 25px;
  padding-top: 25px;
  @media screen and (max-width: 900px) {
    padding-left: 10px;
  }

  :global(svg) {
    color: $color-gray-dark !important;
  }

  h4 {
    color: $color-gray-dark-secondary !important;
  }
}

.upgradeTooltip {
  background: $color-white-primary;
  border: 1px solid $color-white-secondary;
  border-radius: 8px;
  box-sizing: border-box;
  color: $color-gray-dark;
  font-size: $font-size-14;
  height: 60px;
  line-height: 20px;
  padding: 10px;
  width: 182px;

}

.tooltipValue {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0;
  filter: blur(5px);
}

.upgradeTooltipLightBg {
  background: $color-white-primary;
  border: 1px solid $color-white-secondary;
  border-radius: 8px;
  box-sizing: border-box;
  color: $color-gray-dark;
  font-size: $font-size-14 !important;
  line-height: 20px;
  padding: 10px;
}

.customPopover {
  .customPopoverTitle {
    color: $color-white;
    font-size: $font-size-14;
    font-weight: 900;
    line-height: 18px;

    .iconSpace {
      margin-right: 8px;
    }
  }

  .customPopoverDescription {
    color: $color-light-gray;
    font-size: $font-size-14;
    font-weight: 500;
    line-height: 175%;
  }

  :global(.ant-popover-inner) {
    background: $color-gray-medium;
    border-radius: 10px;
    box-shadow: 0 5px 13px rgba(0, 14, 88, 0.2);
    max-width: 300px;
  }

  :global(.ant-popover-arrow) {
    border-left-color: $color-gray-medium !important;
    border-top-color: $color-gray-medium !important;
  }
}

.SitemapTable {
  padding-bottom: 21px;

  :global(.ant-table-tbody > tr > td) {
    padding-right: 16px;
  }

  :global(.ant-table-cell) {
    white-space: nowrap !important;
  }

  :global(.ant-table-thead th.ant-table-column-has-sorters):not(:last-child) {
    padding-right: 0;
  }

  :global(.ant-table-column-sorters) {
    padding-right: 0 !important;
  }  

  :global(.ant-table-thead > tr > th:nth-child(2)) {
    padding-right: 16px !important;
  }

  :global(.ant-table-thead > tr > th:nth-child(3)) {
    padding-left: 0 !important;
  }

  :global(.ant-table-tbody > tr > td:nth-child(3)) {
    padding-left: 10px;
  }

  table {
    padding-bottom: 10px;
    word-break: break-all;

    thead > tr {
      th {
        color: $color-gray-dark-secondary;
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    tr th:first-child,
    tr th:nth-child(2),
    tr th:nth-child(3),
    tr th:nth-child(4),
    tr th:nth-child(5),
    tr th:last-child {
      color: $color-gray-dark-secondary;
      position: relative;
      word-break: break-all;
    }

    tr th:first-child,
    tr td:first-child {
      padding-left: 37px !important;
    }

    tr td:nth-child(2) {
      padding-right: 37px !important;

    }

    tr td:last-child {
      padding-right: 37px !important;

    }
  }
}

.popOver {
  padding-left: 8px;
  padding-top: 2px;
  position: relative;
  @media screen and (max-width:600px) {
    display: none;
  }
}

.pulsatingIcon {
  margin-top: 1.2rem;
}

.popOverWhatButtonNone {
  font-size: 12px;
  opacity: 0;
}

.popOverWhatButton {
  align-items: center;
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  // background: #4e5156;
  border-radius: 4px;
  bottom: 8px;
  box-shadow: 0 0 0 0 rgba(#7f4ead, 0.5);
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 38px;
  justify-content: space-around;
  opacity: 0.7;
  padding-right: 1rem;
  transition: all 0.75s infinite ease-in-out;
  width: 125px;

  &:focus {
    animation: none;
    animation: none;
    animation: none;
    animation: none;
    color: #fff;
  }
}

.demoBanner {
  z-index: 4 !important;

  @media screen and (max-width: 991px) {
    height: unset;
    margin-left: -20px;
    padding-bottom: 0.7rem;
    padding-top: 0.7rem;
    right: 0 !important;
    
  }
  @media screen and (max-width: 2070px) {
    
    width: 83%;
  }
  @media screen and (max-width: 1850px) {
    
    width: 81%;
  }
  @media screen and (max-width: 1650px) {
    
    width: 79%;
  }
  @media screen and (max-width: 1550px) {
    
    width: 77%;
  }
  @media screen and (max-width: 1450px) {
    
    width: 75%;
  }
  @media screen and (max-width: 1560px) {
    
    margin-left: -30px;
  }
  @media screen and (max-width: 1400px) {
    
    margin-left: -15px;
    padding: 8px;
    text-align: center;
    width: 100%;
  }
  @media screen and (max-width: 1250px) {
   
    width: 80%;
   
  }
  @media screen and (max-width: 1000px) {
    
    width: 100%;
    
  }


  &Content {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-right: 60px;
    @media screen and (max-width: 991px) {
      padding-left: 60px;
      text-align: center;

    }
    @media screen and (max-width: 491px) {
      flex-direction: column;
      padding-left: 60px;
      text-align: center;

      >p {
        margin-bottom: 1rem;
      }

    }
  }
}

.overankTable {
  height: 250px;
  padding: 0 25px 25px;
  @media (max-width:600px) {
    padding: 30px 10px 25px 15px;
  }
}

.headingText {
  display: flex;
  @media (max-width:1150px) {
    margin-bottom: 2rem;
    padding-left: 1rem;
  }
  @media (max-width:700px) {
    margin-bottom: 2rem;
    padding-left: 7px;
  }
}

.drawerWrapper {
  .topPart {
    align-items: center;
    display: flex;
    margin-bottom: 40px;

    .textPart {
      display: flex;
      flex-direction: column;
      width: 100%;

      .title {
        color: $color-gray-dark;
        font-size: $font-size-20;
        font-weight: 600;
      }

      .paragraph {
        color: $color-gray-dark;
        font-size: $font-size-14;
        font-weight: normal;
      }
    }
  }

  .middlePart {
    :global(.ant-table-content) {
      max-height: none;
    }

    :global(.ant-table-thead > tr > th) {
      background: rgb(247, 247, 251);
      color: rgb(18, 18, 18);
      padding-left: 0;
    }

    :global(.ant-table-thead > tr > th):first-child {
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
    }

    :global(.ant-table-thead > tr > th):last-child {
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
    }

    :global(.ant-table-tbody > tr):nth-child(2) {
      td {
        box-shadow: none;
        padding-top: 10px !important;
      }
    }

    :global(.ant-table-body) {
      max-height: 550px;
      min-height: 300px;
      overflow-y: auto;
      @media (max-height: 850px) {
        max-height: 420px !important;
      }
      @media (max-height: 1150px) {
        max-height: 530px;
      }
    }
    
  }
}

.activeButton {
  margin-left: 20px;
} 

.buttonIcon {
  color: $color-blue;
}

.bottomPart {
  background-color: #f9f9fb;
  bottom: 150px;
  box-shadow: 0 -1px 0 #e8e8e8;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-top: 40px;
  width: 90%;

  .activeProjects {
    .activeProjectTitle {
      font-size: $font-size-14;
      font-weight: 500;
      position: relative;

      :global(.ant-popover) {
        height: 156px;
        left: 0 !important;
        position: absolute;
        top: -250px !important;
        transform-origin: unset !important;
        width: 340px;
      }

      :global(.ant-popover-arrow) {
        display: none;
      }

      :global(.ant-popover-inner) {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #2d2f34;
        border-radius: 8px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.08);
        cursor: pointer;

        :global(.popoverContent) {
          display: flex !important;
          flex-direction: column !important;
        }
        
        :global(.ant-popover-inner-content) {
          color: $color-white;
          display: flex;
        }
      }
    }

    .count {
      font-size: $font-size-16;
      font-weight: 600;
    }
  }


  .bottomWrapper {
    max-width: 256px;

    .tooltip {
      img {
        margin-left: 5px;
      }
    }

    .bottomParagraph {
      color: $color-gray-dark-secondary;
      font-size: $font-size-12;
      font-weight: normal;
      margin-top: 15px;
    }

    .bottomButton {
      height: 44px;
      width: 252px;
    }
  }
}

.gscEmojiContainer {
  bottom: 40px;
  position: absolute;
  @media (max-width:900px) {
    bottom: 280px !important;
  }

  .iconWrapper {
    background: rgba(18, 18, 18, 0.6);
    border-radius: 50%;
    height: 16px;
    top: -2px;
    transition: 0.5s all ease-in-out;
    width: 16px;

    &:hover {
      transform: scale(1.5);
    }

    img {
      bottom: 3.5px;
      height: 8px;
      left: 4px;
      position: absolute;
      width: 8px;
    }
  }

  .iconWrapperLight {
    background: rgba(242, 242, 245, 0.6);
    top: unset;
  }
}

.sitePerformanceCard {
  height: 200px;
}

.countryMap {
  display: flex;
  margin-top: -10px;
}

.countries {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  margin-right: 15px;
  padding: 5px 12px;

  &.inactiveCountries {
    opacity: 50%;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.12);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.12);
  }

  &:focus {
    background-color: rgba(255, 255, 255, 0.12);
  }

  .code {
    color: #e8e8e8;
    font-size: 12px;
    font-weight: 500;
    margin-left: 10px;
  }

  .impressions {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px;
  }
}

.isActive {
  background-color: rgba(255, 255, 255, 0.12) !important;
}

.inputSearchDiv {
  background: $color-white-primary;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  margin-bottom: 30px;
  max-width: 100% !important;
}

.notification {
  align-items: center;
  border-radius: 8px !important;
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.18) !important;
  display: flex;
  height: 80px !important;
  width: 520px !important;
  z-index: 999;

  :global(.ant-notification-notice-message) {
    margin-bottom: 15px !important;
    margin-left: 0 !important;
    margin-top: 16px !important;
    padding: 0 !important;
  }

  :global(.ant-notification-notice-icon) {
    display: none !important;
  }

  :global(.ant-notification-notice-with-icon) {
    align-items: center;
    display: flex;
  }

  .notificationContent {
    display: flex;
    height: 75px;
    padding-top: 13px;
    white-space: pre-wrap;

    img {
      width: 40px;
    }

    .title {
      color: #121212;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
    }

    .description {
      color: #4e5156;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      line-height: 22px;
      margin-bottom: 10px;
    }

    .notificationBtn {
      border-left: 1px solid #c9c9c9;
      color: #2d6cca !important;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 15px;
      padding-left: 16px;
      padding-top: 10px;
    }
  }

}

.infoNotification {
  :global(.ant-notification-notice-icon) {
    display: none !important;
  }

}

.gaIconGray {
  filter: hue-rotate(100deg) contrast(0);
}

.connectGaAccountBanner {
  background: linear-gradient(0deg, rgba(241, 170, 62, 0.1), rgba(241, 170, 62, 0.1)), #fff;
  border-radius: 8px;
  display: flex;
  height: auto;
  justify-content: space-between;
  margin-top: 21px;
  padding: 30px 35px;
  width: 100%;

  .infoContainer {
    display: flex;
    
    .gaIcon {
      height: 43px;
      margin-right: 18px;
      width: 43px;
    }
   
    .title {
      color: $color-gray-dark;
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
    }

    .subTitle {
      color: $color-gray-dark-secondary;
    }
  }

  .btnContainer {
    margin-top: 5px;

    .smallGaIcon {
      height: 16px;
      margin-right: 10px;
      width: 16px;
    }
  }
}

.btnWrapper {
  display: flex;
  margin-top: 10px;
    
  .buttonstyle {
    align-items: center;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    display: flex;
    height: 28px;
    height: 32px;
    width: 75px;
  }
}

.valuetitle {
  font-size: $font-size-12;
}

.buttonContent {
  align-items: center;
  display: flex;

  .loaderContainer {
    display: flex;

    div {
      height: 18px !important;
      width: 18px !important;
    }
  }
}

.buttonText {
  color: $color-white;
  font-size: $font-size-14;
  font-weight: 400;
  margin-right: 10px;
}
