@import './../../scss/mixins.button';
@import './../../scss/mixins.form';
@import '@/style/colors';
@import 'src/style/fonts';

.paginationContainer {
  align-items: center;
  border-top: 1px solid #e8e8e8;
  display: flex;
  justify-content: flex-end;
  padding: 8px 16px;
  width: 100%;


  .pagination {
    align-items: center;
    display: flex;
    margin-right: 0 !important;
    padding: 18px 90px;
    position: relative;
    width: fit-content;

    li:empty {
      display: none;
    }
  
    :global(.ant-pagination-prev) {
      align-items: center;
      background: #fff;
      border: 1px solid #e8e8e8;
      border-radius: 5px;
      color: #121212;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      gap: 10px;
      height: 25px;
      justify-content: center;
      left: 31px;
      line-height: 17px;
      padding: 1px 8px;
      position: absolute;
      width: fit-content;
    }

    :global(.ant-pagination-item) {
      align-items: center;
      background: #fff;
      border: 1px solid #e8e8e8;
      border-radius: 5px;
      color: #121212;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      gap: 10px;
      height: 25px;
      justify-content: center;
      line-height: 17px;
      padding: 1px 8px;
      width: fit-content;
    }

    :global(.ant-pagination-item-active) {
      background: #2d2f34 !important;
      color: #fff !important;
    }

    :global(.ant-pagination-item-1) {
      background-color: #fff !important;
      color: #121212 !important;
      left: -3px;
      position: absolute;
    }

    :global(.ant-pagination-disabled) {
      opacity: 50 !important;
    }

    :global(.ant-pagination-next) {
      align-items: center;
      background: #2d2f34 !important;
      border: 1px solid #e8e8e8;
      border-radius: 5px;
      color: #fff !important;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      gap: 10px;
      height: 25px;
      justify-content: center;
      line-height: 17px;
      padding: 1px 8px;
      position: absolute;
      right: -1px;
      width: fit-content;
    }
   
  }
  
  .fromTo {
    color: $color-gray-light;
    white-space: nowrap;
    @media (max-width:418px) {
      display: none;
    }
  }
}
