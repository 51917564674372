@import '@/style/colors';

.contentContainer {
  margin-top: -80px;
  padding: 0 40px;

  .cardBackground {
    background-color: #fff !important;
  }

  .searchContainerStyle {
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.02);
    height: 60px;

    :global(.sortButtonStyle) {
      background: #f7f7f8 !important;
      border: 1px solid #e8e8e8 !important;
      border-radius: 4px !important;
    }

    :global(.ant-select-selection-item) {
      display: none !important;
    }

    :global(.ant-select-arrow) {
      display: none !important;
    }

    :global(.customSortClass) {
      color: #121212 !important;
      margin-right: -73px;
    }

    :global(.ant-input) {
      color: #121212 !important;
    }
  }

  :global(.rc-tabs) {
    border: transparent;

    :global(.rc-tabs-tab) {
      background: none !important;
      margin: 0 5px;
      padding-bottom: 5px;
    }

    :global(.rc-tabs-ink-bar) {
      background-color: #aa6ee2;
    }
  }

  :global(.ant-collapse-content-box) {
    padding: 0 !important;
  }
}

.registrationModal,
.emailVerificationModal {
  :global(.ant-form-item-explain-error) {
    font-size: 12px;
  }

  .registrationModalHeader,
  .emailVerificationModalHeader {
    .registrationStep {
      align-items: center;
      display: flex;
      justify-content: flex-end;

      .checkCircle {
        margin-right: 5px;

        &:last-child {
          margin-left: 5px;
          margin-right: 0 !important;
        }
      }
    }

    .welcomeText {
      margin-top: 28px;

      h1 {
        font-size: 20px;
        margin-bottom: 7px;
      }

      span {
        color: #121212;
        font-size: 14px;
      }
    }
  }

  :global(.ant-modal-content) {
    border-radius: 8px !important;
    width: 585px;
  }

  :global(.ant-modal-footer) {
    border: 0 !important;
    padding-bottom: 15px;
  }

  .registrationModalContent {
    margin-top: 32px;

    label {
      color: $color-gray-dark-secondary;
      font-size: 12px;
    }

    .error {
      color: #f00;
      font-size: 12px;
      margin-top: 0;
      padding-top: 0;
      position: absolute;
      top: 70px;
    }

    input {
      background: $color-white-primary;
      border: 1px solid #e8e8e8;
      border-radius: 5px;
      box-sizing: border-box;
      margin-top: 5px;
    }


    .goals {
      :global(#goals) {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        :global(.ant-checkbox-wrapper) {
          align-items: center !important;
          background-color: $color-white-primary;
          border: 1px solid $color-light-gray-third;
          border-radius: 5px;
          display: flex !important;
          font-size: 14px !important;
          height: 54px;
          margin-top: 12px;
          padding: 0 11px;
          width: 170px !important;

          &:hover {
            :global(.ant-checkbox-inner) {
              border: 1px solid $color-purple-medium !important;
            }
          }

          :global(.ant-checkbox-checked.ant-checkbox-inner) {
            background-color: #7f4ead !important;
            border-color: #7f4ead !important;
          }

          :global(.ant-checkbox-checked::after) {
            border: 1px solid $color-purple-medium !important;
          }
        }
      }
    }


    .roles {
      :global(.ant-radio-button-wrapper) {
        background-color: $color-white-primary;
        height: 95px;
        width: 97px;
      }

      .radioInner {
        align-items: left;
        align-self: center;
        display: flex;
        flex-direction: column;
        /* width: 95px; */
        height: 100%;
        justify-content: center;

        div {
          height: 22px;
          width: 20px;
        }

        span {
          height: 50%;
          line-height: 17px;
          padding-top: 10px;
        }
      }
    }

    :global(.ant-radio-button-wrapper) {
      background-color: $color-white-primary;
      border: 1px solid #e8e8e8;
      border-radius: 5px;
      margin-right: 10px;
      margin-top: 5px;
      outline: none;

      &::before {
        display: none;
      }

      &:hover {
        border-color: $color-purple-medium;
        color: $color-purple-medium;
      }
    }

    :global(.ant-radio-button-wrapper-checked) {
      background: linear-gradient(0deg, rgba(127, 78, 173, 0.05), rgba(127, 78, 173, 0.05)) #fff !important;
      border-color: #7f4ead !important;
      border-radius: 5px !important;

      span {
        color: $color-purple-medium;
      }

      svg {
        path {
          fill: $color-purple-medium;
        }
      }


    }
  }

  .footerContainer {
    button {
      font-size: 14px;

      span {
        align-items: center;
        display: flex;
      }
    }

    .back {
      text-align: left;
    }

    .next {
      text-align: right;
    }
  }
}

.role {
  border: 1px solid $color-light-gray;
  border-radius: 8px;
  cursor: pointer;
  height: 100px;
  padding: 1rem;
  width: 100px;
}

.roleSelected {
  background: linear-gradient(0deg, rgba(127, 78, 173, 0.05), rgba(127, 78, 173, 0.05)), #fff;
  border: 1px solid rgba(127, 78, 173, 0.7);
  border-radius: 8px;
  color: $color-purple-medium;
  cursor: pointer;
  height: 100px;
  padding: 1rem;
  position: relative;
  width: 100px;
}

.checkCircle {
  font-size: 16px !important;
  position: absolute !important;
  right: -6px !important;
  top: -7px !important;

  svg {
    background-color: #fff;

    path {
      fill: $color-purple-medium;
    }
  }

  path {
    fill: unset !important;
  }
}

.radioInner {
  align-items: left;
  align-self: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  div {
    height: 22px;
    margin-bottom: 0.5rem;
    width: 20px;

  }

  span {
    height: 50%;
    line-height: 17px;
    padding-top: 10px;
  }
}

.radioInnerSelected {
  align-items: left;
  align-self: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  div {
    height: 22px;
    margin-bottom: 0.5rem;
    width: 20px;

    >svg {
      >path {
        fill: $color-purple-medium;
      }
    }
  }

  span {
    height: 50%;
    line-height: 17px;
    padding-top: 10px;
  }
}

.shakingEffect {
  .modalContent {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;

    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
    // border-radius: 8px !important;
    // width: 585px;
  }

  :global(.ant-modal-footer) {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
  }

  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-0.3deg); }
    20% { transform: translate(-3px, 0) rotate(0.3deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(0.3deg); }
    50% { transform: translate(-1px, 2px) rotate(-0.3deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-0.3deg); }
    80% { transform: translate(-1px, -1px) rotate(0.3deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-0.3deg); }
  }
}
