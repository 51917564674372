.notification {
  align-items: center;
  background-color: #000 !important;
  border-radius: 8px;
  display: flex;
  height: 68px;
  opacity: 0.95;
  width: 375px;

  &:global(.ant-notification-notice) {
    padding: 0 !important;
  }

  :global(.ant-notification-notice-icon) {
    bottom: 24px !important;
    font-size: 23px;
    left: 9px !important;
  }
}

.notificationContent {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 300px;}

.title {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-right: 5px;
}

.buttons {
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  margin-right: 20px;
  width: 76px;
  
  @media screen and (max-width: 490px) {
    margin-right: 45px;
  }
}

.icon {
  margin-top: 5px;
}

.box2 {
  height: 5px;
  position: absolute;
  right: -1px;
  top: 63px;
  width: 376px;
}

