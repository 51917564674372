@import '@/style/colors';

.forgotPassword {
  .inputIcon {
    align-items: center;
    color: $color-secondary-text;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    width: 40px;
  }

  .inputOverrides {
    padding-left: 2.3rem !important;
  }

  .forgotBtn {
    border-color: $color-purple-medium;
    color: $color-purple-medium;
    margin-bottom: 1rem !important;

    &:hover {
      opacity: 0.7;
    }
  }



  .errorInput {
    border-color: $color-red;
    box-shadow: none;
    color: $color-red;

    &:hover,
    &:active,
    &:visited,
    &:focus {
      border-color: $color-red;
      box-shadow: none;
    }
  }

}
