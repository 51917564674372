@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/components/common-components/scss/mixins.scroll';

.histogramTooltip {
  display: flex;
  flex-direction: column;
  width: fit-content;

  .title {
    color: #121212;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 7px;
    text-align: left;
    text-transform: uppercase;
  }

  .bold {
    color: #121212;
    font-weight: 600;
  }

  .row {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .label {
      color: #4e5156;
    }
  }
}

.mainContainer {
  align-items: center;
  background: #282b2f;
  border-radius: 8px;
  display: flex;
  display: flex;
  gap: 6px;
  // height: 410px;
  padding: 25px;
  @media (max-width:900px) {
    display: grid !important;
    height: auto !important;

    .verticalCartContainer {
      width: 100%;
    }

    .legendContainer {
      width: 80%;
    }
  }
}

.headingText {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.noData {
  align-items: center;
  color: #a3a4a4;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  height: 180px;
  justify-content: center;
  width: 100%;
}

.matricsSubValues {
  align-items: center;
  color: #e8e8e8 !important;
  display: flex;
  font-weight: regular !important;
  gap: 4px;
}


.legends {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  opacity: 1;
  width: 100%;

  .left {
    display: block;
  }

  .right {
    .legendDataEmpty {

      color: #a3a4a4;
      font-size: 12px;
      font-weight: normal;
      line-height: 15px;
      text-align: right;

    }

    .legendDataIncrease {

      color: #2ac155;
      font-size: 12px;
      font-weight: normal;
      line-height: 15px;
      text-align: right;

    }

    .legendsValue {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      text-align: right;
    }

    .legendDataDecrease {

      color: #f44343;
      font-size: 12px;
      font-weight: normal;
      line-height: 15px;
      text-align: right;

    }
  }
}

.legendsTop {
  margin-top: 0 !important;
  width: fit-content !important;
}

.legendsInactive {
  opacity: 0.4;
}

.legendTitle {
  color: #303030;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  margin-left: -15px;

  .legendDot {
    background: #d4964f;
    border-radius: 4px;
    height: 6px;
    margin-right: 9px;
    margin-top: 6px;
    width: 6px;
  }
}

@media (max-width:900px) {
  .firstChart {
    width: 100% !important;
  }
}

.firstChart {
  height: 323px;
  position: relative;
  width: 30%;
}


.customPopover {
  width: 300px;

  .customPopoverTitle {
    color: $color-white;
    font-size: $font-size-14;
    font-weight: 900;
    line-height: 18px;
  }

  .customPopoverDescription {
    color: $color-light-gray;
    font-size: $font-size-14;
    font-weight: 500;
    line-height: 175%;
  }

  :global(.ant-popover-inner) {
    background: $color-gray-medium;
    border-radius: 10px;
    box-shadow: 0 5px 13px rgba(0, 14, 88, 0.2);
    max-width: 300px;
  }

  :global(.ant-popover-arrow) {
    border-left-color: $color-gray-medium !important;
    border-top-color: $color-gray-medium !important;
  }
}

.gscEmojiContainer {
  img {
    transition: 0.5s all ease-in-out;

    &:hover {
      transform: scale(1.5) !important;
    }
  }
}
