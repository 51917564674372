@import '@/style/colors';
@import 'src/style/fonts';

.wrapper {
  border-radius: 5px;
}

.themeDark {
  background: $color-gray-medium;
  padding: 40px;
}

.themeLight {
  background: $color-white;
  box-shadow: $color-shadow;
  padding: 40px 60px;
}

.noKeywordsContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 300px;

  p {
    color: #717171;
    font-size: $font-size-16;
    font-style: normal;
    font-weight: 300;
    margin-top: 1%;
  }
}

.BtnStyle {
  // align-items: center;
  // display: flex;
  // flex-direction: row;
  // margin-bottom: 18px;

}

.reportCard {
  background: #fbfbfc;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px 25px;
  // max-height: 100%;
  // max-width: 530px;
  // min-height: 246px;
  // min-width: 279px;
  transition: box-shadow 0.3s;
  // &.large{
  //   width: 530px;
  // }

  .reportCardInner {
    display: flex;
    flex-direction: column;
    height: 100%;

    :global(.ant-btn) {
      margin-bottom: 0 !important;
      margin-left: 0 !important;
    }

    .text {
      height: 140px;
      margin-bottom: 20px;

      &.allData {
        height: 80px;
      }

      .paragraph {
        -webkit-box-orient: vertical;
        color: #4e5156;
        display: -webkit-box;
        font-size: 14px;
        font-weight: 400;
        -webkit-line-clamp: 4;
        margin-top: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
      }
    }
  }
}

.emptyReoprtCard {
  align-items: center;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.02);
  display: flex;
  height: 206px;
  justify-content: space-evenly;
  margin-left: 34%;
  width: 534px;
}

.estimatedTimeText {
  color: #4e5156;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 9px;
}
