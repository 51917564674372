@import '@/style/colors';
@import 'src/style/fonts';

.header {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  position: relative;

  .title {
    font-size: $font-size-38;
    font-weight: 800;
  }

  .subTitle {
    color: $color-gray-dark-secondary;
    font-weight: 16px;
    font-weight: 400;
    margin-bottom: 1.5rem;

  }

  .emailCaption {
    background-color: $color-white;
    color: $color-gray-light;
    font-weight: 300;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    width: max-content;
  }

  .caption {
    bottom: -11px;
    left: 0;
    position: absolute;
    right: 0;
    white-space: nowrap;
  }

  .topImg {
    img {
      margin-bottom: 10px;
    }
  }

  @media (max-width:450px) {
    .topImg {
      img {
        margin-bottom: 2rem;
      }
    }
  }

}

@media (max-width: 600px) {
  .header {
    margin-bottom: 2.5rem;
    padding-bottom: 0.5rem;

    .title {
      font-size: $font-size-24 !important;
      font-weight: 800;
    }

    .subTitle {
      margin-bottom: 3rem !important;
    }
  }

}

.registerSize {
  font-size: 30px !important;
}
