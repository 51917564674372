@import '@/style/colors';
@import 'src/style/fonts';

.paginationContainer {
  float: right;
  margin-bottom: 20px;
  margin-top: 20px;
}

.GroupTag {
  border: 1px solid $color-white-secondary;
  border-radius: 16px;
  box-sizing: border-box;
  margin: 0 0 7px !important;
  padding: 5px 9px 5px 7px;
}

.LoaderIcon {
  color: $color-non-primary-text;
  font-size: $font-size-20;
}

.Dialogue {
  :global(.rc-dialog-body) {
    padding: 0;
  }

  h4 {
    padding: 0 2.5em;
  }

  hr {
    margin: 1em 0;
    width: 100%;
  }

  :global(.ant-table-cell) {
    padding: 0 0 0 2.5em;
  }

  :global(.ant-select) {
    margin: 10px 2.2em;
  }

  input {
    margin: 0 2.5em;
    width: 82%;
  }

  select {
    padding: 0 2.5em;
  }

  .DialogueContainer {
    display: flex;
    flex-direction: column;
  }

  .TableStyle {
    margin-bottom: 2em;
  }

  .RuleSaveButton {
    margin: 0 2em;
    width: 200px;
  }

  p {
    padding: 0 2.5em;
  }

  .PageGroupModalHeader {
    background: #2f2e38;
    margin-bottom: 2.5em;
    padding: 11px 0 1px 20px;

    h2 {
      color: #f2f1f2;
      padding: 0 20px;
    }

    :global(.rc-dialog-close-x) {
      color: $color-white;
      text-shadow: none;
    }
  }

  @media screen and (max-width: 1024px) {
    max-width: 95%;
  }
}


