.selectContainer {
  .selectedItemContainer {
    :global(.ant-select-selection-item) {
      div {
        align-items: center;
        display: flex;
        min-width: 145px !important;
        width: 145px !important;
        
      


        .languageName {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis !important;
          white-space: nowrap !important;
          width: 100px;
        }
      }
     
    }
  }
 
}
