@import 'src/style/colors';
@import 'src/style/fonts';

.upgradeDrawer {
  z-index: 9999 !important;

  :global(ant-drawer-content-wrapper) {
    overflow: auto;
  }

  :global(.ant-drawer-body) {
    :global(.ant-drawer-wrapper-body) {
      overflow: auto;

      :global(.ant-drawer-header) {
        background: #fff !important;
        border-bottom: transparent !important;
        padding: 32px 24px 16px;
      }

      :global(.ant-drawer-title) {
        color: #121212;

        .title {
          display: flex;
          justify-content: space-between;
        }

        p {
          color: #4e5156;
          font-size: 14px;
          font-weight: normal;
          line-height: 22px;
          margin-bottom: 0;
        }

        h2 {
          font-size: 20px;
          font-weight: 600;
          line-height: 32px;
        }
      }
    }
  }
}
