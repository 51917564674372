.AiWriterDrawer {
  :global(.ant-drawer-body) {
    background-color: #f9f9fb;
    padding: 30px;

    .contentIdeasTitle {
      align-items: center;
      color: #121212;
      display: flex;
      font-size: 20px;
      font-weight: 600;
    }
  
  }
}
