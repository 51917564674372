@import './../../../scss/mixins.form';
@import 'src/components/common-components/scss/mixins.scroll';


.input {
  @include input-default-style;
}

.textarea {
  @include input-default-style;
  padding-top: 9px;
}

.customTextarea {
  background: #f2f2f5;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  min-width: 30px;
  position: relative;
  z-index: 1;


  .rownr {
    background: transparent;
    border: transparent;
    color: #a3a4a4;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 22px;
    overflow-y: hidden;
    padding-left: 5px;
    padding-top: 9px;
    position: absolute;
    resize: none;
    text-align: right;
    text-align: center;
    vertical-align: top;
    width: 40px;
    z-index: 5;
    z-index: 0;

    &:focus {
      border: transparent !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }

  .txt {
    @include scrollbars(5px, rgba(138,138,138,0.32), rgba(138,138,138,0.12));
    background: #f2f2f5;
    background-color: #2d2f34 !important;
    border: 1px solid transparent !important;
    border: 1px solid #404042 !important;
    border-radius: 5px;
    box-sizing: border-box;
    color: #121212;
    color: $color-white !important;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 22px;
    overflow-x: scroll;
    overflow-x: hidden;
    overflow-y: auto;

    padding-top: 9px;
    resize: none;
    width: 100%;
    z-index: 0;

    &::placeholder {
      left: 0;
      padding-left: 15px !important;
      position: absolute;
    }

    &:focus {
      border: 1px solid #7f4ead !important;
      // box-shadow: none !important;
      outline: none !important;
    }
  }
}


.light {
  background-color: $color-body-background !important;
  border: 1px solid $color-body-background;
  color: #333 !important;

  textarea:first-child {
    background-color: #f2f2f5 !important;
    border: 1px solid transparent !important;
    color: #121212 !important;

    &:focus {
      border: 1px solid transparent !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }
}

.dark {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  color: $color-white !important;

}

.gray {
  background-color: #47494d !important;
  border: 0 !important;
  color: #333 !important;

  textarea:first-child {
    background-color: #47494d !important;
    border: 0 !important;
    color: #fff !important;

    &:focus {
      border: 0 !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }
  
  .customTextarea {
    border: 0 !important;
  }
}


.transparent {
  background-color: transparent !important;
  border: 0 !important;
  color: $color-gray-light !important;
}

.white {
  background-color: $color-white !important;
  border: 1px solid transparent !important;
  color: $color-white !important;

  textarea:first-child {
    background-color: $color-white !important;
    border: 1px solid transparent !important;
    color: #121212 !important;

    &:focus {
      border: 1px solid transparent !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }
}

.lineNumbersText {
  border: 1px solid rgb(232, 232, 232);
  border-radius: 5px;

  textarea {
    padding-left: 0 !important;
  }
}

.custom {
  background: var(--White, #fff) !important;
  border: 1px solid #e8e8e8 !important;
  border-radius: 6px !important;
  color: $color-white !important;

  textarea:first-child {
    background-color: $color-white !important;
    border: 1px solid transparent !important;
    color: #121212 !important;

    &:focus {
      border: 1px solid transparent !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }
}

.customLinks {
  background: var(--White, #fff) !important;
  border: 1px solid #e8e8e8 !important;
  border-radius: 6px !important;

  textarea:last-child {
    background-color: $color-white !important;
    border: 1px solid transparent !important;
    color: #2d6cca !important;

    &:focus {
      border: 1px solid transparent !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }
}
