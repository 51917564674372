@import '@/style/colors';

.error {
  color: #f00;
  font-size: 12px;
  margin-top: 0;
  padding-top: 0;
  position: absolute;
  top: 70px;
}

.headerText {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  line-height: 38px;
  padding-top: 15px;
  text-align: center;

  @media (max-width: 786px) {
    padding: 10px;
    text-align: left;
    width: 100% !important;
  }
}

.ProductPackage {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: auto;

  

  .package {
    border-top: 2px solid #815aa8 !important;
    display: block;
    flex-grow: 1;
    margin-top: 35px;
    min-width: 320px;
    padding: 25px;
    padding-bottom: 0;
    padding-left: 20px;
    position: relative;
    width: 325px;

    @media (max-width: 1030px) {
      width: 140px;
    }

    @media (max-width: 754px) {
      max-width: unset !important;
      width: 100% !important;
    }
    
    .topWrapper {
      min-height: 38px;
      @media screen and (max-width: 754px) {
        align-items: center;
        display: flex;
        gap: 6px;
        justify-content: space-between;
        margin-bottom: 0;
      }

      .topWrapperInner {
        @media screen and (max-width: 754px) {
          align-items: center;
          display: flex;
          gap: 10px;
        }
      }
    }

    .icon {
      display: flex;

      justify-content: space-between;

      svg {
        color: #7f4ead;
        fill: #7f4ead;
        height: 36px;
        width: 36px;
      }

      span {
        background: #fff;
        border: 1px solid #fff;
        border-radius: 5px;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        padding: 5px 13px;
      }
    }

    .planIcon {
      @media screen and (max-width: 1000px) {
        svg {
          height: 24px !important;
          width: 24px !important;
        }
      }
      @media screen and (max-width: 754px) {
        svg {
          height: 20px !important;
          width: 20px !important;
        }
      }
    }

    .planName {
      color: #fff;
      font-size: 28px;
      font-weight: 700;
      line-height: 28px;
      margin-top: 20px;
    }

    .titleText {
      font-size: 24px;
      line-height: 24px;
    
      @media screen and (max-width: 754px) {
        font-size: 20px;
        font-weight: 700;
        margin: 0;
      }
    }
    

    .subHeading {
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 10px;
      margin-top: 3px;
      width: 100%;
    }

    .descriptionText {
      font-size: 16px;
      @media screen and (max-width: 754px) {
        font-size: 11px;
        font-weight: 400;
        line-height: 14px;
        margin: 0 !important;
        width: fit-content;
      }
    }

    .cutPrice {
      color: #fff;
      display: block;
      font-size: 18px;
      font-weight: 400;
      height: 22px;
      line-height: 22px;
      text-decoration-line: line-through;
    }

    .price {
      color: #fff;
      font-size: 42px;
      font-weight: 700;
      line-height: 39px;
      margin-bottom: 10px;

      span {
        color: #808080;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }

    .priceText {
      @media screen and (max-width: 754px) {
        font-size: 18px;
        font-weight: 600;
        gap: 0 !important;
        margin-bottom: 0;
        margin-top: 0 !important;
        white-space: nowrap;

        span {
          color: #fff;
          font-size: 18px;
          font-weight: 600;
        }

        .whiteSpace {
          display: none !important;
        }

        .perText {
          display: none !important;
        }

        .perTextResponsive {
          display: inline !important;
        }
      }
    }

    .priceContainer {
      @media screen and (max-width: 754px) {
        align-items: center;
        display: flex;
        gap: 10px;
      }
    }

    .collapseIcon {
      cursor: pointer;
      display: none;
    }

    .collapseIconResponsive {
      @media screen and (max-width: 754px) {
        display: block;
      }
    }

    .popularTag {
      @media screen and (max-width: 1350px) {
        width: 100% !important;
      }
      @media screen and (max-width: 754px) {
        font-size: 10px !important;
        margin-left: -15px !important;
        margin-top: -35px !important;
      }
    }

    .roundButton {
      button {
        border-radius: 100px !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        justify-content: space-between !important;
        padding-right: 25px !important;
        width: 100% !important;
      }
    }

    .button {
      margin-bottom: 27px;

      button {
        align-items: center;
        background: linear-gradient(85.22deg, #7b42ca 0%, #a25ad1 100%);
        color: #fff;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        height: 48px;
        margin: 0;
        padding: 10px 0;
        padding-left: 20px;
        text-align: left;
        width: 210px;
      }
    }

    .actionButton {
      @media screen and (max-width: 754px) {
        margin-bottom: 0;

        button {
          border-radius: 10px;
          max-width: 348px;
          min-width: 210px;
          width: 90%;
        }
      }
    }

    .offerPackage {
      p {
        align-items: baseline;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 10px;

        svg {
          // color: #1fac47;
          // fill: #1fac47;
          height: 14px;
          margin-right: 10px;
          width: 14px;
        }
      }

      p:first-child {
        img,
        .apple {
          background: #e8e8e8;
          border-radius: 34px;
          height: 24px;
          margin-right: 5px;
          padding: 5px 10px;
          width: 30px;

          svg {
            color: #fff;
            fill: #fff;
            margin-right: 0;
          }
        }
      }

      .itemList {
        font-size: 14px;
        list-style-type: none;
        padding-left: 0;

        li {
          align-items: center;
          display: flex;
          gap: 10px;        
        }
      }
    }
  }

  .planCardBranded {
    border-top-color: var(--brand-color) !important;

    .icon {
      svg {
        color: var(--brand-color) !important;
      }
    }

    .popularBanner {
      background-color: var(--brand-color-darkened) !important;
    }

    .iconWrapper {
      svg {
        color: #f1aa3e !important;
      }
    }

    button {
      background: var(--brand-button-color) !important;
      border-color: var(--brand-button-color) !important;

      &:hover {
        background: var(--brand-button-hover-color) !important;
      }
    }
  }

  .activePlanCardBranded {
    border-radius: 0 !important;
    border-top-color: var(--brand-color) !important;
  
    .icon {
      svg {
        color: #fff !important;
        fill: #fff !important;
      }
    }

    .price {
      span {
        color: #fff !important;
      }
    }
  
    .subHeading {
      color: #fff !important;
    }
  
    .cutPrice {
      color: #a3a4a4 !important;
    }
  
    .price {
      color: #fff !important;
    }
  
    .planName {
      color: #fff !important;
    }

    .detailText {
      // color: #fff !important;  
    }
  
    .detail-text {
      color: #fff !important;
    }
  
    .button {
      button {
        background: #fff !important;
        border: 1px solid transparent;
        color: var(--brand-color) !important;
      }
    }
  }

  .pricingCard {
    @media screen and (max-width: 1350px) {
      flex-grow: 1;
      margin-top: 0;
      max-width: 33%;
      position: relative;
    }
    @media screen and (max-width: 1000px) {
      max-width: 360px;
    }
    @media screen and (max-width: 754px) {
      border-radius: 12px !important;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 20px;
      margin-top: 0;
      max-width: none;
      max-width: 420px;
      padding: 15px !important;
      width: 100%;
    }

    .collapsedItem {
      @media screen and (max-width: 754px) {
        display: none;
      }
    }
  }

  .cardBackgroud {
    background-color: #1a1a1a;
    background-image: linear-gradient(#0706071a, rgba(60, 41, 96, 1));
    border: 1px solid #24262a;
  }

  .cardBackgroudResponsive {
    @media screen and (max-width: 754px) {
      min-width: 350px !important;
    }
  }
}



.activePlanCardDark {
  border-radius: 0 !important;

  .icon {
    svg {
      color: #fff !important;
      fill: #fff !important;
    }
  }

  .subHeading {
    color: #fff !important;
  }

  .cutPrice {
    color: #a3a4a4 !important;
  }

  .price {
    color: #fff !important;
  }

  .detail-text {
    color: #fff !important;
  }

  .button {
    button {
      background: #fff !important;
      border: 1px solid transparent;
      color: #815aa8 !important;
    }
  }

}

.activePlanCardDarkV2 {
  border-radius: 0 !important;

  .icon {
    svg {
      color: #fff !important;
      fill: #fff !important;
    }
  }

  .subHeading {
    color: #fff !important;
  }

  .cutPrice {
    color: #a3a4a4 !important;
  }

  .price {
    color: #fff !important;
  }

  .detail-text {
    color: #fff !important;
  }

  .button {
    button {
      background: #fff !important;
      border: 1px solid transparent;
      color: #815aa8 !important;
    }
  }

}

.wrapper {
  @media screen and (max-width: 1350px) {
    padding-inline: 17px;
  }
  @media screen and (max-width: 754px) {
    padding-inline: 22px;
    padding-top: 0 !important;
  }
}

.pricingContainer {
  padding-top: 56px !important;

  @media screen and (max-width: 1350px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  @media screen and (max-width: 1000px) {
    justify-content: center;
    row-gap: 26px;
  }
  @media screen and (max-width: 754px) {
    gap: 16px;
    padding-top: 45px !important;
  }
}

.offerPackageStyling {
  p {
    gap: 10px !important;
  }
}

.offerPackageStylingResponsive {
  @media screen and (max-width: 754px) {
    margin-top: 20px;
    width: 85%;
  }
}

.offerWrapperStyling {
  @media screen and (max-width: 754px) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.roundButtonStyling {
  width: 100%;
  @media screen and (max-width: 754px) {
    width: 85%;
  }
}


.collapseIconStyling {
  background: #549e79;
  border-radius: 50%;
  color: #fff;
  height: 15px;
  padding: 10px;
  width: 15px;
}

.mostPopular {
  @media screen and (max-width: 754px) {
    margin-top: 0 !important;
  }
}

.bottomSpacing {
  background: none;
  border-radius: 0 !important;
  font-weight: 700 !important;
  margin-bottom: 18px !important;
  padding-bottom: 4px !important;
}

.borderDark {
  border-bottom: 1px solid rgb(0 0 0 / 15%) !important;
}

.borderLight {
  border-bottom: 1px solid rgba(255, 255, 255, 15%) !important;
}

.headerColored {
  color: rgba(170, 133, 236, 1) !important;
}

.boldTextStyle {
  background: none;
  font-weight: 700 !important;
}

.perMonthTextStyle {
  color: #fff !important;
}

.linkStyle {
  color: #aa85ec;
  text-decoration: underline;
  text-decoration-color: linkStyle;
}

.underlineStyles {
  border-bottom: 1px solid #549e79;
  padding-bottom: 1px;
  width: fit-content;
}

.showBrandColor {
  background: linear-gradient(180deg, var(--brand-color-lightened) 0%, rgba(23, 24, 27, 0) 90%) !important;
}

.showDefaultColor {
  background: linear-gradient(180deg, #8155cf 0%, rgba(23, 24, 27, 0) 90%) !important;
}

.showBrandColorNonLinear {
  background: var(--brand-color-lightened) !important;
}

.showDefaultColorNonLinear {
  background: #8155cf !important;
}

.brandColorText {
  // background-color: var(--brand-color-darkened) !important;
  color: var(--brand-color-darkened) !important;
}

.defaultColorText {
  color: rgba(170, 133, 236, 1) !important;
}

.enterpriseBtnStyling {
  height: 49px;
  width: 269px;
}


.enterPriseWrapper {
  align-items: center;
  display: flex !important;
  justify-content: space-between;
}

.enterpriseBtnStyling {
  height: 49px;
  width: 269px;
}


.headingStyle {
  font-size: 46px;
  font-weight: 700;
  line-height: 40.8px;
  margin-bottom: 12px;
}
