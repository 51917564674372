@import './../../scss/mixins.button';
@import './../../scss/mixins.form';
@import '@/style/colors';
@import 'src/style/fonts';

.pagination {
  :global(.ant-pagination-total-text) {
    line-height: 20px;
    margin-top: 6px;
  }

  :global(.ant-select-item-option-state-icon) {
    display: none;
  }

  :global(.ant-pagination-item) {
    background: #f7f7f8 !important;
    border: 1px solid #e8e8e8 !important;
    border-radius: 4px !important;
    box-sizing: border-box !important;

    a {
      color: #121212 !important;
      text-decoration: none !important;
    }
  }

  :global(.ant-pagination-item-active) {
    background: #2d2f34 !important;

    a {
      color: $color-white !important;
    }
  }

  :global(.ant-pagination-item-active a) {
    color: $color-white;
  }

  :global(.ant-pagination-item-link) {
    align-items: center;
    background: #f7f7f8 !important;
    border: 0;
    border-radius: 4px !important;
    box-sizing: border-box !important;
    color: #121212 !important;
    font-size: 20px;
    font-weight: 500 !important;
  }

  :global(.ant-pagination-item) {
    align-items: center;
    align-items: center;
    background: $color-white;
    border: 1px solid $color-white-secondary;
    border-radius: 4px;
    color: $color-green;
    display: inline-flex;
    font-size: $font-size-12;
    font-weight: 500;
    justify-content: center;
    line-height: 27px;

    &:hover {
      opacity: 0.85;
    }
  }

  :global(.ant-pagination-next) {
    background: #f7f7f8 !important;
    border-radius: 4px !important;
    box-sizing: border-box !important;
  }

  :global(.ant-pagination-next),
  :global(.ant-pagination-prev),
  :global(.ant-pagination-next .ant-pagination-item-link),
  :global(.ant-pagination-next .ant-pagination-item),
  :global(.ant-pagination-prev .ant-pagination-item-link),
  :global(.ant-pagination-prev .ant-pagination-item) {
    background: #f7f7f8 !important;
    border: 0 !important;
    border-radius: 4px !important;
    box-sizing: border-box;
    color: #121212 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  :global(.ant-select) {
    background: #f7f7f8 !important;
    border: 1px solid #e8e8e8 !important;
    border-radius: 4px !important;
    box-sizing: border-box;
    display: grid !important;
    height: 28px;
    justify-content: center;
    margin-bottom: 0;
    padding: 0 5px;
    z-index: 1 !important;

    :global(.ant-select-selector) {
      background-color: transparent !important;
      border: 0 !important;
      height: 1.75em !important;
    }

    &:global(.ant-select-open) {
      :global(.ant-select-dropdown) {
        display: inherit;
      }
    }

    &:not(:global(.ant-select-open)) {
      :global(.ant-select-dropdown) {
        display: none;
      }
    }
  }


  :global(.ant-pagination-disabled) {
    opacity: 0.7;
  }

  :global(.ant-select-selector) {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 142px;
  }

  :global(.ant-pagination-options) {
    cursor: pointer;
  }

  :global(.ant-pagination-options-quick-jumper) {
    :global(input) {
      @include input-default-style;
      height: 25px;
      margin-top: -9px;
      width: 50px;
    }
  }

  :global(.ant-pagination-options-size-changer) {
    position: relative;
    z-index: 0;
  }

  :global(.rc-virtual-list-holder) {
    background-color: $color-white;
    border-radius: 3px;
    box-shadow: 0 4px 10px rgba(0, 14, 88, 0.1);

    :global(.ant-select-item) {
      align-items: center;
      display: flex;
      justify-content: center;

      &:hover {
        background-color: #e8e8ff;
        opacity: 0.7;
      }
    }

    :global(.ant-select-item-option-selected) {
      background-color: #e8e8ff;
    }
  }

  :global(.ant-select-selection-search) {
    display: none;
  }
}

.primaryBackLinkBlur {
  :global(.ant-pagination-item):nth-child(n + 8),
  :global(.ant-pagination-next),
  :global(.ant-pagination-next),
  :global(.ant-pagination-item-link),
  :global(.ant-pagination-options) {
    filter: blur(2.5px);
    pointer-events: none !important;
    user-select: none !important;
  }
}

.disableNextPageBlur {
  :global(.ant-pagination-item):nth-child(n + 7),
  :global(.ant-pagination-next),
  :global(.ant-pagination-next),
  :global(.ant-pagination-item-link),
  :global(.ant-pagination-options) {
    filter: blur(2.5px);
    pointer-events: none !important;
    user-select: none !important;
  }
}


.singlePageBlur {
  :global(.ant-pagination-item):nth-child(n + 4),
  :global(.ant-pagination-next),
  :global(.ant-pagination-next),
  :global(.ant-pagination-item-link),
  :global(.ant-pagination-options) {
    filter: blur(2.5px);
    pointer-events: none !important;
    user-select: none !important;
  }
}

.fromTo {
  color: $color-gray-light;
  @media screen and (max-width:450px) {
    display: none;
  }
  @media (max-width:770px) {
    font-size: 12px;
  }
}
