@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/components/common-components/scss/mixins.scroll';


.topHeading {
  font-size: 16px;
  font-weight: 500;
  margin-top: 25px;
  
}

.keyContainer {
  border-radius: 4px;
  width: 450px;

}

.footerRight {
  align-items: center;
  background: #7f4ead;
  border: 1px solid #7f4ead;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  height: 47px;
  justify-content: center;
  padding: 0 20px;
  white-space: nowrap;

  img {
    height: 14px;
    margin-right: 6px;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}
