.tooltipLink {
  background: rgb(246, 246, 246);
  border: 5px solid #fff;
  display: table-cell;
  height: 60px;
  text-align: center;
  vertical-align: middle;
  width: 80px;
}

.checkboxFilterStyle {
  margin: 21px 5px;

  .filterCheckboxs {
    margin-top: 30px !important;
  }
}

.TopUpPopupOverlay {
  // top:56px !important;
  :global(.ant-popover-arrow) {
    :global(.ant-popover-arrow-content) {
      &::before {
        background: #313337;
      }

    }
  }
}

.CustomDropdown {
  display: inline;

  label {
    text-transform: capitalize;

    span {
      top: -1px;

    }
  }
}

.closes {
  cursor: not-allowed !important;
}


.OptionContainer {
  :global(.ant-popover-arrow) {
    display: none;
  }

  :global(.ant-popover-content) {
    background: #fff;
    border-radius: 5px;
    position: relative;
    top: 0;
  }

  :global(.ant-popover-inner) {
    border-radius: 5px;
  }

  :global(.ant-popover-inner-content) {
    border-radius: 5px;
    padding: 0 !important;
  }
}

.modalIconStyle {
  :global(.rc-dialog-close-x) {
    &::before {
      background: transparent !important;
      color: #fff !important;
    }

    &::after {
      background: transparent !important;
      color: #fff !important;
      content: '×';
      font-size: 46 !important;
      font-size: 30px !important;
      height: 10px;
      margin-top: -20;
      position: absolute;
      right: -3rem;
      top: 0;
      transform: matrix(1, 0, 0, -1, 0, 0);
      width: 10px;
    }
  }
}

.selectStyle {
  :global(.ant-select) {
    height: 40px;
    padding: 0 !important;

    &:hover {
      :global(.ant-select-selector) {
        border: 1px solid #e8e8e8 !important;
      }
    }

    :global(.ant-select-selection-search) {
      align-items: center;
      display: flex;
    }

    :global(.ant-select-selector) {
      align-items: center;
      border: 1px solid #e8e8e8;
      border-radius: 5px !important;
      display: flex;
      height: 40px !important;
      padding: 0 15px;
    }

    :global(.ant-select-selection-item) {
      display: flex;
    }

    :global(.ant-select-arrow) {
      svg {
        color: #121212;
        fill: #121212;
      }
    }
  }

}
