@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/components/common-components/scss/mixins.scroll';

.pagesGroupDetailContainer {
  padding: 0 1em;

  input {
    margin: 0;
    width: 100%;
  }

  .searchIcon {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 25px;
    padding-left: 0;

    .searchGrouping {
      align-items: baseline;
      display: flex;
      margin-top: 8px;

      h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        padding-right: 10px;
      }

      svg {
        cursor: pointer;
      }

      button {
        margin-left: 10px;
      }
    }

    .h2 {
      color: #121212;
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
    }

    svg {
      height: 15px;
      width: 15px;
    }
  }
}

.starCheckboxWrapperDisabled {
  :global(.anticon-star) {
    cursor: not-allowed !important;
  }
}

.groupsTableContainer {
  :global(.ant-table-thead > tr > th) {
    padding: 0 1em;
  }

  :global(.ant-table-tbody > tr:nth-child(even)) {
    td {
      background: $color-white-primary !important;
    }
  }

  :global(.ant-table-tbody > tr:nth-child(odd)) {
    td {
      background: $color-white !important;
    }
  }
}

.pageCount {
  padding: 0 3em;
}

.pageListing {
  color: #1c58fe;
  margin-right: 4px;
  text-decoration: underline;
}

.RuleEditButton {
  background: $color-white !important;
  border: 1px solid $color-white-secondary;
  border-radius: 4px;
  box-sizing: border-box;
  color: rgba(78, 81, 86, 1);
  float: right;
  margin: 0 2em;
  padding: 1px 15px !important;
}

.MainContent {
  display: flex;
}

.DetailViewContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  .PageGroupTableContainer {
    :global(.ant-table-thead > tr > th) {
      padding: 0 1em;
    }
  }

  .MenuContainer {
    margin-top: 1em;
    padding: 0 1em;
    width: 100%;

    .LeftContainer {
      cursor: pointer;
      display: flex;
      float: left;

      .Prev {
        display: flex;
      }

      .NonEditable {
        border: 1px solid #2b2b2b;
        font-size: $font-size-14 !important;
        height: min-content;
        margin: -7px 0 0;
        width: auto;
      }

      .NonEditable::placeholder {
        color: #262626;
      }

      .EditButton {
        margin-left: 1em;
      }
    }

    .RightContainer {
      display: flex;
      float: right;

      .DeleteFromPageButton {
        margin-right: 0.5em;
        padding: 1px 15px !important;
      }

      .DeleteButton {
        background: $color-white !important;
        border: 1px solid $color-red;
        color: $color-red !important;
        padding: 1px 15px !important;
      }
    }
  }
}

.Collapsed {
  display: none;
}

.Spacer {
  padding: 0 1em;
}

.PageGroupHeading {
  margin-top: 1px;
  padding: 0 !important;
}

.ConfirmationModal {
  align-items: center;
  display: flex;
  flex-direction: column;

  .ConfirmationGroupName {
    max-width: 100%;
    overflow-wrap: break-word;
  }

  .Danger {
    background: $color-red;
    color: #ffebea;
    width: 40%;
  }

  .Back {
    background: transparent;
    border: 1px solid #6b6979;
    color: #6b6979;
    top: 6px;
    width: 40%;
  }
}

.RemainingIndicator {
  color: $color-purple-medium;
  float: right;
  font-weight: 500;
}

.GroupUpdatingLoader {
  color: $color-purple-medium;
  margin-left: 6px;
}

.groupsBoxContainer {
  @include scrollbars(5px, #282A2E73, #dedee1);
  display: flex;
  max-width: 100%;
  overflow-x: auto;
  padding-bottom: 20px;

  .box {
    background: #fff;
    border-radius: 8px;
    height: 94px;
    margin-right: 16px;
    max-width: 200px;
    min-width: 170px;
    padding: 15px 20px;

    .action {
      display: flex;
      justify-content: space-between;

      span {
        color: #4e5156;
        font-size: 12px;
        line-height: 20px;
      }
    }

    .groupingName {
      color: #121212;
      cursor: pointer;
      font-size: 14px;
      line-height: 22px;
      line-height: 1.3;
      width: fit-content;
    }

    .groupingNameEllipsis {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

}
