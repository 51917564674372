@import 'src/style/colors';
@import 'src/style/fonts';

.unpaidInvoice {
  :global(.rc-dialog-body) {
    padding: 0;
  }
  @media screen and (max-width: 860px) {
    :global(.rc-dialog-content) {
      max-height: 90vh;
      overflow-y: scroll;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }

  .content {
    border-bottom: 1px solid $color-gray-light;
    padding: 30px;
    @media screen and (max-width: 600px) {
      padding: 20px;
    }

    .heading {
      color: $color-gray-dark;
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
    }

    .warring {
      align-items: center;
      background: rgba(244, 67, 67, 0.1);
      border-radius: 5px;
      color: $color-red-primary;
      display: flex;
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      justify-content: center;
      line-height: 17px;

      img {
        height: 20px;
        margin-right: 10px;
        width: 20px;
      }
    }

    .history {
      background: $color-white-primary;
      border: 1px solid $color-white-secondary;
      border-radius: 5px;
      box-sizing: border-box;
      padding: 30px;
      @media screen and (max-width: 600px) {
        padding: 20px 15px;
      }

      .heading {
        color: $color-gray-dark-secondary;
        display: flex;
        font-size: 12px;
        justify-content: space-between;
        line-height: 15px;
      }
    }

    .detail {
      display: flex;
      justify-content: space-between;

      p {
        color: $color-gray-dark;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: normal;
        line-height: 25px;
        margin-bottom: 0;
        padding: 10px 0;
        @media screen and (max-width: 800px) {
          padding: 6px 0;
        }
      }

      .name {
        color: $color-gray-dark;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
      }

      .rightSide {
        display: flex;
        justify-content: flex-end;
        text-align: right;

        .price {
          color: #7f4ead;
          font-size: 36px;
          font-weight: 800;
          line-height: 44px;
          text-align: right;
          @media screen and (max-width: 830px) {
            font-size: 28px;
          }
          @media screen and (max-width: 600px) {
            font-size: 24px;
          }

        }
      }
    }

    .cardDetail {
      margin-bottom: 20px;
      padding-top: 10px;

      @media screen and (max-width: 860px) {
        :global(.ant-collapse-header) {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
        
        :global(.ant-collapse-content-box) {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }

      :global(.ant-radio-group) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 20px;
        overflow: auto;
        padding-top: 10px;
        width: 100%;
        @media screen and (max-width: 840px) {
          justify-content: space-between;
        }
      }

      .cardNumberStyle {
        background-color: $color-white-primary;
        background-image: url('/img/icon/lock.svg');
        background-position: 98%;
        background-repeat: no-repeat;
        border: 1px solid $color-white-secondary;
        border-radius: 5px;
        box-sizing: border-box;
        height: 38px;
        margin-top: 8px;
        padding-left: 10px;
        padding-top: 8px;
        @media screen and (max-width: 840px) {
          padding-top: 12px;
        }
      }

      .cardInputStyle {
        background: $color-white-primary;
        border: 1px solid $color-white-secondary;
        border-radius: 5px;
        box-sizing: border-box;
        height: 38px;
        margin-top: 8px;
        padding-left: 10px;
        padding-top: 8px;
        @media screen and (max-width: 840px) {
          padding-top: 12px;
        }
      }

      .cardNumber {
        color: $color-gray-dark-secondary;
        font-size: $font-size-12;
        line-height: 20px;
        padding-bottom: 8px;
      }

      .cardInfo {
        display: flex;
        font-size: $font-size-12;
        justify-content: space-between;
        margin-top: 10px;

        label {
          color: $color-gray-dark-secondary;
          font-size: $font-size-12;
          line-height: 20px;
          width: 48%;
        }
      }
    }


    .lock {
      align-items: center;
      display: flex;
      justify-content: flex-start;

      img {
        margin-right: 10px;
      }
    }
  }

  .paymentCard {
    align-items: center;
    background: $color-white-primary;
    border: 1px solid $color-white-secondary;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    height: 63px;
    justify-content: center;
    margin: 6px;
    width: 245px;
    @media screen and (max-width: 830px) {
      margin-left: 0 !important;
      margin-right: 0 !important;
      width: 49%;
    }
    @media screen and (max-width: 550px) {
      width: 100%;
    }

    &.newPaymentCard {
      border: 1px dashed $color-white-secondary;
    }

    &.paymentCardActive {
      background: $color-white;
      border: 1px solid #b98ce4;
      box-shadow: 0 6px 17px rgba(0, 0, 0, 0.07);
      box-sizing: border-box;
    }


    :global(.ant-radio-wrapper) {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-left: 15px;
      margin-right: 0;
      width: 225px;
    }

    :global(.ant-radio-checked .ant-radio-inner) {
      border-color: $color-purple-medium !important;
    }

    :global(.ant-radio-checked .ant-radio-inner::after) {
      background-color: $color-purple-medium;
    }

    :global(.ant-radio:hover .ant-radio-inner) {
      border-color: $color-purple-medium;
    }

    .paymentDetail {

      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 180px;


      h2 {
        color: $color-gray-light;
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        margin-bottom: 0;
      }

      img {
        height: 32px;
        width: 32px;
      }

      .paymentInfo {
        display: flex;
        flex-direction: column;


        span {
          color: $color-gray-dark;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
        }

        p {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0;

          span {
            color: $color-gray-dark-secondary;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
          }
        }
      }
    }
  }


  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 30px;

    button {
      height: 38px;
    }

    .cancel {
      align-items: center;
      background: $color-white;
      border: 1px solid $color-white-secondary;
      border-radius: 5px;
      box-sizing: border-box;
      color: #4e5156;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      height: 38px;
      justify-content: center;
      line-height: 22px;
      margin-right: 10px;
      width: 86px;
    }

    .payForInvoice {
      align-items: center;
      display: flex;

      img {
        margin-right: 5px;
        margin-top: -4px;
      }
    }

  }
}

.modalWrapper {
  @media screen and (max-width: 600px) {
    :global(.rc-dialog) {
      width: 95% !important;
    }
  }
}

.billingDetail {
  >div {
    :global(.ant-form-item-label ) {
      text-align: left;
    }
  }
}
