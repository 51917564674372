@import '@/style/colors';

.customInput {
  display: flex;
  position: relative;
  width: 100%;

  :global(.ant-input[disabled]) {
    background-color: #fff !important;
  }

  input {
    border-radius: 4px;
    font-weight: 500;
    margin: 1px;
    padding: 0 10px;
    transition: 0.3s all ease-in-out;
    width: 100%;

    &:hover {
      border-color: $color-purple-medium;
      outline: none;
      transition: 0.3s all ease-in-out;
    }

    &:focus,
    &:active,
    &:visited {
      border-color: $color-purple-medium;
      border-right-width: 1px !important;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      outline: 0;
    }
  }

  textarea {
    border-radius: 4px;
    margin: 1px;
    padding: 5px 10px;
    transition: 0.3s all ease-in-out;
    width: 100%;

    &:hover {
      border-color: $color-purple-medium;
      outline: none;
      transition: 0.3s all ease-in-out;
    }

    &:focus,
    &:active,
    &:visited {
      border-color: $color-purple-medium;
      border-right-width: 1px !important;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      outline: 0;
    }

    .roundInput {
      border-radius: 100px;
    }
  }

  .inputNumber {
    border: 0;
    width: 100%;

    input {
      height: 100%;
      position: absolute;
      top: 0;
    }
  }

}

.light {
  background-color: $color-body-background !important;
  border: 1px solid $color-body-background !important;
  color: #333 !important;
}

.dark {
  background-color: #404042 !important;
  border: 1px solid #404042 !important;
  color: $color-white !important;
}

.transparent {
  background-color: transparent !important;
  border: 0 !important;
  color: $color-gray-light !important;
}

.white {
  background-color: #fff !important;
  border: 1px solid #e8e8e8ff !important;
  color: $color-gray-light !important;
}

.lightGrey {
  background-color: #f9f9fb !important;
  border: 1px solid #f9f9fb !important;
  color: #333 !important;
}

.gray {
  background-color: #47494d !important;
  border: 0 !important;
  color: #fff !important;

  textarea:first-child {
    background-color: #47494d !important;
    border: 0 !important;
    color: #fff !important;

    &:focus {
      border: 0 !important;
      box-shadow: 0 !important;
      color: #fff !important;
      outline: 0 !important;
    }
  }
}
