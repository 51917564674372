@import 'src/style/colors';

.TabList {
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  flex-direction: row;

  span {
    color: $color-icons;
    font-weight: 300;
  }
}
