@import '@/style/colors';
@import 'src/style/fonts';

.infoCard {
  display: flex;
  justify-content: center;
  margin: 0 1.2em;
  min-width: 110px;

  h4 {
    color: $color-white-secondary;
    font-size: $font-size-12;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
  }

  .value {
    color: $color-white-secondary;
    font-size: $font-size-24;
  }

  .differenceOverride {
    margin: 0;
  }
}

.cardsRow {
  display: flex;
}
