.modalBulkStyle {
  :global(.ant-modal-content) {
    background: #f9f9fb;
    border-radius: 8px;
    box-shadow: 0 4px 21px rgba(0, 0, 0, 0.02);
  }

  :global(.rc-dialog-close) {
    opacity: 1 !important;
  }
  
  :global(.rc-dialog-close-x) {
    &::before {
      background: transparent !important;
      color: #fff !important;
    }

    // &::after {
    //   background: transparent !important;
    //   color: #fff !important;
    //   content: '×';
    //   font-size: 30px !important;
    //   height: 10px;
    //   margin-top: -20;
    //   position: absolute;
    //   right: -3rem;
    //   top: 0;
    //   transform: matrix(1, 0, 0, -1, 0, 0);
    //   width: 10px;
    // }
  }
}

// .textAreaStyle {
//   background-color: #FFFFFF !important;
//   border: 1px solid #E8E8E8 !important;
//   color: #333 !important;

//   textarea:first-child {
//     background-color: #FFFFFF !important;
//     border: 1px solid #E8E8E8 !important;
//     color: #121212 !important;

//     &:focus {
//       background-color: #FFFFFF !important;
//       border: 1px solid #E8E8E8 !important;
//       outline: none !important;
//     }
//   }
// }

.selectStyle {
  :global(.ant-select-selector) {
    border-radius: 5px !important;
    height: 38px !important;
  }
}

.mainSlider {
  :global(.ant-slider-handle) {
    background-color: #2d6cca !important;
    border-color: #2d6cca !important;
    height: 16px !important;
    width: 16px !important;
  }
} 

.itemSlider {
  :global(.ant-slider-handle) {
    background: #fff !important;
    border: 1px solid #d2d2d2 !important;
    border-radius: 3px !important;
    height: 16px !important;
    width: 6px !important;
  }

  :global(.ant-slider-rail) {
    background-color: #d2d3d5;
  }
}
