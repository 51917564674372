@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/components/common-components/scss/mixins.scroll';

.input {
  background-color: $color-white !important;
  border: 1px solid $color-light-gray-third !important;
}

.generateIdeasButton {
  height: 38px !important;
}

.termsToIncludeContainer {
  @include scrollbars(5px, rgba(138, 138, 138, 0.32), rgba(138, 138, 138, 0.12));
  margin-top: 20px;
  overflow-y: auto;
}

.numberInput {
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.02);
  height: 38px;
  min-width: 55px;
  padding-left: 10px;

  &:focus-visible {
    outline: none !important;
  }
}
