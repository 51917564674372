@import '@/style/colors';
@import 'src/style/fonts';

@mixin button-default-styles {
  background-color: #8e8e8e;
  border: 1px solid transparent;
  border-radius: 4px;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  font-size: $font-size-16;
  font-weight: 400;
  line-height: 1.5;
  padding: 7px 12px;
  position: relative;
  text-align: center;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  vertical-align: middle;
}

@mixin button-size-xs {
  font-size: $font-size-14;
  padding: 1px 7px;
}

@mixin button-size-sm {
  padding: 4px 7px;
}

@mixin button-size-lg {
  padding: 9px 23px;
}

@mixin button-primary {
  background-color: $color-purple-medium;
  border-color: $color-purple-medium;
  color: #fff;
}

@mixin button-disabled {
  opacity: 0.8;
  pointer-events: none;
}
@mixin outline-button {
  background-color: transparent !important;
  font-family: Inter;
  font-size: $font-size-14;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
}
