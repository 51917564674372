@import '@/style/colors';

.dataExporter {
  align-items: center;
  display: flex;
  text-decoration: none;

  :global(.anticon-select) {
    color: $color-icons;
  }

  .button {
    background: transparent;
    color: $color-light-gray;
    font-weight: 400;
    outline-width: 0;
    padding-left: 0.25em;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}
