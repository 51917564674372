@import 'src/style/colors';
@import 'src/style/fonts';

.hero {
  background-color: #0c0e12;
  box-shadow: $color-shadow;
  color: $color-white;
  height: auto;
  // height: 450px;
  padding: 40px;
  position: relative;
  width: 100%;
  @media screen and (max-width: 768px) {
    padding: 20px 15px;
  }

  &::after {
    background: $color-black-medium;
    bottom: -70px;
    content: '';
    height: 70px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  // @media (max-width:485px) {
  //   height: 341px;
  // }
  // @media (max-width:1000px) {
  //   height: 470px;
  // }
  // @media (max-width:444px) {
  //   height: 388px;
  // }
  // @media (max-width:439px) {
  //   height: 443px;
  // }
  // @media (max-width:383px) {
  //   height: 479px;
  // }

  hr {
    border-color: $color-divider;
    margin-bottom: 30px;
    margin-top: 20px;
  }

  .customSearchAndSortContainer {
    position: relative;

    :global(.ant-row) {
      :global(.ant-input-affix-wrapper) {
        //border: 1px solid $color-gray-dark-secondary;
        //border-radius: 5px;
        height: 37px;
      }
    }
  }

  &.noDataHero {
    min-height: calc(100vh - 150px);
  }
}



.titleHeader {
  align-items: left;
  margin-bottom: 0;

}

.quota {
  font-size: 14px;
  font-weight: 400;
  margin-right: 10px;
}

.stillHaveQuestions {
  color: $color-gray-light;
  font-size: 14px;
  font-weight: 400;
}

.completeToolGuideButton {
  color: $color-link-blue;
  font-size: 14px;
  font-weight: 400;

  &:hover {
    text-decoration: underline;
  }
}

.title {
  color: $color-white;
  font-size: $font-size-24;
  font-weight: 800;
  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
}

.titleQuotaInfo {
  align-items: center;
  color: $color-gray-light;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 7px;

  .quotaWrapper1 {
    @media screen and (max-width: 930px) {
      margin-right: 20px;
    }
  }

  .quotaWrapper2 {
    margin-left: 25px;
    @media screen and (max-width: 1103px) {
      margin-right: 20px;
    }

    @media screen and (max-width: 930px) {
      margin-left: 0;
    }
  }

  .quotaWrapper3 {
    margin-left: 25px;
    @media screen and (max-width: 1103px) {
      margin-left: 0;
    }
  }
}

.toolDescription {
  color: $color-light-gray-third !important;
  font-size: $font-size-14;
  font-weight: 400;
  margin-bottom: 20px;
  max-width: 640px;
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
}

.createPageFormContainer {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.kwInputDesc {
  color: #a3a4a4;
  font-size: $font-size-12;

  .kwDescriptionBtnWithBg {
    background-color: #36383e;
    border-radius: 3px;
    padding: 1px 3px;
  }
}



.ScaArticlesQuotaPopover {
  border-radius: 8px;
  max-width: 300px;

  .popoverText {
    color: $color-white-primary;
  }

  :global(.ant-popover-inner) {
    background-color: $color-black !important;
    border-radius: 8px;
    padding: 10px;
  }

  :global(.ant-popover-inner-content) {
    border-radius: 8px !important;
    padding: 0 !important;
  }

  :global(.ant-popover-arrow) {
    display: none;
  }
}

.description {
  color: $color-light-gray;

  font-family: Inter;
  font-size: $font-size-16;
  font-style: normal;
  font-weight: normal;
  line-height: 150.52%;
}

.helpText {
  color: $color-icons;
  font-family: Inter;
  font-size: $font-size-14;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
}

.optimizeButton {

  margin-right: 15px;

  @media (max-width:1000px) {
    margin-bottom: 20px;
  }

}

.orderButton {
  background: rgba(78, 81, 86, 0.1);
  border: 1px solid #4e5156;
  border-radius: 5px;
  box-sizing: border-box;
  color: $color-white !important;
  font-size: 14px;
  height: fit-content;
  padding: 7px 12px;
  text-decoration: none !important;
}

.optimizeButtonUpgradePlan {
  background: rgba(82, 212, 119, 0.1);
  border: 1px solid $color-pale-green;
  border-radius: 7px;
  box-sizing: border-box;
  color: $color-white !important;
  height: fit-content;
  padding-left: 30px;
  padding-right: 30px;
  text-decoration: none !important;
}

.summary {
  background: $color-gray-dark-medium;
  border-radius: 5px;
  margin-top: 24px;
  padding: 30px 0;
}

.summaryContainer:not(:first-child) {
  border-left: 1px solid $color-gray-dark-secondary;
}

.summaryContainer {
  @media(max-width: 1120px) {   
    border-left: transparent !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 340px !important;
  }
  @media (max-width: 768px) {
    min-width: 50%;
  }
}

.summaryItem {
  padding: 0 30px;
}

.summaryHeader {
  font-size: $font-size-12;
  font-weight: 600;
}

.summaryValue {
  font-size: $font-size-24;
  font-weight: 700;
}

.trafficValue {
  color: $color-white;
  font-size: $font-size-30;
  font-style: normal;
  font-weight: normal;
  line-height: 34px;
  margin-bottom: 0;
}

.trafficValueNote {
  color: $color-icons;
  font-size: $font-size-12;
  font-weight: bold;
  line-height: 15px;
  margin-bottom: 24px;
  text-transform: uppercase;
}

.searchBox {
  $bg-color: #38373c;
  $text-color: $color-icons;
  color: $text-color;

  height: 38px;
  margin-top: 20px;
  max-width: 354px;

  &:global(.ant-input-affix-wrapper) {
    background-color: $bg-color;
    border: 0;
    border-radius: 5px;
    color: $text-color;

    &:focus,
    &:global(.ant-input-affix-wrapper-focused) {
      border-color: $color-icons;
      box-shadow: 0 0 0 2px rgba(206, 213, 220, 0.2);
    }
  }

  :global(.ant-input) {
    background-color: $bg-color;
    color: $text-color;
  }
}

.videoWrapper {
  .crossBtn {
    display: none !important;

    &:hover {
      background: rgba(18, 18, 18, 1) !important;
    }
  }

  &:hover  .crossBtn {
    background: rgba(18, 18, 18, 0.5);
    border-radius: 6px 0 0 6px !important;
    display: block !important;
    padding: 6px 18px !important;
    position: absolute;
    right: 66px;
    top: 230px;
  }
}

.image {
  position: absolute;
  top: 35px;
}

.firstImage {
  height: auto;
  max-height: 282px;
  max-width: 452px;
  right: 200px;
  top: 60px;
  width: 25%;
}

.secondImage {
  border-radius: 5px;
  filter: drop-shadow(0 5px 13px rgba(0, 14, 88, 0.2));
  height: auto;
  max-height: 330px;
  max-width: 529px;
  right: -35px;
  width: 30%;
}

.progressBar {
  margin-left: 4px;
  transform: rotate(270deg);
  width: 21px;

  >div >div {
    margin-left: 0.24rem;
  }
}

.divider {
  border-top: 1px solid $color-divider;
  height: 1px;
  margin: 15px 0 30px;
  width: 100%;
}

.popOver {
  margin-top: -18px;
  padding-left: 8px;
  // padding-top: 13px;
  position: relative;
}

.popOverWhatButtonNone {
  font-size: 12px;
  opacity: 0;
}

.popOverplace {
  margin-top: 13px;
}

.popOverWhatButton {
  align-items: center;
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  background: #4e5156;
  border-radius: 4px;
  bottom: 10px;
  box-shadow: 0 0 0 0 rgba(#7f4ead, 0.5);
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 32px;
  justify-content: flex-end;
  opacity: 0.7;
  padding-right: 1rem;
  position: absolute;
  transition: all 0.75s infinite ease-in-out;
  width: 138px;

  &:focus {
    animation: none;
    animation: none;
    animation: none;
    animation: none;
    color: #fff;
  }
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
    transform: scale(0.97);
  }
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
    transform: scale(0.97);
  }
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
    transform: scale(0.97);
  }
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
    transform: scale(0.97);
  }
}

.selectContainer {
  margin-right: 10px;

  .selectInput {
    color: #fff;
    width: 190px;


    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-right-width: 0 !important;
    }

    svg {
      height: 16px;
    }

    :global(.ant-select-show-arrow) {
      &:focus,
      &:active,
      &:hover {
        box-shadow: none;
        outline: none;
      }
    }

    :global(.ant-select-focused) {
      &:focus,
      &:active,
      &:hover {
        box-shadow: none;
        outline: none;
      }
    }

    :global(.ant-select-selector) {
      align-items: center;
      background: $color-gray-dark-medium;
      border: transparent;
      border-radius: 5px;
      display: flex;
      height: 38px;
      margin-left: 0 !important;

      &:focus,
      &:active,
      &:hover {
        box-shadow: none;
        outline: none;
      }
    }

    :global(.ant-select-arrow) {
      color: #fff;
      right: 38px;
      top: 48%;
    }

    :global(.ant-select-selection-item) {
      align-items: center;
      display: block;
      height: 28px;
    }
  }
}

.importFromUrlBtn {
  color: $color-blurred-blue;
  cursor: pointer;
  margin-bottom: 'auto';
  margin-left: 20px;
  margin-right: 15px;
  margin-top: 5px;
  width: fit-content;
}

.urlInputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  margin-top: 10px;
  // width: 100%;
  max-width: 505px !important;
}

.aiTitleInputContainer {
  display: flex;
  margin-bottom: 0;
  margin-top: 10px;
  padding-right: 12px;
}

.urlInput {
  color: #121212;
  font-size: $font-size-14;
  // background-color: $color-gray-dark-medium !important;
  height: 44px !important;
  width: 380px !important;

  &::placeholder {
    color: #717778;
    font-size: $font-size-14;
    font-weight: 400;
  }
}

.formTabsUrlInput {
  color: #121212;
  font-size: $font-size-14;
  // background-color: $color-gray-dark-medium !important;
  height: 44px !important;
  max-width: 504px !important;
  width: 100%;

  &::placeholder {
    color: #717778;
    font-size: $font-size-14;
    font-weight: 400;
  }
}

.aiArticleTitleInput {
  color: #121212;
  font-size: $font-size-14;
  // background-color: $color-gray-dark-medium !important;
  height: 44px !important;
  margin-right: 12px;
  width: 100% !important;

  &::placeholder {
    color: #717778;
    font-size: $font-size-14;
    font-weight: 400;
  }
}

.urlErrorMsg {
  color: $color-red-primary;
  font-size: $font-size-12;
  height: 20px;
  white-space: nowrap;
  width: 354px;
}

.removeUrlbtn {
  color: $color-gray-light;
  cursor: pointer;
  font-size: $font-size-14;
  margin-bottom: auto;
  margin-right: 15px;
  margin-top: 20px;
}


.row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
  margin-top: 50px;

  .column {
    margin-right: 100px;
  }

  .title {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }


  .body {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
  }

  .contact {
    align-items: center;
    display: flex;
    justify-content: start;
    margin-top: 15px;
  }

  .contactTitle {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
  }

  .contactBody {
    color: #fff;
    display: block;
    font-size: 12px;
    font-weight: 400;
  }

  .displayEnd {
    align-items: center;
    display: flex;
    justify-content: end;
  }

  .imgFrame {
    background-color: rgba(127, 78, 173, 0.5);
    border-radius: 8px;
    padding: 20px;
  }

  .checks {
    color: $color-purple-medium;
    font-size: 20px;
    margin-bottom: -2px;
    margin-right: 10px;
  }
}

.scaPlanBanner {
  background-color: #2d2f34;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 25px 30px;

  .bannerLeft {
    display: flex;

    .bannerIcon {
      font-size: 30px;
      margin-right: 20px;
      margin-top: 4px;
    }

    .bannerTitle {
      font-weight: 500;
    }

    .bannerSubTitle {
      font-size: $font-size-12;
    }
  }
}

.darkCheckbox {
  color: $color-white-secondary;

  :global(.ant-checkbox) {
    :global(.ant-checkbox-inner) {
      background-color: #3e4144 !important;
      border: 1px solid transparent;
      border-radius: 5px;
    }

    &:hover {
      :global(.ant-checkbox-inner) {
        border: 1px solid transparent;
      }
    }
  }

  
}

.dropdownContainer {
  max-width: 500px !important;
  min-width: 300px;
  @media screen and (max-width: 768px) {
    min-width: 150px !important;
  }
}

.selectContainer {
  :global(.ant-select-selector) {
    margin-left: 0 !important;
  }

  .selectedItemContainer {
    :global(.ant-select-selection-item) {
      div {
        align-items: center;
        display: flex;
        min-width: 145px !important;
        width: 145px !important;

        .locationName {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis !important;
          white-space: nowrap !important;
          width: 100px;
        }
      }
     
    }
  }
 
}
