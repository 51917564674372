@import 'src/style/colors';
@import 'src/style/fonts';

.profit {
  display: flex;
  justify-content: flex-start;
  padding-left: 3px !important;
  position: relative;



  :global(.gain) {
    align-items: center;
    background: transparent;
    border-radius: 4px;
    box-sizing: border-box;
    color: $color-light-green !important;
    display: flex;
    flex-direction: row;
    height: 21px;
    justify-content: center;
    margin: 5px 0 7px !important;
    min-width: 31px;
    padding: 0;
    word-break: normal;
  }

  :global(.loss) {
    align-items: center;
    background: transparent;
    border-radius: 4px;
    box-sizing: border-box;
    color: $color-red-secondary;
    display: flex;
    flex-direction: row;
    height: 21px;
    justify-content: center;
    margin: 5px 0 7px !important;
    min-width: 31px;
    padding: 0;
    word-break: normal;
  }

  :global(.textInfinity) {
    font-size: $font-size-20;
  }
}

.textGain {
  color: $color-green;
}

.textLoss {
  color: $color-red;
}

.tooltipContent {
  display: flex;
  flex-direction: column;
  padding: 10px;
  // min-width: 120px;
  .row {
    display: flex;
    justify-content: space-between;

    .label {
      color: #e8e8e8;
      font-size: 12px;
      line-height: 20px;
      min-width: 250px;
    }

    .value {
      color: #fff;
      font-size: 12px;
      font-weight: 500;

      :global(.gain),
      :global(.loss) {
        font-size: 12px !important;
        margin: 0 !important;
      }
    }
  }
}

.tooltipInner {
  :global(.ant-tooltip-inner) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #2d2f34;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.08);
    width: max-content;

    :global(.ant-tooltip-content) {
      width: inherit !important;
    }
  }
}
