@import 'src/style/colors';
@import 'src/style/fonts';


.greenBorder {
  background: rgba(31, 172, 71, 0.1);
  border-radius: 26px;
  padding: 5px 12px;
}

.greyBorder {
  background: #f2f2f5;
  border-radius: 26px;
  padding: 5px 12px;
}


.firstItem {
  align-items: center;
  display: flex;
  margin: auto;
  width: 140px;

  :global(.ant-switch-checked) {
    background-color: #1fac47 !important;
  }

  :global(.ant-popover) {
    height: 174px;
    left: 178px !important;
    position: relative;
    top: 71px !important;
    width: 340px;
  }

  :global(.ant-popover-arrow) {
    display: none;
  }

  :global(.ant-popover-inner) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #2d2f34;
    border-radius: 8px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    
    :global(.ant-popover-inner-content) {
      color: $color-white;
      display: flex;
    }
  }
}
