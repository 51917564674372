@import '@/style/colors';

.contentContainer {
  padding: 0 20px;
}

.registrationModal,
.emailVerificationModal {
  .registrationModalHeader,
  .emailVerificationModalHeader {
    .registrationStep {
      align-items: center;
      display: flex;
      justify-content: flex-end;

      .checkCircle {
        margin-right: 5px;

        &:last-child {
          margin-left: 5px;
          margin-right: 0 !important;
        }
      }
    }

    .welcomeText {
      margin-top: 28px;

      h1 {
        font-size: 20px;
        margin-bottom: 7px;
      }

      span {
        color: #121212;
        font-size: 14px;
      }
    }
  }

  :global(.ant-modal-content) {
    border-radius: 8px !important;
    // width: 410px;
  }

  :global(.ant-modal-footer) {
    border: 0 !important;
    padding-bottom: 15px;
  }

  .registrationModalContent {
    margin-top: 32px;

    label {
      color: $color-gray-dark-secondary;
      font-size: 12px;
    }

    .error {
      color: #f00;
      font-size: 12px;
      margin-top: 0;
      padding-top: 0;
      position: absolute;
      top: 70px;
    }

    input {
      background: $color-white-primary;
      border: 1px solid #e8e8e8;
      border-radius: 5px;
      box-sizing: border-box;
      margin-top: 5px;
    }
    
    // :global(.react-tel-input) {
    //   // margin-top: 5px !important;
    // }

    .goals {
      :global(#goals) {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        :global(.ant-checkbox-wrapper) {
          align-items: center !important;
          background-color: $color-white-primary;
          border: 1px solid $color-light-gray-third;
          border-radius: 5px;
          display: flex !important;
          font-size: 14px !important;
          height: 54px;
          margin-top: 12px;
          padding: 0 11px;
          width: 170px !important;

          &:hover {
            :global(.ant-checkbox-inner) {
              border: 1px solid $color-purple-medium !important;
            }
          }

          :global(.ant-checkbox-checked.ant-checkbox-inner) {
            background-color: #7f4ead !important;
            border-color: #7f4ead !important;
          }

          :global(.ant-checkbox-checked::after) {
            border: 1px solid $color-purple-medium !important;
          }
        }
      }
    }

    
    .roles {
      :global(.ant-radio-button-wrapper) {
        background-color: $color-white-primary;
        height: 95px;
        width: 97px;
      }

      .radioInner {
        align-items: left;
        align-self: center;
        display: flex;
        flex-direction: column;
        /* width: 95px; */
        height: 100%;
        justify-content: center;

        div {
          height: 22px;
          width: 20px;
        }

        span {
          height: 50%;
          line-height: 17px;
          padding-top: 10px;
        }
      }
    }

    :global(.ant-radio-button-wrapper) {
      background-color: $color-white-primary;
      border: 1px solid #e8e8e8;
      border-radius: 5px;
      margin-right: 10px;
      margin-top: 5px;
      outline: none;

      &::before {
        display: none;
      }

      &:hover {
        border-color: $color-purple-medium;
        color: $color-purple-medium;
      }
    }

    :global(.ant-radio-button-wrapper-checked) { 
      background: linear-gradient(0deg, rgba(127, 78, 173, 0.05), rgba(127, 78, 173, 0.05)) #fff !important;
      border-color: #7f4ead !important;
      border-radius: 5px !important;

      span {
        color: $color-purple-medium;
      }

      svg {
        path {
          fill: $color-purple-medium;
        }
      }

      .checkCircle {
        font-size: 16px !important;
        position: absolute !important;
        right: -6px !important;
        top: -7px !important;

        svg {
          background-color: #fff;
        }

        path {
          fill: unset !important;
        }
      }
    }
  }

  .footerContainer {
    button {
      font-size: 14px;

      span {
        align-items: center;
        display: flex;
      }
    }

    .back {
      text-align: left;
    }

    .next {
      padding: 0 !important;
      text-align: left;
    }
  }
}

.checkEmailButton {
  align-items: center;
  background: #7f4ead;
  border-radius: 5px;
  color: #fff !important;
  cursor: pointer;
  display: flex;
  font-size: 14px !important;
  font-weight: 400;
  height: 44px;
  justify-content: center;
  line-height: 22px;
  margin-bottom: -35px;
  margin-top: 10px;
  width: 350px;
}

.notVerifiedText {
  color: #f44343;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
