@import 'src/style/colors';

.cardBackground {
  background-color: #fff !important;
}

.loader {
  .loaderContainer {
    display: flex;
    height: 100%;
    text-align: center;
    width: 100%;
  }

  .loaderSpinner {
    animation: spin 2s linear infinite;
    border: 2px solid $color-border-medium;
    border-radius: 50%;
    border-top: 2px solid $color-purple-medium;
    height: 32px;
    margin: auto;
    width: 32px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.campaignsLoader {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 300px;
}
