@import 'src/style/colors';
@import 'src/style/fonts';

.areaTooltip {
  color: inherit;
}

.heading {
  color: $color-gray-dark;
  font-size: $font-size-14;
  font-style: normal;
  font-weight: bold;
  line-height: 17px;
}

.subHeading {
  font-size: $font-size-14;
  left: 10px;
  line-height: 18px;
  margin-left: 10px;
  margin-right: 20px;
  position: relative;

  &::before {
    content: '';
    left: -20px;
    position: absolute;
    top: 6px;
  }

  .outer {
    display: flex;
    margin: 0;
    padding: 0;
  }
}

.one {
  &::before {
    border-bottom: 8px solid $color-green;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }
}

.two {
  &::before {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid $color-red;
  }
}

.customPopover {
  .customPopoverTitle {
    color: $color-white;
    font-size: $font-size-14;
    font-weight: 900;
    line-height: 18px;
  }

  .customPopoverDescription {
    color: $color-light-gray;
    font-size: $font-size-14;
    font-weight: 500;
    line-height: 175%;
  }

  :global(.ant-popover-inner) {
    background: $color-gray-medium;
    border-radius: 10px;
    box-shadow: 0 5px 13px rgba(0, 14, 88, 0.2);
    max-width: 300px;
  }

  :global(.ant-popover-arrow) {
    border-left-color: $color-gray-medium !important;
    border-top-color: $color-gray-medium !important;
  }
}

.LowDataBannerGoogleChange {
  background: #f9f9fb;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  display: flex;
  left: 35%;
  margin-top: -250px;
  padding: 15px;
  position: absolute;

  span {
    color: #121212;
  }

  span:first-child {
    padding-right: 15px;
  }

  img {
    height: 32px;
    margin-top: 4px;
    width: 32px;
  }

  a {
    display: block;
    text-decoration: none !important;
  }
}
