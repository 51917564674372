@import 'src/style/colors';
@import 'src/style/fonts';

.columns {
  td {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .imgDivWrapper {
    align-items: center;
    display: flex;
    padding: 9px 5px 9px 0;
  
    .projectName {
      font-size: $font-size-14;
      font-weight: 500;

      a {
        color: #2d6cca !important;
        word-break: break-all !important;
      }
    }

    .icon {
      height: 26px;
      margin-right: 10px;
      width: 26px;
    }

    .dataWrapper {
      align-items: center;
      display: flex;

      .projectKwDiv {
        align-items: center;
        color: $color-gray-dark-secondary;
        display: flex;
        font-size: $font-size-12;
        font-weight: normal;
      }

      .dot {
        background: $color-gray-dark-secondary;
        border-radius: 50%;
        display: inline-block;
        height: 5px;
        margin: 0 5px;
        width: 5px;
      }
      
      .pagesDiv,
      .trafficValueDiv {
        color: $color-gray-dark-secondary;
        font-size: $font-size-12;
        font-weight: normal;
      }
    }
  } 
}
