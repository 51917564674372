@import '@/style/colors';
@import 'src/style/fonts';

.reportCard {
  background: $color-white;
  border-radius: 5px;
  box-shadow: $color-shadow;
  display: flex;
  margin-top: 20px;

  .pannelOne {
    background: linear-gradient(180deg, #383542 0%, #25242b 100%);
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    max-width: 364px;
    min-width: 330px;
    padding: 40px;
    width: 30%;

    h5 {
      color: $color-white;
      font-size: $font-size-12;
      font-style: normal;
      font-weight: bold;
      line-height: 15px;
      text-transform: uppercase;
    }

    p {
      color: $color-non-primary-text;
      font-family: Inter;
      font-size: $font-size-14;
      font-style: normal;
      font-weight: 500;
      line-height: 175%;
    }

    .checkboxCustom {
      margin-bottom: 10px;
    }

    .bulletsOuter {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .dots {
        border-radius: 30px;
        padding: 5px;
        position: absolute;
        top: 3px;
      }

      .bullets {
        color: $color-non-primary-text;
        flex: 0 0 50%;
        font-size: $font-size-12;
        line-height: 15px;
        margin-bottom: 12px;
        position: relative;
      }
    }

    .headingBottom {
      color: $color-secondary-text;
      margin-top: 40px;
    }

    .subpara {
      color: $color-non-primary-text;
      font-size: $font-size-14;
      font-style: normal;
      font-weight: 500;
      line-height: 175%;
      opacity: 0.7;
    }
  }

  .pannelTwo {
    align-self: center;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    flex-grow: 11;
    flex-shrink: 0;
    padding: 40px 60px;
  }
}
