@import 'src/components/common-components/scss/mixins.scroll';

.promotedUrl {
  color: #2d6cca;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  width: fit-content;
}

.urlsTooltip {
  :global(.ant-tooltip-inner) {
    border-radius: 12px;
    overflow: hidden;
    padding: 0;

    .scrollable {
      @include scrollbars(4px, rgba(138,138,138,0.62), rgba(138,138,138,0.12));
      max-height: 400px;
      overflow-y: auto;
      padding: 16px 12px;
    }
  }
}
