.filterPopup {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
  // left: -295px;
  padding: 15px 20px;
  // position: absolute;
  // top: 0;
  width: 285px;

  &Options {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 11px;

    .checkboxesStyle {
      :global(.ant-checkbox-group-item) {
        width: 46%;
      }
    }

    .saCheckboxesStyle {
      :global(.ant-checkbox-group-item) {
        white-space: nowrap;
      }
    }
    
  }

  &Buttons {
    display: flex;
    justify-content: flex-end;
    padding-top: 11px;

    >button {
      margin-left: 10px;
    }
  }

  &ButtonCancel {
    background: #7f4ead;
    border: 1px solid #7f4ead;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    height: 38px;
    width: 86px;
  }

  &ButtonApply {
    background: #7f4ead;
    border-radius: 5px;
    border-width: 0;
    color: #fff;
    cursor: pointer;
    height: 38px;
    outline-width: 0;
    width: 114px;
  }
}

.disabled {
  background: rgba(127, 78, 173, 0.11);
  border: 1px solid transparent;
  border-radius: 5px;
  color: #7f4ead;
  cursor: not-allowed;
  font-size: 14px;
  height: 38px;
  outline-width: 0;
}
