@import '@/style/colors';
@import 'src/style/fonts';

.sortByButton {
  background: #f7f7f8 !important;
  border: 1px solid #e8e8e8 !important;
  color: #121212 !important;
  margin: 0 10px;

}

.menuIcon {
  color: #a3a4a4;
  width: 14px;
}

.changeIconColor {
  color: #121212;
  width: 14px;
}

.filterInput {
  display: flex;
  height: 60px !important;
  // justify-content: space-between;
  padding-bottom: 15px;
  padding-top: 15px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: row;
    height: 100px;
    justify-content: flex-start;
  }

  .filtersContainer {
    @media (max-width:1400px) {
      justify-content: flex-start !important;
    }
  }

  :global(.filters) {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    @media (max-width:1400px) {
      justify-content: space-between;
    }
    @media (max-width: 785px) {
      justify-content: unset !important;
    }
    @media (max-width: 430px) {
      height: 100px;
      justify-content: unset !important;
    }
  }

  :global(.main-input) {
    align-items: center;
    display: flex;
    margin-top: 0;
    // padding-bottom: 15px;
    width: 100%;
    @media (max-width:1400px) {
      margin-bottom: 0.4rem;
    }
    @media (max-width:411px) {
      margin-top: 1rem;
      padding-bottom: unset;
    }

    :global(.search-icon) {
      color: $color-secondary-text;
      width: 1.5em;
    }

    

    :global(.common-component-input::placeholder) {
      background: $color-white-primary;
      color: #a3a4a4;
      font-size: $font-size-14;
    }

    :global(.common-component-input) {
      background: $color-white-primary;
      color: #121212;
      font-size: $font-size-14;
    }

    :global(img) {
      height: 23px;
      margin: 0 5px;
      margin-left: 0;
      position: relative;
      top: 8px;
    }
  }
}

.BtnDiv {
  color: $color-secondary-text;
  display: flex;
  padding-left: 15px;
  padding-top: 7px;
}

.BtnDivSorter {
  color: $color-secondary-text;
  display: flex;
}

.GarbageIcon {
  cursor: pointer;
  font-size: 18px;
  padding-top: 5px;
}

.Btn {
  align-items: center;
  background: #f7f7f8;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  box-sizing: border-box;
  color: #121212;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 32px;
  justify-content: center;
  margin-left: 15px;
  transition: all 0.3s ease;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: darken(#f7f7f8, 3%);
    border: 1px solid darken(#e8e8e8, 5%);
  }

  img {
    cursor: pointer;
    padding-right: 10px;
  }
}

.filterInputItem {
  height: 0;
  margin-left: 0;
  margin-right: 0;
  padding-top: 0 !important;
  position: relative;
  z-index: 3;

  @media screen and (max-width: 991px) {
    margin-right: 0;
    width: auto;
  }

  &.isBodyVisible {
    box-shadow: 0 4px 10px rgba(0, 14, 88, 0.1);

    :global(.body) {
      background: $color-white-primary;
      border-radius: 6px;
      box-shadow: 7px 6px 24px rgba(0, 0, 0, 0.2);
      display: inline-block;
      height: 170px;
      margin-top: 0.7rem;
      opacity: 1;
      padding-top: 15px;
      position: absolute;
      right: 0;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 324px;
      @media (max-width:1400px) {
        // right: unset;
      }
      @media screen and (max-width: 991px) {
        left: -40px;
        right: unset;
      }
      @media screen and (max-width: 785px) {
        left: -50px;
        right: unset;
        width: 300px;
      }
    }
  }


  :global(.header) {
    border-right: 1px solid $color-white-secondary;

    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    @media (max-width:1400px) {
      border-right: unset;
      padding-left: unset;
      padding-right: unset;
    }

    :global(.label) {
      align-content: center;
      border: 1px solid transparent;
      color: $color-secondary-text;
      display: grid;
      font-size: $font-size-14;
      gap: 4px;
      grid-auto-flow: column;
      justify-content: center;
      padding: 0 15px;
      text-align: center;
      @media (max-width:1400px) {
        display: flex;
      }

      &:hover {
        background-color: #fff;
        border: 1px solid $color-white-secondary;
        border-radius: 4px;
      }

      :global(.filter-title) {
        white-space: nowrap !important;
      }

      @media screen and (max-width: 991px) {
        grid-auto-flow: row;

        strong {
          grid-column: 1 / span 2;
        }

        span {
          white-space: nowrap;
        }
      }

      :global(.icon) {
        img {
          color: #333;
          height: 17px;
          left: 5px;
          opacity: 0.7;
          position: relative;
          top: 0;
        }
      }
    }
  }

  :global(.body) {
    display: none;
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    :global(.bodyElement) {
      margin: 7px 8px;
      padding-top: 0 !important;

      :global(.button) {
        width: 100%;
      }
    }
  }
}

// TODO: remove all that :global override, so we can stop using important here.

.calcelBtn {
  border: 1px solid $color-white-secondary;
  box-sizing: border-box;
  color: rgba(78, 81, 86, 1) !important;
  display: inline-block;
  text-align: center;
  text-decoration: none !important;
  width: 100%;

  &:hover {
    opacity: 0.8;
  }
}

.in {
  background-color: #fff !important;
  border: 1px solid $color-white-secondary;
  height: 38px !important;
  width: 133px !important;

  &:focus {
    border: 1px solid #7f4ead;
    border-radius: 5px;
    box-sizing: border-box;
  }
}

.btnDisabled {
  background-color: #8e8e8e !important;
  border-color: #8e8e8e !important;
  cursor: default;
}

.any {
  color: #121212;
  font-weight: 500;
  white-space: nowrap;
  @media (max-width:1200px) {
    display: none;
  }
}

.filterActive {
  align-content: center;
  background-color: #fff;
  border: 1px solid transparent;
  border: 1px solid $color-white-secondary;
  border-radius: 4px;
  color: $color-secondary-text;
  display: grid;
  font-size: $font-size-14;
  gap: 4px;
  grid-auto-flow: column;
  justify-content: center;
  min-width: max-content;
  padding: 0 15px;
  text-align: center;
}

.filtersContainer {
  display: flex;
  height: 60px;
  justify-content: center;
  @media (max-width: 700px) {
    justify-content: flex-start;
  }
  @media (max-width: 400px) {
    height: 60px;
  }
}

.addButton {
  background-color: #ece4f3;
  border-radius: 4px;
  color: #7f4ead;
  cursor: pointer;
  display: grid;
  height: 32px;
  place-items: center;
  width: 76px;

  &:hover {
    background-color: darken(#ece4f3, 2%);
    color: darken(#7f4ead, 3%);
  }
}



.filterTag {
  align-items: center;
  background: #f7f7f7;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  margin-bottom: 6px;
  margin-right: 10px;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  transition: all 0.3s ease;
  width: max-content;

  &:hover {
    background-color: darken(#f7f7f7, 2%);
    border: 1px solid darken(#e8e8e8, 8%);
  }
}

.popupClasses {
  left: 0 !important;
  top: 2.5rem !important;
  z-index: 3;
}

.actions {
  display: flex;
}

.filtersList {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 400px) {
    margin-top: 10px;
  }

  :global(.ant-select) {
    align-items: center;
    align-items: center;
    background: #f7f7f8;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: 30px !important;
    justify-content: center;
    

    :global(.ant-select-selection-item) {
      color: #121212 !important;
      font-size: 14px !important;
      font-weight: 400 !important;
    }
  }
}

.ant-select-dropdown {
  background: #fff;
  border-radius: 5px;
  height: 200px !important;
  left: 105px !important;
  overflow-y: auto;
  width: 200px !important;

  :global(.ant-select-item-option-content) {
    color: #121212;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

.checkBoxFiltersStyled {
  left: 0 !important;
  top: 37px !important;
  z-index: 9;

  :global(.ant-checkbox-group-item) {
    width: 100% !important;
  }
}

.OptionContainer {
  :global(.ant-popover-arrow) {
    display: none;
  }

  :global(.ant-popover-content) {
    background: #fff;
    border-radius: 5px;
    position: relative;
    top: 0;
  }

  :global(.ant-popover-inner) {
    border-radius: 5px;
  }

  :global(.ant-popover-inner-content) {
    border-radius: 5px;
    padding: 0 !important;
  }
}
