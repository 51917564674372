@import '@/style/colors';
@import 'src/style/fonts';

.serpUrl {
  display: flex;

  .position {
    padding-right: 16px;
    position: relative;
    width: 16px;

    span {
      align-items: center;
      border-radius: 50%;
      color: $color-white;
      display: flex;
      font-size: $font-size-12;
      height: 18px;
      justify-content: center;
      position: absolute;
      top: 5px;
      width: 18px;
    }
  }

  .url {
    .title {
      color: $color-gray-dark;
      max-width: 370px !important;
      overflow: hidden;
      text-overflow: ellipsis;

    }

    .page {
      color: $color-blue !important;
      display: block;
      font-size: $font-size-12;
      overflow: hidden;
      text-decoration: none !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 300px;
    }
  }

  .green {
    background: $color-green;
  }

  .black {
    background: linear-gradient(180deg, #383542 0%, #25242b 100%);
  }
}
