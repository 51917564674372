@import 'src/style/colors';
@import 'src/style/fonts';

.trailSec {
  background: #24262a;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 10px;
  padding-left: 16px;
  position: fixed;
  transition: width 0.2s;

  .pricing {
    align-items: center;
    border-radius: 4px;
    color: #e8e8e8;
    cursor: pointer;
    display: flex;
    flex-grow: 1;
    font-size: 15px;
    font-weight: 500;
    height: 36px;
    letter-spacing: -0.01em;
    line-height: 22px;
    overflow: hidden;
    padding: 3px 10px;
    white-space: nowrap;

    &:hover {
      background: rgba(78, 81, 86, 0.2);
      color: #fff;
    }

    &.active {
      background: rgba(136, 96, 212, 0.2) !important;
      color: #fff;
    }
 
  }


  a {
    align-items: center;
    border-radius: 4px;
    color: #e8e8e8;
    display: flex;
    flex-grow: 1;
    font-size: 15px;
    font-weight: 500;
    height: 36px;
    letter-spacing: -0.01em;
    line-height: 22px;
    overflow: hidden;
    padding: 3px 10px;
    white-space: nowrap;

    &:hover {
      background: rgba(78, 81, 86, 0.2);
      color: #fff;
    }

    &.active {
      background: rgba(136, 96, 212, 0.2) !important;
      color: #fff;
    }
  }

}

.opacity {
  background: linear-gradient(180deg, rgba(31, 33, 37, 0) 0%, #1f2125 100%);
  border-bottom: 1px solid #333539;
  bottom: 147px;
  height: 40px;
  left: 0;
  margin-bottom: 0;
  position: fixed;
}

.header {
  &__sca-menu-item {
    align-items: center;
    color: $color-gray-light;
    cursor: pointer;
    display: flex;
    margin-top: 3px;
    padding: 5px;
    transition: all 0.3s ease;
    white-space: nowrap;

    &:hover {
      color: $color-white;
    }

    svg {
      font-size: 22px;
      margin-left: 10px;
    }
  }
}

.sidebarCTA {
  background: #24262a !important;
  color: $color-light-gray;
  font-size: $font-size-12;
  font-weight: 300;
  margin: 20px 15px 10px;
  
  @media (max-width:992px) {
    display: none;
  }
}

.testPageLink {
  align-items: center;
  border-radius: 4px;
  color: #e8e8e8;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  font-size: 15px;
  font-weight: 500;
  height: 36px;
  letter-spacing: -0.01em;
  line-height: 22px;
  overflow: hidden;
  padding: 3px 10px;
  white-space: nowrap;

  &:hover {
    background: rgba(78, 81, 86, 0.2);
    color: #fff;
  }
}


.postOnboardingBanner {
  background-color: #b98ce426;
  border-radius: 8px;
  color: #fff;
  margin-bottom: 17px;
  margin-left: 2px;
  padding: 10px;
  position: relative;
  text-align: left;

  &.dockedSidebar {
    background-color: transparent;
    padding-left: 8px;
  }

  .postOnboardingCloseIcon {
    cursor: pointer;
    display: none;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &:hover {
    .postOnboardingCloseIcon {
      display: block;
    }
  }
}
