.loader {
  animation: effect 1s linear infinite;
  border: 3px solid #e4e4e477;
  border-radius: 50%;
  border-top-color: #aaa1a1;
  height: 30px;
  margin: auto;
  transform: translate(-40%, -50%);
  width: 30px;
}

@keyframes effect {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }

}
