@import 'src/style/colors';
@import 'src/style/fonts';

.login {
  display: flex;


  input {
    background-color: #f9f9fb !important;
    border: 1px solid #e8e8e8 !important;
    font-weight: normal !important;
  }

  :global(.flag-dropdown) {
    background-color: #f9f9fb;
    border: 1px solid #ddd;

    .selected-flag {
      background-color: #f9f9fb !important;
    }
  }

  &Left {
    background-color: $color-white;
    min-height: 100vh;
    padding: 2.5rem 90px;
    width: 580px;

    .progressTrackerContainer {
      display: flex;
      justify-content: space-between;
      max-width: 85%;
      width: 500px;

      .progressSingleStep {
        display: flex;
        height: 30px;

        img {
          margin-right: 9px;
        }

        p {
          color: $color-gray-dark-secondary;
          margin: auto 0;
        }

        .googleColor {
          color: $color-pale-green;
        }
      }
    }

    .SignUp {
      img {
        margin-bottom: 2rem;

      }
    }

    &Header {
      margin-bottom: 2rem;
      position: relative;
    }

    h1 {
      font-size: $font-size-30;
      font-weight: 800;
    }

    p {
      color: $color-gray-dark-secondary;
      font-weight: 16px;
      font-weight: 400;
      margin-bottom: 1.5rem;
    }

    .flexBetween {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.5rem;
    }

    .form {
      margin-bottom: 4rem;
      @media (max-width: 600px) {
        margin-bottom: 3rem;
      }
    }
  }

  .errorMessages {
    margin-bottom: 20px;
  }
  
  &Right {
    align-items: center;
    background: linear-gradient(0deg, #17171a, #17171a), $color-gray-dark-medium;
    border-radius: 10px 0 0 10px;
    color: $color-white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-top: 5rem;
    position: relative;
    width: calc(100% - 580px);

    &Slider {
      position: absolute;
      z-index: 3;

      .loginBackground {
        margin: auto;
        position: relative;
        width: min-content;

        .loginGirl {
          bottom: 0;
          position: absolute;
          right: 10%;
        }
      }
    }

    &Text {
      height: 100vh;
      margin: auto;
      max-width: 650px;
      width: 100%;

      h2 {
        color: $color-white;
        font-size: $font-size-20;
        font-weight: 800;
      }

      p {
        color: $color-white-secondary;
        font-size: $font-size-14;
        font-weight: 300;
        line-height: 24px;
      }
    }

    .loginDash {
      height: 100%;
      padding-left: 4rem;
      position: relative;
      width: 100%;

      .backOrn1 {
        left: 65%;
        position: absolute;
        z-index: 1;
      }

      .backOrn2 {
        bottom: 50vh;
        left: 5%;
        position: absolute;
        z-index: 1;
      }

      .backOrn3 {
        bottom: -30px;
        left: 1%;
        position: absolute;
        z-index: 1;

        img {
          width: 90px;
        }
      }

      .backOrn4 {
        bottom: 0;
        position: absolute;
        right: 0;
        z-index: 1;

        img {
          width: 350px;
        }
      }

      .backOrn5 {
        position: absolute;
        right: -50px;
        top: 85px;
        transform: rotate(-30deg);
        z-index: 1;

        img {
          width: 130px;
        }
      }

      .backOrn6 {
        position: absolute;
        right: 75%;
        top: -210px;
        transform: rotate(300deg);
        z-index: 1;

        img {
          width: 260px;
        }
      }
    }

    @media (max-width: 600px) {
      display: none;
    }
  }
}

.loginBtn {
  background-color: $color-purple-medium;
  border-color: $color-purple-medium !important;
  color: $color-white !important;
  font-weight: 400;
  margin-bottom: 1rem !important;
  transition: all 0.26s ease-in-out;
}

.loginLabel {
  color: $color-gray-dark-secondary;
  font-size: $font-size-12 !important;
  font-weight: normal;
  line-height: 20px;
}

.footer {
  small {
    color: $color-gray-light;
    font-size: $font-size-12;
    line-height: 20px;
  }
}

.inputOverrides {
  height: 40px;
  padding-left: 2.3rem !important;
}

.inputIcon {
  align-items: center;
  color: $color-gray-light;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 40px;
}

.emailInput {
  position: relative;

  :global(.ant-form-item) {
    min-width: 100%;
  }

  input {
    width: 100%;
  }

  .emailIcon {
    color: $color-gray-light;
    left: 10px;
    position: absolute;
    top: 10px;
  }
}



@media (max-width:450px) {
  .login {
    &Left {
      padding: 2.5rem 15px;

      .SignUp {
        img {
          margin-bottom: 2rem;
        }
      }

      h1 {
        font-size: $font-size-24;
        font-weight: 800;
      }
    }
  }
}


.ornaments {
  display: flex;

}

.ornament1 {
  background-color: $color-purple-medium;
  border-radius: 8px;
  height: 10px;
  margin-right: 0.5rem;
  width: 45px;

}

.ornament2 {
  background: $color-gray-dark-secondary;
  border-radius: 17px;
  height: 10px;
  margin-right: 0.5rem;
  width: 10px;
}

.logIn {
  color: $color-gray-light !important;
  text-align: center;
}

.sendEmail {
  color: $color-gray-light !important;
  text-align: left;
}

.startCreating {
  button {
    border: 1px solid $color-white-secondary;
    color: #323538;
    margin-bottom: 1.7rem;
  }
}

.selectInput {

  margin-top: 0.1em;
  width: 11.5em;


  :global(.ant-select-selector) {
    align-items: center;
    background: $color-gray-medium;
    border: 1px solid $color-gray-medium;
    border-radius: 4px !important;
    display: flex;
    font-size: $font-size-14;
    height: 40px !important;

    &:focus,
    &:active,
    &:hover {
      border-color: $color-purple-medium;
    }
  }

  :global(.ant-select-arrow) {
    color: $color-light-gray-secondary;
  }
}

.SignUpRegistered {
  margin-bottom: 4.75rem;
  margin-top: 1.3rem;
}

.phoneInput {
  :global(.form-control) {
    height: 41px;
    padding-left: 46px;
    width: 238px;

    &::after {
      background-color: $color-black;
      content: '';
      height: 5px;
      width: 7px;
    }
  }

  :global(.selected-flag) {
    background-color: #f9f9fb;
    border: 0 !important;
    width: 10px;
  }

  :global(.flag-dropdown) {
    background-color: #f9f9fb;
    border-right: 0 !important;
    padding-left: 0.3rem;
  }

  :global(.arrow) {
    color: #17171a;
    display: block;
    margin-left: 0.15rem;
  }
}

.tooltipError {
  background-color: transparent !important;

  :global(.ant-tooltip-content) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), $color-gray-dark-medium;
    border-radius: 8px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.08);
    padding: 2px 6px;
    width: max-content;

    :global(.ant-tooltip-inner) {
      div {
        color: $color-white !important;
        font-size: $font-size-12 !important;
        font-weight: 300;
        white-space: nowrap;

        strong {
          font-weight: 600;
        }

        span {
          color: $color-red !important;
          margin-right: 2px !important;
          width: max-content;
        }
      }
    }}
}

.languagesSwitcher {
  align-items: center;
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin-bottom: 5px;
  margin-top: 5px;

  li {
    color: $color-blue;
    font-size: $font-size-12;

    margin-right: 10px;
    padding: 0 4px;

    :hover {
      text-decoration: underline;
    }
  }
}
