@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/components/common-components/scss/mixins.scroll';

.tableOuterPages {
  background-color: $color-white;
  padding-bottom: 0 !important;
 
  :global(.ant-table-body) {
    overflow: auto scroll;
    // overflow-y: hidden !important;
  }

  table tr th {
    background-color: #fff;
  }

  .ant-table-content {
    border-radius: 0 !important;
  }

  :global(.ant-table-column-sorters-with-tooltip):not(:first-child) {
    text-align: center;
  }

  table {
    :global(button) {
      background-color: #f7f7f8;
      border: 0;
      border-radius: 4px;
      box-sizing: border-box;
      color: $color-gray-dark-secondary;
      cursor: pointer;
      font-size: $font-size-14;
      font-weight: 400 !important;
      outline: none !important;
      padding: 4px 15px;
    }

    th::before {
      width: 0 !important;
    }

    :global(.ant-table-column-sorter) {
      display: flex;
      padding-left: 3px !important;
    }

    :global(.ant-table-column-sorters) {
      display: inline-flex !important;
      padding: 5px !important;
    }

    tr td {
      /*   padding-right: 20px !important; */

      &:first-child {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
    }

    :global(.ant-table-tbody) {
      tr:nth-child(even) {
        td {
          background: $color-white;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:hover {
          td {
            background: #eef4fe !important;
          }

          button {
            background: #eef4fe !important;
            border: 0 !important;
            color: #2d6cca;
            font-weight: 500;
          }
        }

        &.purpleRow {
          &:hover {
            td {
              background-color: #ece5f3 !important;
            }

            :global(button) {
              background-color: transparent !important;
            }
          }
        } 
      }

      tr:nth-child(odd) {
        td {
          background: $color-white;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:hover {
          td {
            background: #eef4fe !important;
          }

          button {
            background: #eef4fe !important;
            border: 0 !important;
            color: #2d6cca;
            font-weight: 500;
          }
        }

        &.purpleRow {
          &:hover {
            td {
              background-color: #ece5f3 !important;
            }

            :global(button) {
              background-color: transparent !important;
            }
          }
        } 
      }
    }

    :global(.ant-table-thead) {
      background: #f7f7f7 !important;
      border-radius: 8px;

      :global(.ant-table-column-has-sorters) {
        text-align: right;
  
        :global(.ant-table-column-title) {
          text-align: center;
          z-index: 0 !important;
        }
      }

      th {
        background: #f7f7f7 !important;
        color: #121212 !important;
        font-size: $font-size-12;
        font-weight: 600;
        line-height: 14px;
        padding: 0 !important;
      }

      :global(.ant-table-cell-ellipsis) {
        &:first-child {
          padding-left: 20px !important;
        }
      }


      :global(.ant-table-cell-fix-left) {
        text-align: left !important;

        &:first-child {
          border-bottom-left-radius: 8px !important;
          border-top-left-radius: 8px !important;
        }
      }

      th:first-child {
        background: #f7f7f7 !important;
        border-bottom-left-radius: 8px !important;
        border-top-left-radius: 8px !important;
        color: #121212;
        font-size: $font-size-12;
        font-weight: 600;
      }

      th:nth-child(2) {
        background: #f7f7f7 !important;
        color: #121212;
        font-size: $font-size-12;
        font-weight: 600;
      }

      th:nth-child(3) {
        background: #f7f7f7 !important;
        color: #121212;
        font-size: $font-size-12;
        font-weight: 600;
        text-align: center;
      }

      th:last-child {
        background-color: #f7f7f7 !important;
        border-bottom-right-radius: 8px !important;
        border-top-right-radius: 8px !important;
        padding-left: 25px !important;
      }
    }

    :global(.ant-table-cell) {
      white-space: pre-wrap;
    }

    :global(.ant-table-cell-fix-left-last) {
      text-align: left !important;
      // background: $color-white !important;
    }

    tr:first-child td {
      background: $color-white !important;
    }

    tr:nth-child(even) td {
      background: $color-white !important;
    }

    tr:nth-child(odd) td {
      background-color: $color-white !important;
    }

    tr th {
      height: 60px !important;
      line-height: 22px;
      white-space: nowrap;
    }

    :global(.ant-column-sorters) {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    tr td {
      padding-bottom: 15px;
      padding-top: 15px;
      text-align: center;
    }

    tr th:first-child,
    tr td:first-child {
      padding-left: 15px !important;
      padding-right: 25px !important;
    }

    tr th:last-child,
    tr td:last-child {
      padding-left: 25px !important;
      padding-right: 25px !important;
      text-align: center;
    }
  }

  .rowBackground {
    // filter: saturate(6);

    :global(.ant-table-cell) {
      background: #eef4fe !important;
    }

    button {
      background: #eef4fe !important;
      border: 0 !important;
      color: #2d6cca;
      font-weight: 500;
    }
  }
}

.actionCount {
  align-items: center;
  display: flex;

  .dropdownStyles {
    font-size: 18px;
    margin-left: -15px;
  }
}

.removeStyle {
  padding: 5px;

  &:hover {
    background-color: $color-light-gray;
  }

}

.modalBulkStyle {
  :global(.ant-modal-content) {
    border-radius: 8px !important;
  }

  :global(.ant-modal-close) {
    color: #fff !important;
    left: -50px !important;
  }

  :global(.ant-btn-default) {
    border: 0 !important;
  }

  :global(.ant-btn) {
    margin: 10px 0 20px !important;
  }

  :global(.ant-btn-primary) {
    background: #7f4ead !important;
    border: 1px solid #7f4ead;
    border-radius: 6px !important;
    height: 44px;
    margin-left: 20px !important;
    width: 131px;

    &:hover {
      background: #5d1f96 !important;
    }

    &:disabled {
      background: #7f4ead88 !important;
      border: 0 !important;
      color: #fff !important;
    }

    > span {
      font-weight: 600 !important;
    }
  }
}
