@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/components/common-components/scss/mixins.scroll';


.sliderStyle {
  :global(.ant-slider-handle) {
    background-color: #2d6cca !important;
    border-color: #2d6cca !important;
    height: 16px !important;
    width: 16px !important;
  }
}

.selectStyle {
  :global(.ant-select-selector) {
    border-radius: 5px !important;
  }
}

.modalIconStyle {
  :global(.rc-dialog-close-x) {
    &::before {
      background: transparent !important;
      color: #fff !important;
    }

    &::after {
      background: transparent !important;
      color: #fff !important;
      content: '×';
      font-size: $font-size-46 !important;
      font-size: 30px !important;
      height: 10px;
      margin-top: -20;
      position: absolute;
      right: -3rem;
      top: 0;
      transform: matrix(1, 0, 0, -1, 0, 0);
      width: 10px;
    }
  }
}

.overlay {
  :global(.ant-popover-arrow) {
    display: none !important;
  }
}
