.popoverOverlay {
  :global(.ant-popover-content) {
    :global(.ant-popover-inner) {
      border-radius: 20px !important;
    }
  }
}

.tableHeaderStyle {
  :global(.ant-table-thead) {
    background: #f7f7f8 !important;
    border-radius: 8px;

    th:first-child {
      background: #f7f7f8 !important;
      border-bottom-left-radius: 6px !important;
      border-top-left-radius: 6px !important;
    }

    th:last-child {
      background: #f7f7f8 !important;
      border-bottom-right-radius: 6px !important;
      border-top-right-radius: 6px !important;
    }
  }
  
  :global(.ant-spin-nested-loading .ant-spin-container .ant-table-container .ant-table-content .ant-table-tbody .ant-table-row .ant-table-cell) {
    vertical-align: top !important;
  }
}
