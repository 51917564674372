@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/components/common-components/scss/mixins.scroll';

.ModalHeader {
  color: $color-white;
  text-align: center;

  p {
    color: #54565a;
    font-size: $font-size-14;
  }

  h3 {
    color: $color-purple-medium;
  }
}

.PendingInvoice,
.SummaryBox,
.TotalDue,
.BtnRow {
  display: flex;
  justify-content: space-between;
}



.PendingInvoice {
  background-color: $color-white;
  border-radius: 10px;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  padding: 20px;

  .PendingUserInfo {
    h3 {
      font-size: $font-size-24;
    }

    .DueDateTitle {
      color: $color-secondary-text;
      margin-bottom: 0;
    }
  }

  .PendingAmount {
    text-align: right;

    h3 {
      color: $color-purple-medium;
      font-size: $font-size-24;
      font-style: normal;
      font-weight: 800;
      line-height: 44px;
    }

    .CheckoutBtn {
      background: $color-purple-medium;
      color: $color-white;
      font-size: $font-size-14;
      max-width: 125px;
    }
  }
}

.SummaryBox {
  margin-top: 18px;

  .SummaryLeft {
    p {
      margin-bottom: 0;
    }
  }

  .SummaryRight {
    display: flex;

    p {
      font-size: $font-size-16;
      margin-bottom: auto;
      margin-top: auto;
    }
  }
}

.RowTitle {
  color: $color-secondary-text;
  font-size: $font-size-12;
  font-weight: 600;
  line-height: 15px;
  text-transform: uppercase;
}

.ModalFooter {
  font-family: Inter;
  font-size: $font-size-14;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}

.SummaryContainer {
  @include scrollbars(5px, rgba(138,138,138,0.32), rgba(138,138,138,0.12));
  max-height: calc(90vh - 240px);
  overflow-y: auto;
}

.blueButton {
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #4ca2f2;
  }
}
