@import 'src/style/colors';
@import 'src/style/fonts';

.billingDetail {
  margin-top: 20px;

  .billingDetailLabel {
    color: $color-gray-dark-secondary;
    font-size: $font-size-12;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
  }

  .billingName {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 600px) {
      flex-wrap: wrap;

      :global(.ant-form-item) {
        margin-bottom: 10px !important;
      }
    }

    label {
      color: $color-gray-dark-secondary;
      font-size: $font-size-12;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
    }

    input {
      background: $color-white-primary;

    }

    div {
      display: grid;
      width: 242px;
      @media screen and (max-width: 600px) {
        width: 100%;
      }

    }
  }

  .billingAddress {
    display: flex;
    justify-content: space-between;

    div {
      display: grid;
      width: 100%;
    }

    textarea {
      background: $color-white-primary;
    }

    label {
      color: $color-gray-dark-secondary;
      font-size: $font-size-12;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
    }
  }
}

.selectAarea {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .zipcodeArea {
    display: grid;
    margin-bottom: 1.6rem;

    input {
      background: $color-white-primary;
    }

    div {
      width: 157px;
    }
  }

  .selectContainer {
    display: grid;
    margin-bottom: 1.6rem;
    margin-right: 10px;
    width: 100%;
    @media screen and (max-width: 600px) {
      margin-bottom: 10px;
      margin-right: 0;
    }

    div {
      width: 157px;

      :global(.ant-select-selector) {
        align-items: center;
        background-color: $color-body-background !important;
        border-radius: 5px !important;
        display: flex;
        height: 38px !important;
      }

      :global(.ant-form-item-label) {
        color: $color-gray-dark-secondary;
        font-size: $font-size-12;
        font-weight: 500;
        line-height: 20px;
        text-align: left;

      }

      :global(.ant-select-arrow) {
        color: $color-light-gray-secondary;
      }
    }
  }
}


