.heading {
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  line-height: 41.22px;
  margin-bottom: 0;
  margin-top: 10px;
  text-align: center;
}

.subHeading {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 22.9px;
  margin-top: 9px;
  text-align: center;
}

.videoSection {
  border-radius: 8px;
  display: flex;
  flex-grow: 1;
  gap: 24px;
  width: 100%;
}

