@import 'src/style/colors';
@import 'src/style/fonts';

.mainDiv {
  margin: auto 40px 40px !important;
  @media screen and (max-width: 500px) {
    margin: auto 15px 15px !important;
  }

  .lightTheme {
    border-top: 2px solid $color-light-gray-third;

    .text {
      color: $color-gray-dark-secondary;
    }

    .selectInput {
      color: $color-gray-dark-secondary;

      :global(.ant-select-selector) {
        background-color: transparent;
      }

      :global(.ant-select-arrow) {
        color: $color-gray-dark-secondary;
      }
    }
  }  

  .darkTheme {
    border-top: 2px solid $color-gray-dark-secondary;

    .text {
      color: $color-light-gray-third;
    }

    .selectInput {
      color: $color-light-gray-third;

      :global(.ant-select-selector) {
        background-color: transparent;
      }

      :global(.ant-select-arrow) {
        color: $color-light-gray-third;
      }
    }
  }

  .container {
    padding: 25px 0;
    @media screen and (max-width: 500px) {
      padding: 12px 0 0;
    }

    .poweredContainer {
      align-items: center;
      display: flex;
      flex-direction: row;
      float: left;
      justify-content: center;
      margin-top: 7px;

      img {
        height: 16px;
        margin-right: 5px;
        width: 16px;
      }

    }

    .languagesSwitcher {
      float: right;
      @media screen and (max-width: 500px) {
        float: none !important;
      }

      .selectInput {
        margin-left: 0.66em;
        width: 150px;
        
        :global(.ant-select-item-option-content) {
          span {
            font-size: 20px;
            margin-right: 5px;
          }
        }

        .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
          border-right-width: 0 !important;
        }
      
        svg {
          height: 16px;
        }
      
        :global(.ant-select-show-arrow) {
          &:focus,
          &:active,
          &:hover {
            box-shadow: none;
            outline: none;
          }
        }
      
        :global(.ant-select-focused) {
          &:focus,
          &:active,
          &:hover {
            box-shadow: none;
            outline: none;
          }
        }
      
        :global(.ant-select-selector) {
          align-items: center;
          border: transparent;
          display: flex;
          height: 38px;
      
          &:focus,
          &:active,
          &:hover {
            box-shadow: none;
            outline: none;
          }
        }
      
        :global(.ant-select-arrow) {
          top: 46%;
        }
      
        :global(.ant-select-selection-item) {
          align-items: center;
          display: flex;

          span {
            font-size: 20px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 440px) {
  .mainDiv {
    margin: 20px 20px 80px;

    .selectInput {
      margin-left: 0 !important;
    }
  }
}

@media screen and (max-width: 500px) {
  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 375px) {
  .mainDiv {
    margin: 40px auto 20px;

    .container {
      display: flex;
      flex-direction: column;

      .poweredContainer {
        order: 2;
      }

      .languagesSwitcher {
        margin: auto;
        order: 1;
      }

    }
  }
}
