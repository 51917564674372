@import 'src/components/common-components/scss/mixins.scroll';
@import 'src/style/colors';

.highlighted--green,
.highlighted--yellow,
.highlighted--red,
.highlighted--blue,
.highlighted--gray {
  border-radius: 3px;
  padding: 0;
}

.highlighted--red {
  background-color: rgba(255, 0, 66, 0.3) !important;
}

.highlighted--gray {
  background-color: rgba(247, 237, 237, 1) !important;
  // background-color: rgba(131, 131, 131, 0.3) !important;
}

.highlighted--yellow {
  background-color: #eff7d6;
}

.highlighted--green {
  background-color: rgba(51, 189, 109, 0.3) !important;
}

.highlighted--blue {
  background-color: rgba(185, 140, 228, 0.2) !important;
}

.ant-menu .ant-menu-item-selected {
  background-color: transparent;
  // padding: 0;

  a {
    background-color: rgba(#b98ce4, 0.2) !important;
    border-radius: 4px;
  }
}

.ant-layout-sider-children {
  @include scrollbars(5px, rgba(138,138,138,0.32), rgba(138,138,138,0.12));
  overflow: overlay;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: rgba(127, 78, 173, 0.1) !important;
  
}

.ant-menu-submenu-arrow {
  right: 8px;
}

.ant-menu-vertical .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 8px !important;
}

.ant-menu-submenu {
  padding-bottom: 10px !important;
}

.ant-menu-sub.ant-menu-inline {
  padding: 10px 0;
}
