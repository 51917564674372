@import 'src/style/colors';
@import 'src/style/fonts';

$item-hover-color: $color-white;
$item-transition-duration: 0.3s;
$icon-size: 16px;
$icon-margin-right: auto;

.icon {
  align-items: center;
  display: flex;
  font-size: 16px;
  height: fit-content;
  margin-right: 6px;

  span {
    color: $color-gray-light;
  }

  svg {
    * {
      transition: fill $item-transition-duration,
        stroke $item-transition-duration;
    }
  }
}

.menu {
  flex-grow: 1;
  font-weight: 500;
  overflow: hidden;
  position: relative;

  * {
    font-family: 'Inter', sans-serif;
  }

  &:global(.ant-menu) {
    background: transparent;
    border-bottom: 0;
    border-right: 0 !important;
    height: 100%;
    // margin-left: 40px;
    overflow-y: hidden;
    // padding: 0 10px 0 0;
    width: fill-available;

    :global(.ant-menu-item) {
      align-items: flex-end;
      display: flex;
      height: 100%;
      padding: 0 15px !important;

      :global(.ant-menu-title-content) {
        align-items: center;
        display: flex;
        height: 100%;
      }

      &::after {
        left: 9px !important;
      }
    }
  }

  :global(.ant-menu-item) {
    border-radius: 4px;
    color: $color-gray-light;
    font-size: 13px;
    font-weight: 400;
    margin: 0 !important;
    width: fit-content;
  }

  &.singleItemMenu { 
    box-shadow: rgb(78, 81, 86) -11px 0 0 -10px !important;
    margin-left: 10px !important;
    max-width: 140px !important;
    min-width: 140px !important;
  }

}

.rootMenuStyle {
  :global(.ant-menu-sub) {
    :global(.ant-menu-item) {
      &:hover {
        background: transparent !important;
      }

      &:active {
        background: transparent !important;
      }
    }

    :global(.ant-menu-submenu-title) {
      &:active {
        background: transparent !important;
      }
    }

    :global(.ant-menu-item-selected) {
      background: transparent !important;

      &:hover {
        background: transparent !important;
      }
    }
  }

  :global(.ant-menu) {
    background-color: #24262a !important;
    color: #a3a4a4 !important;
  }

  :global(.ant-menu-overflow-item-rest) {
    padding: 3px 0 0 10px !important;

    >div>span>svg {
      display: none;
    }

    >div {
      align-items: center;
      display: flex;
      flex-direction: row-reverse;

      &::after {
        color: #e8e8e8;
        content: 'More';
        font-size: 14px;
        font-weight: 500;
      }

      &:hover {
        &::after {
          color: #fff;
        }

        >span::after {
          border-top-color: #fff;
        }
      }
    }

    >div>span {
      display: flex;

      &::after {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #a3a4a4;
        content: '';
        height: 0;
        margin-left: 6px;
        margin-top: 2px;
        width: 0;
      }
    }
  }

  :global(.ant-menu-item-active) {
    background: #282b2f !important;
  }

  :global(.ant-menu-item-selected) {
    background: #282b2f !important;
    
  }

  :global(.ant-menu-overflow-item-rest) {
    &::after {
      display: none;
    }
  }
}

.disabled {
  cursor: not-allowed;
  filter: grayscale(1);
}

.popupCustomStlye {
  position: absolute;
  z-index: 999;

  :global(.ant-tooltip-inner) {
    align-items: center;
    background: #313337;
    border-radius: 8px;
    display: flex;
    height: 236px;
    width: 310px !important;
  }

  :global(.ant-tooltip-arrow-content) {
    //background: #313337 !important;
    --antd-arrow-background-color: #313337;
  }
}

