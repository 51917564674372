@import '@/style/colors';

.commonHeader {
  background: $color-black-medium;
  // margin: 0 -40px;
  // min-height: 303px;
  padding: 0 40px 120px;
  padding-bottom: 85px;
}

.pageHeaderContent {
  margin-top: -140px;
}
