@import 'src/style/colors';
@import 'src/style/fonts';

.banner {
  padding: 25px;

  &.error {
    background: linear-gradient(127.2deg, rgba(244, 67, 67, 0.2) -7.95%, rgba(244, 67, 67, 0) 33.67%), $color-gray-medium;
  }

  &.warning {
    background: linear-gradient(127.2deg, rgba(241, 170, 62, 0.2) -7.95%, rgba(241, 170, 62, 0) 33.67%), linear-gradient(0deg, $color-gray-medium, $color-gray-medium);
  }

  button {
    color: $color-white;
  }

  .title,
  .subtitle {
    color: $color-white;
  }

  .title {
    font-size: $font-size-24;
    font-weight: 800;
  }

  .subtitle {
    color: $color-white;
    font-size: $font-size-14;
  }
}
