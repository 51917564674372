.tag {
  display: inline-block;
  white-space: nowrap;

  .container {
    align-items: center;
    border: 1px solid #e4e4e4;
    border-radius: 20px;
    display: flex;
    padding: 0 10px;

    .dot {
      border-radius: 50%;
      display: block;
      height: 7px;
      margin-right: 5px;
      position: relative;
      width: 7px;
    }

    // .label {
    //   padding-left: 6px;
    // }
  }

}
