@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/components/common-components/scss/mixins.scroll';

.ContentToolsDrawer {
  .popoverDivider {
    border-top: 1px solid $color-light-gray-third;
    height: 1px;
  }


  :global(.ant-drawer-close) {
    color: $color-black;
    font-size: 20px;
    opacity: 0.5;
    position: absolute;
    right: 10px;
    top: 15px;

    &:hover {
      opacity: 1;
    }
  }

  :global(.ant-drawer-header) {
    background-color: rgb(242, 242, 245) !important;
    padding: 0 !important;
  }

  .ContentToolsDrawer {
    padding: 0 30px;

    .contentIdeasTitle {
      color: $color-black-secondary;
      font-size: $font-size-24;
      font-weight: 600;
    }

    .descriptionText {
      color: $color-black;
      font-size: $font-size-14;
      margin-top: 10px;
    }

    
    .input {
      background-color: $color-white !important;
      border: 1px solid $color-light-gray-third !important;
    }

  




    .countrySelector {
      .selectInput {
        margin-right: 15px;
        width: 195px;

        :global(.ant-select-selector) {
          border-radius: 5px;
          height: 38px !important;
        }

        :global(.ant-select-selection-item) {
          padding-top: 2px !important;
        }

        :global(.ant-select-selection-search) {
          padding-top: 0 !important;
        }
      }
    }

    .generateIdeasButton {
      height: 38px !important;
    }

    .generatedItemsNumb {
      color: $color-black;
      cursor: default;
      font-size: $font-size-16;
      font-weight: 500;
    }


  }
}

.ContentIdeaContainer {
  border-radius: 6px;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.02);
  height: auto;
  margin-bottom: 30px;
  padding: 25px 40px;
  position: relative;
  width: 100%;

  .ideaOrder {
    background: $color-white;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    left: 0;
    padding: 2px 8px;
    position: absolute;
    top: 26px;
  }

  .ContentIdea {
    color: $color-black;

    white-space: pre-line;
   

    span {
      display: block;
      font-weight: bold;
    }
  }

  .controlButtons {
    align-items: center;
    display: flex;

    .downloadButton {
      color: $color-gray-dark;
      cursor: pointer;
      margin: auto;
      margin-right: 10px;
      width: 14px;
    }

    .copyButton {
      color: $color-black;
      cursor: pointer;
      margin-left: 17px;
    }
  }
}

.controlButtons {
  align-items: center;
  display: flex;

  .downloadButton {
    color: $color-gray-dark;
    cursor: pointer;
    margin: auto;
    margin-right: 10px;
    width: 14px;
  }

  .copyButton {
    color: $color-black;
    cursor: pointer;
    margin-left: 17px;
  }
}

.refreshAiOutlineBtn {
  background: rgba(45, 108, 202, 0.1);
  border: 1px solid rgba(45, 108, 202, 0.5);
  color: #2d6cca;
  height: 22px;
  margin-left: 10px;
  padding: 1px 5px 3px !important;

  &:hover,
  &:active,
  &:focus {
    background: rgba(45, 108, 202, 0.1);
  }

  span {
    display: block;
    font-size: $font-size-12;
  }
}

.generateIdeasButton {
  height: 38px !important;
}

.numberInput {
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.02);
  height: 38px;
  min-width: 55px;
  padding-left: 10px;

  &:focus-visible {
    outline: none !important;
  }
}

