@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/components/common-components/scss/mixins.scroll';

.logo {
  align-items: center !important;
  cursor: pointer;
  display: flex !important;
  height: fit-content;
  height: 60px;
  margin-left: 18px;
  transition: width 0.2s;

  .imgStyle {
    margin-bottom: 8px;
    margin-right: 5px;
    max-height: 27px;
    max-width: 130px;
  }
}

.globalIcon {
  color: #fff;
  height: 14px;
  margin-right: 10px;
  margin-top: 5px;
  width: 14px;
}

.tag {
  background-color: transparent !important;
  border: 0 !important;
  color: #a3a4a4 !important;
  font-size: $font-size-12 !important;
  margin-bottom: auto !important;
  margin-right: 0 !important;
  margin-top: auto !important;
  padding-left: 0 !important;
}

.disableTag {
  background-color: transparent !important;
  border: 0 !important;
  color: $color-gray-light !important;
  font-size: $font-size-12 !important;
  margin-bottom: auto !important;
  margin-right: 0 !important;
  margin-top: auto !important;
  padding-left: 0 !important;
}

.disableImg {
  filter: grayscale(70%);
}

.backlinkDiv {
  align-items: center;
  display: flex;
  justify-content: space-between;

  >div {
    align-items: center;
    display: flex;
    flex: 1;

    >img {
      margin-right: 0.8rem;
    }
  }
}

.tagIn {
  background-color: transparent !important;
  border: 0 !important;
  color: $color-secondary-text !important;
  font-size: $font-size-14 !important;
  margin-bottom: auto !important;
  margin-right: 0 !important;
  margin-top: auto !important;
  padding-left: 0 !important;
}

.dot {
  background: $color-light-gray-third !important;
  border-radius: 50%;
  display: inline-block;
  height: 5px;
  margin-bottom: 2px;
  margin-right: 5px;
  width: 5px;
}

.activeButton {
  background: rgba(45, 108, 202, 0.15) !important;
  border: 1px solid #2d6caa !important;
  border-radius: 4px;
  font-size: $font-size-14 !important;
  margin-left: 15px;
  white-space: nowrap;
  width: 80px !important;
}

.deactiveButton {
  background: rgba(45, 108, 202, 0.15) !important;
  border: 1px solid #2d6caa !important;
  border-radius: 4px;
  font-size: $font-size-14 !important;
  height: 32px;
  line-height: 17px;
  margin-left: 15px;
  margin-right: 10px;
  white-space: nowrap;
  width: 92px !important;
}

.TagContainer {
  display: flex !important;

  .projectBox {
    display: flex;

    .topPropertyInfoContainer {
      display: flex;

      .labelDot {
        color: $color-gray-light;
      }

      .gscLabel {
        color: $color-gray-light;
        font-size: 12px;
      }

      .gaLabel {
        color: $color-gray-light;
        font-size: 12px;
        margin-left: 5px;
      }

      .gaDisconnected {
        color: $color-link-secondary;
        font-size: 12px;
        margin-left: 5px;
      }
    }

    .url {
      color: $color-white;
    }

    .disableUrl {
      color: $color-gray-light;
    }


  }

  img {
    border-radius: 5px;
    height: 30px;
    margin: auto 10px auto 0;
    transform: translate(0, 5px);
    width: 30px;
  }

}

.headerDisabled {
  background: #1b1c1f;
  filter: brightness(69%);
  opacity: 0.98;
  pointer-events: none;
}

.announceKit {
  align-items: center;
  display: flex;
  margin-right: 14px;
  margin-top: 4px;
  // position: absolute;
  // right: 102px;
  // top: 4px;
  width: 175px;

  @media (max-height: 800px) {
    margin-top: 2px;
  }
}

.header {
  align-items: center;
  background: #282b2f;
  border-radius: 8px;
  cursor: default;
  display: flex;
  height: 50px;
  // justify-content: space-between;
  transition: all 0.3s ease;
  width: 100%;
  z-index: 3;

  :global(.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder) {
    padding-right: 0.1px;

    @media (max-width: 1013px) {
      display: none;
    }

    @media (max-width: 991px) {
      display: block;
    }

    @media (max-width: 799px) {
      display: none;
    }
  }

  :global(.ant-select-single:not(.ant-select-customize-input) .ant-select-selector) {
    padding: 0 6px;
  }

  @media screen and (min-width: 991px) {
    &.checkoutLogo {
      padding-left: 0 !important;

      img {
        cursor: pointer;
        padding-left: 145px;
      }
    }
  }

  &CaretDown {
    transform: translate(-13px, 11px);
  }

  &CaretDownCountry {
    transform: translateX(-15px);

    >span>svg {
      width: 10px;
    }
  }

  &__button-transparent {
    background: transparent !important;
    margin-right: 10px;

    &:hover {
      background: $color-light-gray-secondary !important;
    }
  }

  &__search-input-wrapper {
    flex-grow: 1;
    padding-left: 36px;
  }

  &__location-select {
    border-radius: 5px;
    color: $color-white;
    margin-left: 15px;
    padding: 7px 12px;

    svg {
      color: $color-icons;
      margin-left: 5px;
    }
  }

  &__search-input {
    max-width: 1000px;

    :global(.ant-input-prefix),
    :global(.ant-input) {
      color: $color-white;
    }

    :global(.ant-input-prefix) {
      align-items: center;
      display: flex;
      margin-right: 8px;
    }

    :global(.ant-input-affix-wrapper) {
      background: rgba(255, 255, 255, 0.53);
      border: 0;
      border-radius: 8px;
      height: 36px;
    }

    :global(.ant-input) {
      background: transparent;
      border: 0;
    }
  }

  &__user-menu {
    padding-left: 20px;
    padding-right: 40px;

    div {
      height: 38px;

      :global(.ant-avatar) {
        background-color: $color-gray-dark-secondary !important;
        color: $color-gray-light;
        font-size: $font-size-12;
        font-weight: 700;
        height: 100%;
        width: 38px;

        @media (max-height: 800px) {
          height: 32px;
          width: 32px;
        }

        span {
          align-items: center;
          display: flex;
          height: 100%;
        }
      }
    }
  }

  &__buttons {
    align-items: center;
    display: flex;
    position: relative;

    button {
      padding: auto 20px !important;
    }
  }

  &__buttons-btn {
    margin-right: 10px;
  }

  &__buttons_place-an-order {
    background: transparent;
    border: 1px solid $color-light-gray-secondary;
    color: $color-non-primary-text;
  }

  &__companySelectionHeading {
    color: #a3a4a4;
    font-weight: 500;
    margin-bottom: 0;
    margin-right: 3px;
    white-space: nowrap;


    // &:hover {
    //   border: 1px solid #4e5156 !important;
    //   border-radius: 5px;
    //   padding: 5px 12px;
    // }

    span {
      @media (max-width: 1270px) {
        // display: none;
      }
    }
  }

  &__backlinkSelectionHeading {
    color: #a3a4a4;
    font-weight: 500;
    margin-bottom: 0;
    margin-right: 3px;
    padding-left: 1rem;
    white-space: nowrap;

    span {
      @media (max-width: 1270px) {
        display: none;
      }
    }
  }

  &__selectorWrapper {
    display: inline-block;
    padding-left: 0.25rem;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    &-companyName {
      color: $color-non-primary-text;
      font-weight: 500;
    }

    &-companySelectionIcon {
      left: 3px;
      position: relative;
      top: 5px;

      :global(svg) {
        height: 19px;
        margin-right: -14px;
        stroke-width: 3px;
        top: 8px;
        width: 19px;
      }
    }
  }

  &__siteAuditor-menu {
    display: flex;
    height: 38px !important;
    justify-content: space-between;
    width: 64%;

    @media(max-width: 1470px) {
      width: 100%;
    }

    @media(max-width: 768px) {
      margin-top: 6px;
    }

    @media(max-width: 576px) {
      >div:nth-child(2) {
        flex: none;
      }
    }

    >div:nth-child(2) {
      flex: auto;
    }

  }

  &__gsc-menu {
    display: flex;
    height: 38px !important;
    justify-content: space-between;
    width: 64%;

    @media(max-width: 1470px) {
      width: 80%;
    }

    &__custom-gsc-menu {
      width: auto !important;
    }
  }

  &__sca-menu {
    align-items: center;
    display: flex;
    // height: 20px !important;
    justify-content: space-between;
    // Here we copy paddings of .contentOptimizerHeader + transition + width of the back arrow
    // Goes the same for media queries
    padding-left: 58px;
    transition: all 0.5s ease-in;

    @media only screen and (max-width: 1210px) {
      padding-left: 28px;
    }

    &:nth-child(2) {
      padding-left: 15px;
    }
  }

  &__sca-menu-item {
    border-left: 1px solid $color-gray-light;
    color: $color-gray-light;
    cursor: pointer;
    padding: 0 25px;
    transition: all 0.3s ease;

    &:hover {
      color: $color-white;
    }

    // &:first-child {
    //   border-left: 0;
    //   padding-left: 0;
    // }
  }

  &__project-menu {
    align-items: center;
    border: 1px solid transparent !important;
    border-radius: 5px;
    color: $color-non-primary-text;
    display: flex;
    height: 38px;
    margin-left: 0 !important;
    max-width: 380px;

    transition: background-color 0.6s;
    transition: all 0.3s ease-out;

    @media (max-width: 1013px) {
      margin-right: -28px !important;
    }

    @media (max-width: 991px) {
      margin-right: -4px !important;
    }

    @media (max-width: 799px) {
      margin-right: -28px !important;
    }

    &.scaProjectMenu {
      @media (max-width: 1013px) {
        margin-right: 0 !important;
      }

      @media (max-width: 991px) {
        margin-right: 0 !important;
      }

      @media (max-width: 799px) {
        margin-right: 0 !important;
      }

      :global(.ant-select-selector) {
        margin-right: 0 !important;
        padding-left: 0 !important;
        padding-right: 20px !important;
      }


      .headerCaretDown {
        margin-left: -10px;
        margin-top: 5px;
        transform: translate(0, 0);
      }

      :global(.ant-select-selection-placeholder) {
        display: block !important;
      }
    }
  }

  &__projectbacklink-menu {
    align-items: center;
    border: 1px solid transparent !important;
    border-radius: 5px;
    color: $color-non-primary-text;
    display: flex;
    height: 38px;
    margin-left: 0.75rem !important;
    max-width: 380px;
    min-width: 185px;

    transition: background-color 0.6s;
    transition: all 0.3s ease-out;

    @media (max-width: 1013px) {
      margin-right: -28px !important;
    }

    @media (max-width: 991px) {
      margin-right: -4px !important;
    }

    @media (max-width: 799px) {
      margin-right: -28px !important;
    }

    @media (max-width: 576px) {
      margin-left: 2rem !important;
    }
  }

  &__project-menu-open {
    // background: rgba(84, 86, 90, 0.1);
    // border: 1px solid $color-gray-dark-secondary !important;
    // border-radius: 5px;
    z-index: 1;

    :global(.ant-select-selection-item) {
      max-width: 180px;
    }
  }

  &__project-menu-close {
    // padding-left: 46px;
    // padding-top: 22px;
    z-index: 1;

    :global(.ant-select-selection-item) {
      max-width: 160px;
    }

    :global(.ant-select-selector) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  &__projectbacklink-menu-open {
    background: rgba(84, 86, 90, 0.1);
    border: 1px solid $color-gray-dark-secondary !important;
    border-radius: 5px;
    z-index: 1;
  }

  &__projectbacklink-menu-close {
    padding-left: 46px;
    padding-top: 22px;
    z-index: 1;

    &:hover {
      background: rgba(84, 86, 90, 0.1);
      border: 1px solid $color-gray-dark-secondary !important;
      border-radius: 5px;
    }

    :global(.ant-select-selector) {
      margin-right: 20px !important;
      padding-left: 100px !important;
      padding-right: 13px !important;
      width: auto !important;

      :global(.ant-select-selection-item) {
        padding-right: 0 !important;

      }
    }
  }

  &__countries-menu {
    align-items: center;
    border: 1px solid transparent !important;
    border-radius: 5px;
    color: $color-non-primary-text;
    display: flex;
    height: 38px;
    margin-left: 5px !important;

    transition: background-color 0.6s;
    transition: all 0.3s ease-out;

    :global(.ant-select:hover) {
      background: rgba(84, 86, 90, 0.1);
      border: 1px solid $color-gray-dark-secondary !important;
      border-radius: 5px;
    }

    :global(.ant-select) {
      border: 1px solid transparent !important;
      width: 100%;
    }
  }

  &__countries-menu-open {
    // background: rgba(84, 86, 90, 0.1);
    // border: 1px solid $color-gray-dark-secondary !important;
    border-radius: 5px;
    width: 100%;
    z-index: 1;
  }

  &__countries-menu-close {
    width: 100%;
    z-index: 1;

    &:hover {
      // background: rgba(84, 86, 90, 0.1);
      // border: 1px solid $color-gray-dark-secondary !important;
      border-radius: 5px;
    }

    :global(.ant-select-selector) {
      margin-right: 7px !important;
      min-width: 174px !important;
      padding-left: 90px !important;
      padding-right: 13px !important;
      width: auto !important;

      @media (max-width: 1470px) {
        padding-left: 0 !important;
      }

      // @media (max-width: 1270px) {
      //   margin-right: -8px !important;
      //   min-width: auto !important;
      // }

      :global(.ant-select-selection-item) {
        display: none !important;
        padding-right: 0 !important;
      }
    }
  }
}

.orderHeader {
  @media (max-width:576px) {
    flex-direction: row !important;
  }
}

.checkoutLogo {
  @media screen and (max-width: 991px) {
    padding-left: 50px !important;
  }
}

.IconsHeader {
  align-items: center;
  color: #a3a4a4;
  display: flex;
  font-size: $font-size-20;
  padding-left: 20px;

  >span {
    margin-left: 5px;
    text-align: center;
    width: 34px;
  }


}

.infoPopover {
  :global(.ant-popover-content) {
    border-radius: 10px;
  }

  :global(.ant-popover-inner-content) {
    background-color: #c95b14;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 #592809;

  }

  :global(.ant-popover-arrow) {
    display: none !important;
  }

}

.pickerHeader {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-color: 0 !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  cursor: pointer;

  padding: 0 !important;
  // @media (max-width: 1207px) {
  //   display: none !important;
  // }

  &:hover {
    border-right-width: 0 !important;
  }

  :global(.ant-picker-focused) {
    border-color: 0 !important;
    border-right-width: 0 !important;
  }

  :global(.ant-picker:hover) {
    border-color: 0 !important;
    border-right-width: 0 !important;
  }

  span {
    display: none !important;
  }

  :global(.ant-picker-input)>input {
    cursor: pointer;
    width: 6.4em;
  }

  :global(.ant-picker-input) {
    width: 71px;
  }

  :global(.ant-picker-active-bar) {
    background-color: $color-purple-medium !important;
    margin-left: 0 !important;
  }

  :global(.ant-picker-input > input) {
    color: $color-white !important;
    font-size: 11px;
    font-weight: 500;
  }

  :global(.ant-picker-range-separator) {
    color: $color-non-primary-text !important;
    padding: 3px;
  }

  :global(.ant-picker-suffix) {
    color: $color-non-primary-text;
    margin: 0;
  }
}

.siteeventdatepicker {
  background: #313337 !important;
  border-radius: 5px;
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.18);
  left: 230px !important;
  top: 10em !important;
  width: 744px !important;

  :global(.ant-picker-ranges .ant-picker-preset > .ant-tag-blue) {
    background: transparent;
    border: hidden;
    border-color: $color-gray-dark-secondary;
    color: rgba(222, 222, 222, 1);
    cursor: pointer;
    font-size: $font-size-14;
    padding: 5px;

    &:hover {
      border: 1px solid $color-gray-dark-secondary;
      border-radius: 5px;
    }
  }

  @media (max-width: 1203px) {
    opacity: 1 !important;
  }

  @media screen and (max-width: 991px) {
    left: 5.5em !important;
  }

  :global(.ant-picker-panel-container) {
    background-color: rgba(49, 51, 55, 1);
    padding-bottom: 13px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 13px;
  }

  :global(.ant-picker-panels) {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
  }

  :global(.ant-picker-range-arrow) {
    display: none !important;
  }

  :global(.ant-picker-cell-inner) {
    color: $color-white-secondary !important;
    font-size: $font-size-14;
    min-height: 30px !important;
    min-width: 30px !important;
    padding-top: 2px !important;

    &::after {
      background: $color-purple-medium !important;
    }

    &:hover {
      background: $color-purple-medium !important;
    }
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
    border-radius: 2px !important;
  }

  :global(.ant-picker-cell::before) {
    background: transparent !important;
  }

  :global(.ant-picker-cell::after) {
    border-color: $color-purple-medium !important;
    border-radius: 2px !important;
    box-sizing: border-box !important;
  }

  :global(.ant-picker-cell-range-start > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;

    &:hover {
      background-color: #512283 !important;
    }
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-in-range::before) {
    background: $color-purple-medium !important;
    opacity: 0.2;

    &:hover {
      background: $color-purple-medium !important;
    }
  }

  :global(.ant-picker-cell-disabled::before) {
    background: none !important;
  }

  :global(.ant-picker-cell-range-start > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
  }

  :global(.ant-picker-cell-range-hover-end > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
  }

  :global(.ant-picker-header button) {
    color: $color-white !important;
    font-size: $font-size-16;
    font-weight: bold;
  }

  :global(.ant-picker-content th) {
    color: rgba(163, 164, 164, 1) !important;
    cursor: default;
    font-size: $font-size-12;
    font-weight: 600;
    text-transform: uppercase;
  }

  :global(.ant-picker-content tr:nth-child(6)) {
    display: none;
  }

  :global(.ant-picker-ranges) {
    padding-top: 15px;
  }

  :global(.ant-picker-panel) {
    border-bottom: 1px solid #57555c !important;
    width: 350px !important;
  }

  :global(.ant-picker-date-panel) {
    width: 350px !important;

    :global(.ant-picker-body) {
      width: 350px !important;

      :global(.ant-picker-content) {
        width: 100% !important;
      }
    }
  }

  :global(.ant-picker-header) {
    border-bottom: 1px solid #57555c !important;
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before) {
    background: $color-orange-primary;
    border: hidden !important;
    height: 4px;
    left: 25px;
    top: 5px;
    width: 4px;
  }
}

.siteeventdatepickersiteeventclose {
  background: #313337 !important;
  border-radius: 5px;
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.18);
  left: 400px !important;
  top: 12em !important;
  width: 744px !important;

  :global(.ant-picker-ranges .ant-picker-preset > .ant-tag-blue) {
    background: transparent;
    border: hidden;
    border-color: $color-gray-dark-secondary;
    color: rgba(222, 222, 222, 1);
    cursor: pointer;
    font-size: $font-size-14;
    padding: 5px;

    &:hover {
      border: 1px solid $color-gray-dark-secondary;
      border-radius: 5px;
    }
  }

  @media (max-width: 1203px) {
    opacity: 1 !important;
  }

  @media screen and (max-width: 991px) {
    left: 5.5em !important;
  }

  :global(.ant-picker-panel-container) {
    background-color: rgba(49, 51, 55, 1);
    padding-bottom: 13px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 13px;
  }

  :global(.ant-picker-panels) {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
  }

  :global(.ant-picker-range-arrow) {
    display: none !important;
  }

  :global(.ant-picker-cell-inner) {
    color: $color-white-secondary !important;
    font-size: $font-size-14;
    min-height: 30px !important;
    min-width: 30px !important;
    padding-top: 2px !important;

    &::after {
      background: $color-purple-medium !important;
    }

    &:hover {
      background: $color-purple-medium !important;
    }
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
    border-radius: 2px !important;
  }

  :global(.ant-picker-cell::before) {
    background: transparent !important;
  }

  :global(.ant-picker-cell::after) {
    border-color: $color-purple-medium !important;
    border-radius: 2px !important;
    box-sizing: border-box !important;
  }

  :global(.ant-picker-cell-range-start > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;

    &:hover {
      background-color: #512283 !important;
    }
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-in-range::before) {
    background: $color-purple-medium !important;
    opacity: 0.2;

    &:hover {
      background: $color-purple-medium !important;
    }
  }

  :global(.ant-picker-cell-disabled::before) {
    background: none !important;
  }

  :global(.ant-picker-cell-range-start > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
  }

  :global(.ant-picker-cell-range-hover-end > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
  }

  :global(.ant-picker-header button) {
    color: $color-white !important;
    font-size: $font-size-16;
    font-weight: bold;
  }

  :global(.ant-picker-content th) {
    color: rgba(163, 164, 164, 1) !important;
    cursor: default;
    font-size: $font-size-12;
    font-weight: 600;
    text-transform: uppercase;
  }

  :global(.ant-picker-content tr:nth-child(6)) {
    display: none;
  }

  :global(.ant-picker-ranges) {
    padding-top: 15px;
  }

  :global(.ant-picker-panel) {
    border-bottom: 1px solid #57555c !important;
    width: 350px !important;
  }

  :global(.ant-picker-date-panel) {
    width: 350px !important;

    :global(.ant-picker-body) {
      width: 350px !important;

      :global(.ant-picker-content) {
        width: 100% !important;
      }
    }
  }

  :global(.ant-picker-header) {
    border-bottom: 1px solid #57555c !important;
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before) {
    background: $color-orange-primary;
    border: hidden !important;
    height: 4px;
    left: 25px;
    top: 5px;
    width: 4px;
  }
}

.siteeventdatepickersiteevent {
  background: #313337 !important;
  border-radius: 5px;
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.18);
  left: 550px !important;
  top: 12em !important;
  width: 744px !important;

  :global(.ant-picker-ranges .ant-picker-preset > .ant-tag-blue) {
    background: transparent;
    border: hidden;
    border-color: $color-gray-dark-secondary;
    color: rgba(222, 222, 222, 1);
    cursor: pointer;
    font-size: $font-size-14;
    padding: 5px;

    &:hover {
      border: 1px solid $color-gray-dark-secondary;
      border-radius: 5px;
    }
  }

  @media (max-width: 1203px) {
    opacity: 1 !important;
  }

  @media screen and (max-width: 991px) {
    left: 5.5em !important;
  }

  :global(.ant-picker-panel-container) {
    background-color: rgba(49, 51, 55, 1);
    padding-bottom: 13px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 13px;
  }

  :global(.ant-picker-panels) {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
  }

  :global(.ant-picker-range-arrow) {
    display: none !important;
  }

  :global(.ant-picker-cell-inner) {
    color: $color-white-secondary !important;
    font-size: $font-size-14;
    min-height: 30px !important;
    min-width: 30px !important;
    padding-top: 2px !important;

    &::after {
      background: $color-purple-medium !important;
    }

    &:hover {
      background: $color-purple-medium !important;
    }
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
    border-radius: 2px !important;
  }

  :global(.ant-picker-cell::before) {
    background: transparent !important;
  }

  :global(.ant-picker-cell::after) {
    border-color: $color-purple-medium !important;
    border-radius: 2px !important;
    box-sizing: border-box !important;
  }

  :global(.ant-picker-cell-range-start > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;

    &:hover {
      background-color: #512283 !important;
    }
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-in-range::before) {
    background: $color-purple-medium !important;
    opacity: 0.2;

    &:hover {
      background: $color-purple-medium !important;
    }
  }

  :global(.ant-picker-cell-disabled::before) {
    background: none !important;
  }

  :global(.ant-picker-cell-range-start > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
  }

  :global(.ant-picker-cell-range-hover-end > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
  }

  :global(.ant-picker-header button) {
    color: $color-white !important;
    font-size: $font-size-16;
    font-weight: bold;
  }

  :global(.ant-picker-content th) {
    color: rgba(163, 164, 164, 1) !important;
    cursor: default;
    font-size: $font-size-12;
    font-weight: 600;
    text-transform: uppercase;
  }

  :global(.ant-picker-content tr:nth-child(6)) {
    display: none;
  }

  :global(.ant-picker-ranges) {
    padding-top: 15px;
  }

  :global(.ant-picker-panel) {
    border-bottom: 1px solid #57555c !important;
    width: 350px !important;
  }

  :global(.ant-picker-date-panel) {
    width: 350px !important;

    :global(.ant-picker-body) {
      width: 350px !important;

      :global(.ant-picker-content) {
        width: 100% !important;
      }
    }
  }

  :global(.ant-picker-header) {
    border-bottom: 1px solid #57555c !important;
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before) {
    background: $color-orange-primary;
    border: hidden !important;
    height: 4px;
    left: 25px;
    top: 5px;
    width: 4px;
  }
}

.customPicker {
  background: #313337 !important;
  border-radius: 5px;
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.18);
  left: 230px !important;
  top: 10.5em !important;
  width: 744px !important;

  :global(.ant-picker-ranges .ant-picker-preset > .ant-tag-blue) {
    background: none;
    border: hidden;
    border-color: $color-gray-dark-secondary;
    color: rgba(222, 222, 222, 1);
    cursor: pointer;
    font-size: $font-size-14;
    padding: 5px;

    &:hover {
      border: 1px solid $color-gray-dark-secondary;
      border-radius: 5px;
    }
  }

  @media (max-width: 1203px) {
    opacity: 1 !important;
  }

  @media screen and (max-width: 991px) {
    left: 5.5em !important;
  }

  :global(.ant-picker-footer) {
    min-width: unset !important;
  }

  :global(.ant-picker-panel-container) {
    background-color: rgba(49, 51, 55, 1);
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 13px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 13px;
  }

  :global(.ant-picker-panels) {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
  }

  :global(.ant-picker-range-arrow) {
    display: none !important;
  }

  :global(.ant-picker-cell-inner) {
    color: $color-white-secondary !important;
    font-size: $font-size-14;
    min-height: 30px !important;
    min-width: 30px !important;
    padding-top: 2px !important;

    &::after {
      background: $color-purple-medium !important;
    }

    &:hover {
      background: $color-purple-medium !important;
    }
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
    border-radius: 2px !important;
  }

  :global(.ant-picker-cell::before) {
    background: transparent !important;
  }

  :global(.ant-picker-cell::after) {
    border-color: $color-purple-medium !important;
    border-radius: 2px !important;
    box-sizing: border-box !important;
  }

  :global(.ant-picker-cell-range-start > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;

    &:hover {
      background-color: #512283 !important;
    }
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-in-range::before) {
    background: $color-purple-medium !important;
    opacity: 0.2;

    &:hover {
      background: $color-purple-medium !important;
    }
  }

  :global(.ant-picker-cell-disabled::before) {
    background: none !important;
  }

  :global(.ant-picker-cell-range-start > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
  }

  :global(.ant-picker-cell-range-hover-end > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
  }

  :global(.ant-picker-header button) {
    color: $color-white !important;
    font-size: $font-size-16;
    font-weight: bold;
  }

  :global(.ant-picker-content th) {
    color: rgba(163, 164, 164, 1) !important;
    cursor: default;
    font-size: $font-size-12;
    font-weight: 600;
    text-transform: uppercase;
  }

  :global(.ant-picker-content tr:nth-child(6)) {
    display: none;
  }

  :global(.ant-picker-ranges) {
    padding-top: 69px;
  }

  :global(.ant-picker-panel) {
    border-bottom: 1px solid #57555c !important;
    // width: 350px !important;
  }

  :global(.ant-picker-date-panel) {
    // width: 350px !important;

    :global(.ant-picker-body) {
      // width: 350px !important;

      :global(.ant-picker-content) {
        width: 100% !important;
      }
    }
  }

  :global(.ant-picker-header) {
    border-bottom: 1px solid #57555c !important;
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before) {
    background: $color-orange-primary;
    border: hidden !important;
    height: 4px;
    left: 25px;
    top: 5px;
    width: 4px;
  }
}

.customPickerOneMonth {
  background: #313337 !important;
  border-radius: 5px;
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.18);
  left: 230px !important;
  top: 10.5em !important;
  width: 744px !important;

  :global(.ant-picker-ranges .ant-picker-preset > .ant-tag-blue) {
    background: none;
    border: hidden;
    border-color: $color-gray-dark-secondary;
    color: rgba(222, 222, 222, 1);
    cursor: pointer;
    font-size: $font-size-14;
    padding: 5px;

    &:hover {
      border: 1px solid $color-gray-dark-secondary;
      border-radius: 5px;
    }
  }

  :global(.ant-picker-ranges .ant-picker-preset:first-child > .ant-tag-blue) {
    background: none;
    border: hidden;
    border: 1px solid $color-gray-dark-secondary;
    border-color: $color-gray-dark-secondary;
    border-radius: 5px;
    color: rgba(222, 222, 222, 1);
    cursor: pointer;
    font-size: $font-size-14;
    padding: 5px;
  }

  @media (max-width: 1203px) {
    opacity: 1 !important;
  }

  @media screen and (max-width: 991px) {
    left: 5.5em !important;
  }

  :global(.ant-picker-footer) {
    min-width: unset !important;
  }

  :global(.ant-picker-panel-container) {
    background-color: rgba(49, 51, 55, 1);
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 13px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 13px;
  }

  :global(.ant-picker-panels) {
    display: flex;
    justify-content: space-between;
    // width: 100% !important; 
  }

  :global(.ant-picker-range-arrow) {
    display: none !important;
  }

  :global(.ant-picker-cell-inner) {
    color: $color-white-secondary !important;
    font-size: $font-size-14;
    min-height: 30px !important;
    min-width: 30px !important;
    padding-top: 2px !important;

    &::after {
      background: $color-purple-medium !important;
    }

    &:hover {
      background: $color-purple-medium !important;
    }
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
    border-radius: 2px !important;
  }

  :global(.ant-picker-cell::before) {
    background: transparent !important;
  }

  :global(.ant-picker-cell::after) {
    border-color: $color-purple-medium !important;
    border-radius: 2px !important;
    box-sizing: border-box !important;
  }

  :global(.ant-picker-cell-range-start > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;

    &:hover {
      background-color: #512283 !important;
    }
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-in-range::before) {
    background: $color-purple-medium !important;
    opacity: 0.2;

    &:hover {
      background: $color-purple-medium !important;
    }
  }

  :global(.ant-picker-cell-disabled::before) {
    background: none !important;
  }

  :global(.ant-picker-cell-range-start > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
  }

  :global(.ant-picker-cell-range-hover-end > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
  }

  :global(.ant-picker-header button) {
    color: $color-white !important;
    font-size: $font-size-16;
    font-weight: bold;
  }

  :global(.ant-picker-content th) {
    color: rgba(163, 164, 164, 1) !important;
    cursor: default;
    font-size: $font-size-12;
    font-weight: 600;
    text-transform: uppercase;
  }

  :global(.ant-picker-content tr:nth-child(6)) {
    display: none;
  }

  :global(.ant-picker-ranges) {
    padding-top: 69px;
  }

  :global(.ant-picker-panel) {
    border-bottom: 1px solid #57555c !important;
    // width: 350px !important;
  }

  :global(.ant-picker-date-panel) {
    // width: 350px !important;

    :global(.ant-picker-body) {
      // width: 350px !important;

      :global(.ant-picker-content) {
        width: 100% !important;
      }
    }
  }

  :global(.ant-picker-header) {
    border-bottom: 1px solid #57555c !important;
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before) {
    background: $color-orange-primary;
    border: hidden !important;
    height: 4px;
    left: 25px;
    top: 5px;
    width: 4px;
  }
}

.customPickerThreeMonths {
  background: #313337 !important;
  border-radius: 5px;
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.18);
  left: 230px !important;
  top: 10.5em !important;
  width: 744px !important;

  :global(.ant-picker-ranges .ant-picker-preset > .ant-tag-blue) {
    background: none;
    border: hidden;
    border-color: $color-gray-dark-secondary;
    color: rgba(222, 222, 222, 1);
    cursor: pointer;
    font-size: $font-size-14;
    padding: 5px;

    &:hover {
      border: 1px solid $color-gray-dark-secondary;
      border-radius: 5px;
    }
  }

  :global(.ant-picker-ranges .ant-picker-preset:nth-child(2) > .ant-tag-blue) {
    background: none;
    border: hidden;
    border: 1px solid $color-gray-dark-secondary;
    border-color: $color-gray-dark-secondary;
    border-radius: 5px;
    color: rgba(222, 222, 222, 1);
    cursor: pointer;
    font-size: $font-size-14;
    padding: 5px;
  }

  @media (max-width: 1203px) {
    opacity: 1 !important;
  }

  @media screen and (max-width: 991px) {
    left: 5.5em !important;
  }

  :global(.ant-picker-footer) {
    min-width: unset !important;
  }

  :global(.ant-picker-panel-container) {
    background-color: rgba(49, 51, 55, 1);
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 13px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 13px;
  }

  :global(.ant-picker-panels) {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
  }

  :global(.ant-picker-range-arrow) {
    display: none !important;
  }

  :global(.ant-picker-cell-inner) {
    color: $color-white-secondary !important;
    font-size: $font-size-14;
    min-height: 30px !important;
    min-width: 30px !important;
    padding-top: 2px !important;

    &::after {
      background: $color-purple-medium !important;
    }

    &:hover {
      background: $color-purple-medium !important;
    }
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
    border-radius: 2px !important;
  }

  :global(.ant-picker-cell::before) {
    background: transparent !important;
  }

  :global(.ant-picker-cell::after) {
    border-color: $color-purple-medium !important;
    border-radius: 2px !important;
    box-sizing: border-box !important;
  }

  :global(.ant-picker-cell-range-start > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;

    &:hover {
      background-color: #512283 !important;
    }
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-in-range::before) {
    background: $color-purple-medium !important;
    opacity: 0.2;

    &:hover {
      background: $color-purple-medium !important;
    }
  }

  :global(.ant-picker-cell-disabled::before) {
    background: none !important;
  }

  :global(.ant-picker-cell-range-start > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
  }

  :global(.ant-picker-cell-range-hover-end > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
  }

  :global(.ant-picker-header button) {
    color: $color-white !important;
    font-size: $font-size-16;
    font-weight: bold;
  }

  :global(.ant-picker-content th) {
    color: rgba(163, 164, 164, 1) !important;
    cursor: default;
    font-size: $font-size-12;
    font-weight: 600;
    text-transform: uppercase;
  }

  :global(.ant-picker-content tr:nth-child(6)) {
    display: none;
  }

  :global(.ant-picker-ranges) {
    padding-top: 69px;
  }

  :global(.ant-picker-panel) {
    border-bottom: 1px solid #57555c !important;
    // width: 350px !important;
  }

  :global(.ant-picker-date-panel) {
    // width: 350px !important;

    :global(.ant-picker-body) {
      // width: 350px !important;

      :global(.ant-picker-content) {
        width: 100% !important;
      }
    }
  }

  :global(.ant-picker-header) {
    border-bottom: 1px solid #57555c !important;
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-today.ant-picker-cell-inner::before) {
    background: $color-orange-primary;
    border: hidden !important;
    height: 4px;
    left: 25px;
    top: 5px;
    width: 4px;
  }

}

.customPickerSixMonths {
  background: #313337 !important;
  border-radius: 5px;
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.18);
  left: 230px !important;
  top: 10.5em !important;
  width: 744px !important;

  :global(.ant-picker-ranges .ant-picker-preset > .ant-tag-blue) {
    background: none;
    border: hidden;
    border-color: $color-gray-dark-secondary;
    color: rgba(222, 222, 222, 1);
    cursor: pointer;
    font-size: $font-size-14;
    padding: 5px;

    &:hover {
      border: 1px solid $color-gray-dark-secondary;
      border-radius: 5px;
    }
  }

  :global(.ant-picker-ranges .ant-picker-preset:nth-child(3) > .ant-tag-blue) {
    background: none;
    border: hidden;
    border: 1px solid $color-gray-dark-secondary;
    border-color: $color-gray-dark-secondary;
    border-radius: 5px;
    color: rgba(222, 222, 222, 1);
    cursor: pointer;
    font-size: $font-size-14;
    padding: 5px;
  }

  @media (max-width: 1203px) {
    opacity: 1 !important;
  }

  @media screen and (max-width: 991px) {
    left: 5.5em !important;
  }

  :global(.ant-picker-footer) {
    min-width: unset !important;
  }

  :global(.ant-picker-panel-container) {
    background-color: rgba(49, 51, 55, 1);
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 13px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 13px;
  }

  :global(.ant-picker-panels) {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
  }

  :global(.ant-picker-range-arrow) {
    display: none !important;
  }

  :global(.ant-picker-cell-inner) {
    color: $color-white-secondary !important;
    font-size: $font-size-14;
    min-height: 30px !important;
    min-width: 30px !important;
    padding-top: 2px !important;

    &::after {
      background: $color-purple-medium !important;
    }

    &:hover {
      background: $color-purple-medium !important;
    }
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
    border-radius: 2px !important;
  }

  :global(.ant-picker-cell::before) {
    background: transparent !important;
  }

  :global(.ant-picker-cell::after) {
    border-color: $color-purple-medium !important;
    border-radius: 2px !important;
    box-sizing: border-box !important;
  }

  :global(.ant-picker-cell-range-start > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;

    &:hover {
      background-color: #512283 !important;
    }
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-in-range::before) {
    background: $color-purple-medium !important;
    opacity: 0.2;

    &:hover {
      background: $color-purple-medium !important;
    }
  }

  :global(.ant-picker-cell-disabled::before) {
    background: none !important;
  }

  :global(.ant-picker-cell-range-start > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
  }

  :global(.ant-picker-cell-range-hover-end > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
  }

  :global(.ant-picker-header button) {
    color: $color-white !important;
    font-size: $font-size-16;
    font-weight: bold;
  }

  :global(.ant-picker-content th) {
    color: rgba(163, 164, 164, 1) !important;
    cursor: default;
    font-size: $font-size-12;
    font-weight: 600;
    text-transform: uppercase;
  }

  :global(.ant-picker-content tr:nth-child(6)) {
    display: none;
  }

  :global(.ant-picker-ranges) {
    padding-top: 69px;
  }

  :global(.ant-picker-panel) {
    border-bottom: 1px solid #57555c !important;
    // width: 350px !important;
  }

  :global(.ant-picker-date-panel) {
    // width: 350px !important;

    :global(.ant-picker-body) {
      // width: 350px !important;

      :global(.ant-picker-content) {
        width: 100% !important;
      }
    }
  }

  :global(.ant-picker-header) {
    border-bottom: 1px solid #57555c !important;
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before) {
    background: $color-orange-primary;
    border: hidden !important;
    height: 4px;
    left: 25px;
    top: 5px;
    width: 4px;
  }
}

.customPickerOneYear {
  background: #313337 !important;
  border-radius: 5px;
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.18);
  left: 230px !important;
  top: 10.5em !important;
  width: 744px !important;

  :global(.ant-picker-ranges .ant-picker-preset > .ant-tag-blue) {
    background: none;
    border: hidden;
    border-color: $color-gray-dark-secondary;
    color: rgba(222, 222, 222, 1);
    cursor: pointer;
    font-size: $font-size-14;
    padding: 5px;

    &:hover {
      border: 1px solid $color-gray-dark-secondary;
      border-radius: 5px;
    }
  }

  :global(.ant-picker-ranges .ant-picker-preset:nth-child(4) > .ant-tag-blue) {
    background: none;
    border: hidden;
    border: 1px solid $color-gray-dark-secondary;
    border-color: $color-gray-dark-secondary;
    border-radius: 5px;
    color: rgba(222, 222, 222, 1);
    cursor: pointer;
    font-size: $font-size-14;
    padding: 5px;
  }

  @media (max-width: 1203px) {
    opacity: 1 !important;
  }

  @media screen and (max-width: 991px) {
    left: 5.5em !important;
  }

  :global(.ant-picker-footer) {
    min-width: unset !important;
  }

  :global(.ant-picker-panel-container) {
    background-color: rgba(49, 51, 55, 1);
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 13px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 13px;
  }

  :global(.ant-picker-panels) {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
  }

  :global(.ant-picker-range-arrow) {
    display: none !important;
  }

  :global(.ant-picker-cell-inner) {
    color: $color-white-secondary !important;
    font-size: $font-size-14;
    min-height: 30px !important;
    min-width: 30px !important;
    padding-top: 2px !important;

    &::after {
      background: $color-purple-medium !important;
    }

    &:hover {
      background: $color-purple-medium !important;
    }
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
    border-radius: 2px !important;
  }

  :global(.ant-picker-cell::before) {
    background: transparent !important;
  }

  :global(.ant-picker-cell::after) {
    border-color: $color-purple-medium !important;
    border-radius: 2px !important;
    box-sizing: border-box !important;
  }

  :global(.ant-picker-cell-range-start > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;

    &:hover {
      background-color: #512283 !important;
    }
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-in-range::before) {
    background: $color-purple-medium !important;
    opacity: 0.2;

    &:hover {
      background: $color-purple-medium !important;
    }
  }

  :global(.ant-picker-cell-disabled::before) {
    background: none !important;
  }

  :global(.ant-picker-cell-range-start > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
  }

  :global(.ant-picker-cell-range-hover-end > .ant-picker-cell-inner) {
    background: $color-purple-medium !important;
  }

  :global(.ant-picker-header button) {
    color: $color-white !important;
    font-size: $font-size-16;
    font-weight: bold;
  }

  :global(.ant-picker-content th) {
    color: rgba(163, 164, 164, 1) !important;
    cursor: default;
    font-size: $font-size-12;
    font-weight: 600;
    text-transform: uppercase;
  }

  :global(.ant-picker-content tr:nth-child(6)) {
    display: none;
  }

  :global(.ant-picker-ranges) {
    padding-top: 69px;
  }

  :global(.ant-picker-panel) {
    border-bottom: 1px solid #57555c !important;
    // width: 350px !important;
  }

  :global(.ant-picker-date-panel) {
    // width: 350px !important;

    :global(.ant-picker-body) {
      // width: 350px !important;

      :global(.ant-picker-content) {
        width: 100% !important;
      }
    }
  }

  :global(.ant-picker-header) {
    border-bottom: 1px solid #57555c !important;
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before) {
    background: $color-orange-primary;
    border: hidden !important;
    height: 4px;
    left: 25px;
    top: 5px;
    width: 4px;
  }
}

.pickerHeading {
  border-radius: 4px 0 0 4px;
  border-right: 0;
  color: #a3a4a4;
  cursor: pointer;
}

.progressBar {
  width: 10em !important;

  :global(.ant-progress-inner) {
    border-radius: 0 !important;
  }

  :global(.ant-progress-text) {
    color: #a1a0a3 !important;
  }
}

.options {
  :global(.ant-select-item-option-content) {
    overflow: initial !important;
  }

  &:global(.ant-row) {
    justify-content: space-between;
    margin-right: -8.5px !important;
  }

  .activate {
    background: rgba(52, 174, 243, 0.1);
    border: 1px solid rgba(52, 174, 243, 0.5);
    border-radius: 4px;
    color: #34aef3;
    font-size: 14px;
    font-weight: 500;
    height: 32px;
    line-height: 17px;
    margin-right: 10px;
    width: 76px;
  }

  .property {
    margin-right: 7px;
  }

  .propertyIcon {
    font-weight: 700;
    padding-left: 18px;
    padding-right: 11px;
  }

  .checkContainer {
    padding-left: 4px !important;

    .checkIcon {
      background: $color-light-green;
      border: 0;
      border-radius: 7px;
      color: #222126;
    }
  }

  &.optionDisable {
    .projectBox {
      .url {
        color: #a3a4a4;
      }

      span {
        color: #a3a4a4 !important;
      }

      img {
        background: #f9f9fb !important;
        opacity: 0.2 !important;
      }
    }
  }


  &.scaOptions {
    justify-content: flex-start;
    margin-left: 0 !important;
    margin-right: 0 !important;

    .folderIconContainer {
      background-color: $color-gray-dark-secondary;
      border-radius: 5px;
      display: flex;
      height: 30px;
      margin-right: 8px;
      width: 30px;
    }

    .menuToggler {
      display: none;
      height: 20px;
      margin-left: auto;
      margin-right: 20px;
      position: relative;
      width: 20px;

      .kebabMenuIcon {
        margin: auto;
      }
    }

    &:hover {
      .menuToggler {
        display: flex;
      }
    }
  }

  .gaIconContainer {
    background-color: transparent;
    border-radius: 4px;
    display: flex;
    height: 32px;
    margin-left: 5px;
    transition: all 0.2s ease;
    width: 32px;

    .gaIconColor {
      margin: auto;
      width: 16px;
    }

    .gaIcon {
      margin: auto;
      // filter:  hue-rotate(100deg) contrast(0);
      transition: all 0.2s ease;
      width: 16px;
    }

    .gaIconGray {
      filter: hue-rotate(100deg) contrast(0);
    }

    &:hover {
      background-color: #ffffff1a;

      // .gaIcon {
      //   filter:  hue-rotate(100deg) ;
      // }
    }
  }

}

.options.withTag {
  padding-top: 20px;
  position: relative;
}

.ProgressIconContainer {
  height: 16px;
  margin-left: auto;
  margin-top: 15px;
  position: relative;
  transform: translateY(-50%);
  width: 16px;
}

.ComplitedIcon,
.InProgressIcon {
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  &.ComplitedIcon {
    background-image: url('/img/google-color.svg');
    height: 100%;
  }

  &.InProgressIcon {
    background-image: url('/img/google-gray.svg');
  }
}

.Tooltip {
  cursor: default;
  font-size: $font-size-14;
  padding-left: 22px !important;

  ul {
    margin-top: 1em;
    padding-left: 23px;

    li {
      span {
        color: #cdcdcd;
      }
    }
  }

  :global(.ant-tooltip-arrow) {
    margin-left: 14px !important;
  }

  :global(.ant-tooltip-arrow-content) {
    background-color: #1a181d !important;
  }

  :global(.ant-tooltip-inner) {
    background-color: #1a181d;
    border-radius: 8px;
    font-size: $font-size-14;
    padding: 7px 9px;
  }
}

.TagToolTip {
  max-width: 570px !important;
  padding-left: 250px !important;
}

.SubDomain {
  background: #38363b;
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.5);
  padding: 2px 10px;
}

.ModalHeader {
  color: #fff;
  text-align: center;

  p {
    color: #54565a;
    font-size: $font-size-14;
  }

  h3 {
    color: $color-purple-medium;
  }
}

.PendingInvoice,
.SummaryBox,
.TotalDue,
.BtnRow {
  display: flex;
  justify-content: space-between;
}

.PendingInvoice {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  padding: 20px;

  .PendingUserInfo {
    h3 {
      font-size: $font-size-24;
    }

    .DueDateTitle {
      color: $color-secondary-text;
      margin-bottom: 0;
    }
  }

  .PendingAmount {
    text-align: right;

    h3 {
      color: $color-purple-medium;
      font-size: $font-size-24;
      font-style: normal;
      font-weight: 800;
      line-height: 44px;
    }

    .CheckoutBtn {
      background: $color-purple-medium;
      color: #fff;
      font-size: $font-size-14;
      max-width: 125px;
    }
  }
}

.SummaryBox {
  margin-top: 18px;

  .SummaryLeft {
    p {
      margin-bottom: 0;
    }
  }

  .SummaryRight {
    display: flex;

    p {
      font-size: $font-size-16;
      margin-bottom: auto;
      margin-top: auto;
    }
  }
}

.RowTitle {
  color: $color-secondary-text;
  font-size: $font-size-12;
  font-weight: 600;
  line-height: 15px;
  text-transform: uppercase;
}

.ModalFooter {
  font-family: Inter;
  font-size: $font-size-14;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}

.SummaryContainer {
  @include scrollbars(5px, rgba(138, 138, 138, 0.32), rgba(138, 138, 138, 0.12));
  max-height: calc(90vh - 240px);
  overflow-y: auto;
}

.ranger {
  align-items: center;
  background: rgba(84, 86, 90, 0.17);
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  height: 50px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    border: 1px solid #4e5156 !important;
    border-radius: 5px;
  }

  &Button {
    align-items: center;
    border: 1px solid transparent !important;
    display: flex;
    margin-left: 8px;
    padding: 0;
    z-index: 1 !important;

    :global(.ant-picker:hover:focus) {
      border-color: 0 !important;
      border-right-width: 0 !important;
    }

    &:focus,
    &:hover {
      // background: rgba(84, 86, 90, 0.1) !important;
      // border: 1px solid $color-gray-dark-secondary !important;
      border-radius: 5px !important;
      padding-right: 30px;
    }

    &Open {
      // background: rgba(84, 86, 90, 0.1);
      // border: 0px solid  !important;
      border-radius: 5px;
    }
  }
}

.rangernew {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;

  //@media (max-width: 1207px) {
  //  min-width: 135px;
  //}



  &Button {
    border: 1px solid transparent;
    padding: 4px 20px 4px 10px;
    text-align: left;
    width: 100%;
    z-index: 1 !important;

    @media (max-width: 1207px) {
      margin: 0 5px;
    }

    :global(.ant-picker:hover) {
      border-color: 0 !important;
      border-right-width: 0 !important;
    }

    &:focus,
    &:hover {
      background: rgba(84, 86, 90, 0.1) !important;
      border: 1px solid $color-gray-dark-secondary !important;
      border-radius: 5px !important;
      //padding-right: 10px;
    }

    &Open {
      background: rgba(84, 86, 90, 0.1);
      border: 1px solid $color-gray-dark-secondary !important;
      border-radius: 5px;
    }
  }
}

.eventsDateValue {
  color: #fff;
}

.header__left {
  display: flex;
  margin-left: 18px;
  width: 100%;

  @media (max-width: 1400px) {
    margin-left: -7px;
  }

  @media (max-width: 992px) {
    margin-left: 0;
  }

  @media (max-width: 600px) {
    margin-left: -7px;
  }

  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
  }
}

.location__button {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
}

.rangerCaret {
  span {
    color: #fff;

    svg {
      cursor: pointer;
      width: 10px;
      //@media (max-width: 1207px) {
      //  margin-left: -364px !important;
      //}
    }
  }
}

.rangerCaretNew {
  span {
    color: #fff;

    svg {
      cursor: pointer;
      width: 10px;
    }
  }
}


.loading {
  color: #7f4ead !important;
  position: absolute;
  right: 0.7rem;
  top: 0.65rem;
}

.headerCaretDown {
  margin-top: 20px;

  span {
    svg {
      width: 10px;
    }
  }
}

.upgradeTooltip {
  z-index: 5 !important;

  :global(.ant-tooltip-inner) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), $color-gray-dark-medium;
    border-radius: 8px;
    font-size: $font-size-14;
    line-height: 20px;
    padding: 20px 15px;
  }

  .upgradeButton {
    background: rgba(82, 213, 119, 0.1);
    border: 1px solid #52d577;
    border-radius: 5px;
    box-sizing: border-box;
    color: #fff;
    font-size: $font-size-14;
    height: 30px;
    line-height: 22px;
    padding-top: 3px;
    text-align: center;
    width: 131px;
  }
}

.freeConsultationButton {
  background: rgba(78, 81, 86, 0.1) !important;
  border: 1px solid $color-gray-dark-secondary !important;
  border-radius: 5px;
  font-size: $font-size-14 !important;
  height: 38px !important;
  padding: 0 20px !important;
  white-space: nowrap;
  width: 159px !important;

  @media (max-width: 1384px) {
    display: none !important;
  }

  @media (max-height: 800px) {
    height: 32px !important;
    padding: 4px 15px;
    width: auto !important;
  }
}

.freeConsultationIcon {
  background: transparent !important;
  border: transparent;
  font-size: $font-size-14 !important;

  @media (min-width: 1385px) {
    display: none;
  }
}

.notificationPopover {
  background-color: transparent;

  :global(.ant-popover-arrow) {
    display: none;
  }

  :global(.ant-popover-content) {
    background-color: transparent;

    :global(.ant-popover-inner) {
      background-color: transparent;
    }
  }

  :global(.ant-popover-inner-content) {
    background-color: transparent;
    padding: 0 !important;
  }
}

.announcekitInner {
  background-color: #ffffff1a !important;
  border-radius: 4px;
  color: #fff;
  height: 32px !important;
  padding: 6.5px 20px !important;

  @media (max-height: 800px) {
    padding: 6px 20px;
  }
}

.alphaBadge {
  align-items: center;
  background-color: #4a4c51;
  border-radius: 50px;
  color: #fff;
  font-size: 11px;
  height: 19px;
  margin-left: 7px;
  padding: 0 9px;
  width: max-content;
}

.globeIcon {
  color: #fff;
  margin-left: 3px;
  transform: translateY(3px);
  width: 18px;
}

.menuIcon {
  cursor: pointer;
  display: none;
  margin-left: 1rem;
  margin-right: 1rem;

  @media (max-width: 975px) {
    display: block;
  }
}

.groupDropdownclose {
  background: #313337;
  border-radius: 5px;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
  height: 240px;
  margin-left: -500px !important;
  margin-top: 106px !important;
  top: 60px !important;
  width: 273px;

  @media (min-width: 1380px) {
    left: 615px !important;
  }

  @media (min-width: 1208px) and (max-width: 1379px) {
    left: 590px !important;
  }

  :global(.ant-picker-active-bar) {
    margin-left: 33px !important;
  }

  :global(.ant-dropdown-menu) {
    background-color: transparent;
    color: #a3a4a4;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    height: 100%;
    line-height: 22px;
    padding: 12px 20px;
    position: relative;

    div:nth-child(3) {
      margin-top: 55px;
    }

    .pickerHeader {
      background: rgba(84, 86, 90, 0.1) !important;
      border: 1px solid #4e5156 !important;
      border-radius: 5px !important;
      bottom: auto !important;
      display: flex !important;
      justify-content: left !important;
      left: 38px;
      padding: 7px 11px !important;
      top: 180px;
      // position: unset !important;
      width: 80% !important;

      div:nth-child(3) {
        margin-top: 0;
      }

      :global(.ant-picker-input) {
        input {
          font-size: 12px;
          font-weight: normal;
          height: 15px !important;
        }
      }
    }

    :global(.ant-radio-wrapper) {
      color: #fff;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      line-height: 22px;
      margin: 8px 0 0;
      width: 100%;

      :global(span.ant-radio + *) {
        padding-left: 5px;
        padding-right: 0;
      }

      :global(.ant-radio-checked) {
        :global(.ant-radio-inner) {
          border: 5px solid #7f4ead;

          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.groupDropdown {
  background: #313337;
  border-radius: 5px;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
  height: 240px;
  margin-left: -350px !important;
  margin-top: 106px !important;
  top: 60px !important;
  width: 273px;

  @media (min-width: 1380px) {
    left: 615px !important;
  }

  @media (min-width: 1208px) and (max-width: 1379px) {
    left: 590px !important;
  }

  :global(.ant-picker-active-bar) {
    margin-left: 33px !important;
  }

  :global(.ant-dropdown-menu) {
    background-color: transparent;
    color: #a3a4a4;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    height: 100%;
    line-height: 22px;
    padding: 12px 20px;
    position: relative;

    div:nth-child(3) {
      margin-top: 55px;
    }

    .pickerHeader {
      background: rgba(84, 86, 90, 0.1) !important;
      border: 1px solid #4e5156 !important;
      border-radius: 5px !important;
      bottom: auto !important;
      display: flex !important;
      justify-content: left !important;
      left: 38px;
      padding: 7px 11px !important;
      top: 180px;
      // position: unset !important;
      width: 80% !important;

      div:nth-child(3) {
        margin-top: 0;
      }

      :global(.ant-picker-input) {
        input {
          font-size: 12px;
          font-weight: normal;
          height: 15px !important;
        }
      }
    }

    :global(.ant-radio-wrapper) {
      color: #fff;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      line-height: 22px;
      margin: 8px 0 0;
      width: 100%;

      :global(span.ant-radio + *) {
        padding-left: 5px;
        padding-right: 0;
      }

      :global(.ant-radio-checked) {
        :global(.ant-radio-inner) {
          border: 5px solid #7f4ead;

          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.orderBuilderHeaderStyle {
  margin: 0 auto;
  width: 1070px;

  img {
    padding: 0 !important;
  }

  @media (max-width: 1100px) {
    width: 630px;
  }
}

.editUrlBtnContainer {
  display: flex;

  :global(.ant-form-item-control-input-content) {
    display: flex;
  }

}

.dropDockedSidebar {
  align-items: center;
  border: 1px solid transparent !important;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 40px !important;
  justify-content: center;
  margin-left: 0;
  margin-top: 4px;
  padding: 7px;


  &:hover {
    background: rgba(84, 86, 90, 0.1) !important;
    border: 1px solid #4e5156 !important;
    border-radius: 5px !important;
  }

  :global(.ant-select) {
    height: 40px;
    margin: 0;
    padding: 0 !important;
    width: 100%;

    &:global(.ant-select-focused) {
      :global(.ant-select-selector) {
        box-shadow: unset !important;
      }

    }

    :global(.ant-select-selection-item) {
      padding: 0;

    }

    :global(.ant-select-selector) {
      height: 32px !important;
      margin-left: unset;
      margin-top: unset;
      max-width: unset !important;
      min-width: unset !important;
      padding: 0 !important;
      width: unset !important;

      :global(.ant-select-selection-item) {
        padding: 0;
        width: 100%;

        div {
          img {
            height: 30px !important;
            width: 30px !important;
          }

          span {
            display: none;
          }
        }
      }
    }
  }

  :global(.ant-select-selection-item) {
    div {
      svg {
        margin-right: 0 !important;
      }
    }
  }
}

.drop {
  border: 1px solid transparent !important;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 25px;
  // margin-top: -5px;

  &:hover {
    background: rgba(84, 86, 90, 0.1) !important;
    border: 1px solid #4e5156 !important;
    border-radius: 5px !important;
  }

  :global(.ant-select) {
    height: 40px;
    margin-top: -11px;

    &:global(.ant-select-focused) {
      :global(.ant-select-selector) {
        box-shadow: unset !important;
      }

    }
  }

  :global(.ant-select-selection-item) {
    overflow: visible;

    div {
      overflow: visible;

      svg {
        height: 30px;
        left: -43px;
        margin-right: 0 !important;
        position: absolute;
        top: -13px;
        width: 30px !important;
      }

      img {
        height: 24px !important;
        left: -43px;
        position: absolute;
        top: -10px;
        width: 30px !important;
      }
    }
  }
}

.gscHeading {
  padding-left: 5px;

  :global(.ant-select-selection-placeholder) {
    max-width: 180px;
  }
}

.open {
  display: block;

}

.close {
  display: none;

}

.opened {
  :global(.ant-select-selection-placeholder) {
    display: none;
  }

  :global(.ant-select-selection-item) {
    display: none;
  }
}

.closed {
  :global(.ant-select-selection-item) {
    display: block;
  }

  :global(.ant-select-selection-placeholder) {
    display: block;
  }

  :global(.ant-picker-dropdown-range) {
    left: 70px !important;

  }

}

:global(.ant-picker) {
  &:global(.ant-picker-range) {
    &.closed {
      :global(.ant-picker-input),
      :global(.ant-picker-range-separator) {
        display: none;
      }
    }

    &.opened {
      display: inline-flex;
    }
  }
}

.closed {
  &:global(.ant-picker-dropdown-range) {
    left: 70px !important;

  }
}

.opened {
  &:global(.ant-picker-dropdown-range) {
    // left: 70px !important;
    margin-left: 10px;
  }
}

.hideLogo {
  .hideimg {
    display: none !important;
  }
}

.logoWrapper {
  height: 100%;
  max-height: 50px;
}

.countrydropdown {
  left: 240px !important;
  top: 240px !important;
}

.countrydropdownout {
  left: 90px !important;
  top: 215px !important;
  width: 182px !important;
}

.boxItemStyle {
  align-items: center;
  background: #ffffff1a;
  border-radius: 4px;
  color: $color-gray-light;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  gap: 6px;
  height: 28px;
  margin: 0 !important;
  min-width: 125px;
  padding: 0 12px !important;
  width: fit-content;

  &.selected {
    color: #fff;
  }
}
