@import '@/style/colors';
@import 'src/style/fonts';

.login {
  display: flex;

  .header {
    border-bottom: 1px solid $color-white-secondary;
  }

  .flexBetween {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }

  .form {
    margin-bottom: 0;
    @media (max-width: 600px) {
      margin-bottom: 3rem;
    }
  }
}

.loginBtn {
  background: rgba(127, 78, 173, 0.15) !important;
  border-color: $color-purple-medium !important;
  color: $color-purple-medium !important;
  margin-bottom: 1rem !important;

  :hover {
    background: rgba(127, 78, 173, 0.2) !important;
  }
}

.loginLabel {
  color: $color-gray-dark-secondary;
  font-size: $font-size-12 !important;
  font-weight: normal;
  line-height: 20px;
}


.inputOverrides {
  padding-left: 2.3rem !important;
}

.passIcon {
  float: right;
  margin-right: 9px;
  margin-top: -52px;
  position: relative;

  svg {
    color: #a3a4a4;
    height: 15px;
  }
}

.inputIcon {
  align-items: center;
  color: $color-gray-light;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 40px;
}

.signUp {
  color: $color-gray-light;
}

.loginMobileFooter {
  background: linear-gradient(0deg, #17171a, #17171a), $color-gray-dark-medium;
  border-radius: 10px 10px 0 0;
  display: none;
  min-height: 244px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.8rem;
  position: relative;
  width: 100vw;
  @media (max-width:600px) {
    display: block;
  }

  div {
    div {
      button {
        background-color: transparent !important;
      }

      ul {
        bottom: -1rem !important;
        left: -3rem !important;
        margin-top: 2rem;
        position: absolute;
        right: auto !important;
        @media (min-width:450px) {
          bottom: -3rem !important;
          left: -5rem !important;
        }

        li {
          background: $color-gray-dark-secondary;
          border-radius: 17px;
          height: 8px !important;
          width: 8px !important;

          &:global(.slick-active) {
            background-color: $color-purple-medium !important;
            width: 30px !important;
          }
        }
      }
    }
  }

  h2 {
    color: $color-white;
    font-size: $font-size-20;
    font-weight: 800;
  }

  p {
    color: $color-white-secondary;
    font-size: $font-size-14;
    font-weight: 300;
    line-height: 24px;
  }
}



@media (max-width:450px) {
  .login {
    &Left {
      padding: 2.5rem 15px;

      h1 {
        font-size: $font-size-24;
        font-weight: 800;
      }
    }
  }
}

.languagesSwitcher {
  align-items: center;
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin-top: 2.5rem;

  li {
    color: $color-blue;
    font-size: $font-size-12;

    margin-right: 10px;
    padding: 0 4px;

    :hover {
      text-decoration: underline;
    }
  }
}
