@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/components/common-components/scss/mixins.scroll';

.overlay {
  :global(.ant-popover-arrow) {
    display: none !important;
  }
}

.projectContainer {
  display: flex;
  @media screen and (max-width: 768px) {
    display: block;
  }

  .infoHeaderRightContainer {
    padding-right: 0 !important;
  }

  .infoHeaderRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 15px;
    // width: 100%;
    width: calc(100% - 90px);
  }

  .info {
    background: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 6px 10px 10px 6px;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.02);
    box-sizing: border-box;
    display: flex;
    padding: 23px;
    width: 355px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }

    .headerTitle {
      margin-bottom: 12px;

      :global(.ant-progress) {
        margin-right: 10px;
      }

      .dot {
        margin: -8px;
      }

      .stateContainer:hover .addButton {
        visibility: visible;
      }

      .addButton {
        color: #2d6cca;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        padding-left: 5px;
        visibility: hidden;
      }

      :global(.ant-progress-circle-trail) {
        stroke: #4e5156 !important;
      }
    }

    &Right {
      align-items: center;
      display: flex;

      span {
        color: $color-white-secondary;
        margin-left: 1rem;
      }

      div {
        display: inline-block;
      }

      div,
      p {
        border-right: 1px solid $color-gray-dark-secondary;
        color: $color-light-gray;
        font-size: $font-size-14;
        font-weight: 500;
        margin: 0;
        padding-right: 1rem;
        text-align: center;

        .viewCompetitorLink {
          border: 0.063rem solid transparent;
          border-radius: 0.5rem;
          margin-left: 0.313rem;
          margin-right: 0.313rem;
          padding: 10px;

          &:hover {
            border: 0.063rem solid $color-gray-dark-secondary !important;
          }
        }

        span {
          margin: 0 !important;
          margin-right: 4px !important;
        }
      }
    }

    div {
      .headerTitleName {
        color: $color-white !important;
        display: flex;
        font-size: $font-size-18 !important;
        font-weight: 600;
        line-height: 20px;
        margin: 0;
        padding-bottom: 5px;
      }

      .dropdown {
        font-size: $font-size-20;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .infoHeaderRightLink {
      color: #dedede !important;
      display: flex;
      font-size: $font-size-14 !important;
      font-weight: 500;
      line-height: 22px;
      padding-left: 5px;

      span {
        padding-right: 5px;
      }
    }

    .imageContainer {
      height: 50px;
      text-align: center;
      width: 70px;

      h3 {
        color: $color-white;
        font-size: $font-size-12;
        font-weight: bold;
        margin-bottom: 12px;
      }

      .img {
        border-radius: 3px;
        filter: drop-shadow(0 4px 20px rgba(0, 65, 162, 0.2));
        height: 100px;
        width: 160px;
      }

      .gscFavicon {
        max-height: 50px;
        max-width: 50px;
        min-height: 50px;
        min-width: 50px;
      }
    }

  }

  .progressNumber {
    color: $color-white;
    font-size: $font-size-14;
    font-weight: bold;
    line-height: 22px;
    margin-right: 10px;
    text-align: center;
  }

  .details {
    @include scrollbars(5px, rgba(138, 138, 138, 0.32), rgba(138, 138, 138, 0.12));
    display: flex;
    flex: 1 1 auto;
    overflow-x: auto;
    padding: 25px;
    //min-width: 755px;
    //max-width: 100%;
  }

  .divider {
    background: $color-border;
    height: 110px;
    margin: auto 0;
    width: 1px;
  }

}

.projectsList {
  max-height: 300px;
  overflow: auto;
  padding: 0;
}

.noArchived {
  padding-bottom: 30px;
}

.projectsList li:last-child {
  border-bottom: transparent;
}

.projectsList li {
  border-bottom: 1px solid rgb(244, 244, 244);
  list-style: none;
  margin: 12px 0 0;
}

.projectItem {
  display: flex;
  height: 62px;

  justify-content: space-between;

}

.projectImgWrapper {
  display: flex;
  flex-direction: row;
}

.projectImgContainer {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-right: 8px;
  width: 40px;
}

.archiveText {
  color: #4e5156 !important;
  font-size: 12px !important;

}

.archiveTitle {
  color: #121212 !important;
  font-size: 14px !important;
  font-weight: 500;
}

.projectArchive {
  color: $color-red-primary;
  font-size: $font-size-14;
  font-weight: normal;
  line-height: 22px;
}

.crawlSetting {
  color: #121212;
  font-size: $font-size-14;
  font-weight: normal;
  line-height: 22px;
}

.sliderStyle {
  :global(.ant-slider-handle) {
    background-color: #2d6cca !important;
    border-color: #2d6cca !important;
    height: 16px !important;
    width: 16px !important;
  }
}

.selectStyle {
  :global(.ant-select) {
    height: 40px;
    padding: 0 !important;

    &:hover {
      :global(.ant-select-selector) {
        border: 1px solid #e8e8e8 !important;
      }
    }

    :global(.ant-select-selection-search) {
      align-items: center;
      display: flex;
    }

    :global(.ant-select-selector) {
      align-items: center;
      border: 1px solid #e8e8e8;
      border-radius: 5px !important;
      display: flex;
      height: 40px !important;
      padding: 0 15px;
    }

    :global(.ant-select-selection-item) {
      display: flex;
    }

    :global(.ant-select-arrow) {
      svg {
        color: #121212;
        fill: #121212;
      }
    }
  }

}

.modalIconStyle {
  :global(.rc-dialog-close-x) {
    &::before {
      background: transparent !important;
      color: #fff !important;
    }

    &::after {
      background: transparent !important;
      color: #fff !important;
      content: '×';
      font-size: $font-size-46 !important;
      font-size: 30px !important;
      height: 10px;
      margin-top: -20;
      position: absolute;
      right: -3rem;
      top: 0;
      transform: matrix(1, 0, 0, -1, 0, 0);
      width: 10px;
    }
  }
}

.projectImg {
  border-radius: 4px;
  height: 30px;
  margin-right: 8px;
  width: 30px;
}

.archiveFooter {
  button {
    background: #f2edf7;
    color: #7f4ead;

    &:hover,
    &:active,
    &:visited,
    &:focus {
      background: #f2edf7;
      color: #7f4ead;
    }
  }
}

.cardsContainer {
  display: flex;
  width: 100%;

  .cardBox {
    width: 25%;
  }
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;

    .cardBox {
      width: 160px !important;
    }
  }
  @media screen and (max-width: 484px) {
    justify-content: center;
  }

  .locationCardBox {
    @include scrollbars(5px, rgba(138, 138, 138, 0.32), rgba(138, 138, 138, 0.12));
    overflow-x: auto;
    padding-right: 4px;
    width: 20%;

  }

  .justifyLeft {
    justify-content: left;
  }

}


.authButton {
  border-right: 0.063rem solid $color-gray-dark-secondary;
  padding-right: 0.313rem !important;

  div {
    border: 0.063rem solid transparent;
    border-radius: 0.5rem;
    margin-right: 0.313rem;
    padding: 10px;

    &:hover {
      border: 0.063rem solid $color-gray-dark-secondary !important;
    }
  }
}

.lastCrawlContainer {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  @media screen and (max-width: 768px) {
    gap: 8px;
    justify-content: flex-start;
  }
}

.viewAuditContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-top: 10px;
  width: 100%;
  @media screen and (max-width: 425px) {
    gap: 8px;
    justify-content: flex-start;
  }
}
