.datePopover {
  :global(.ant-dropdown-menu-sub) {
    border: 1px solid rgba(205, 205, 205, 1);
    border-radius: 10px;
    box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.15);
    overflow: hidden !important;

    :global(.ant-dropdown-menu-item.ant-dropdown-menu-item-active) {
      background-color: #fff;
    }
  }
}
