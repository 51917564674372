@import 'src/style/colors';
@import 'src/style/fonts';

.selection {
  align-items: center;
  display: flex;
  position: absolute;
  white-space: nowrap;
  z-index: 2;
}

.selectionLonger {
  display: flex;
  position: absolute;
  white-space: nowrap;
  z-index: 2;
}

.locationWrapper {
  align-items: center;
  border: 1px solid $color-light-gray;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  height: 42px;
  padding-left: 5px;
  position: relative;
  width: 180px;

  :global(.ant-select-arrow) {
    right: 5px !important;
  }

  .globeIcon {
    color: $color-gray-dark;
    margin-left: 3px;
    transform: translateY(3px);
    width: 18px;
  }

  .globeIconDisabled {
    color: $color-gray-dark-secondary;
    margin-left: 10px;
    transform: translateY(3px);
    width: 18px;
  }

  .dropdown {
    border: 0 !important;
    position: absolute;
    right: 0;
    width: 30px;

    .ant-select .ant-select-selector {
      border: unset !important;
    }

    :global(.ant-select-item-option-state) {
      display: none !important;
    }
  }

  .locationText {
    color: $color-gray-dark;
    font-size: $font-size-14;
    font-weight: normal;
    margin-left: 6px;
  }

  .locationTextDisabled {
    color: $color-gray-dark-secondary;
    font-size: $font-size-14;
    font-weight: normal;
    margin-left: 6px;
  }

  .arrow {
    bottom: 22px;
    height: 4px;
    position: absolute;
    right: 20px;
    width: 7px;
  }

  .arrowDisabled {
    bottom: 22px;
    color: $color-gray-dark-secondary;
    height: 4px;
    position: absolute;
    right: 20px;
    width: 7px;
  }
}

.selectInput {
  cursor: pointer !important;
  width: 100%;

  :global(.ant-select-selection-item) {
    display: none !important;
  }

  :global(.ant-select-selector) {
    input {
      cursor: pointer !important;
    }

    :global(.ant-select-arrow) {
      right: 15px !important;
    }
  }

  :global(.ant-select-selector) {
    border: 0 !important;
    box-shadow: none !important;
    cursor: pointer !important;
    padding: 0 !important;
  }

  :global(.ant-select-selection-item) {
    align-items: center;
    display: flex;
    justify-content: flex-start;
  }

  :global(.ant-select-item-option-content) {
    align-items: center !important;
    display: flex !important;
    justify-content: flex-start !important;

    svg {
      height: 20px !important;
      width: 20px !important;
    }
  }
}

.dropdown {
  :global(.ant-select-item-option-content) {
    align-items: center !important;
    display: flex !important;
    justify-content: flex-start !important;

    svg {
      height: 20px !important;
      width: 20px !important;
    }
  }

  :global(.ant-select-item-option-state) {
    display: none !important;
  }

  :global(.ant-select-item-option-selected:not(.ant-select-item-option-disabled)) {
    background-color: #fff !important;
  }
}

.checkbox {
  margin-right: 10px;

  :global(.ant-checkbox-wrapper:hover) {
    border-color: $color-purple-medium;
  }

  :global(.ant-checkbox-checked) {
    :global(.ant-checkbox-inner) {
      background-color: $color-purple-medium;
      border-color: $color-purple-medium;
    }
  }

 

  &:focus {
    border-color: $color-purple-medium !important;
    box-shadow: none !important;
  }

  &:hover {
    border-color: $color-purple-medium !important;
    box-shadow: none !important;
  }
}

.quotaLimitTooltip {
  background: #24262a;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  left: -295px;
  line-height: 22px;
  padding: 10px;
  position: absolute;
  right: 180px;
  top: 42px;
  z-index: 999;
}

.organicTrafficValue {
  color: #121212;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: right;
}

.dropdownTopDiv {
  color: #121212;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  justify-content: space-between;
  line-height: 14px;
  padding: 10px 15px 10px 35px;
  width: 100%;
}
