.noDataBanner {
  background-color: #f9f9fb;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  display: flex;
  margin: 25px;
  padding: 15px;

  span {
    color: #121212;
    display: flex;
    flex-direction: column;

    a,
    .supportLink {
      color: #4881f0;
      cursor: pointer;
      display: block;
      text-decoration: underline;
    }
  }

  span:first-child {
    padding-right: 15px;
  }

  img {
    height: 32px;
    margin-top: 4px;
    width: 32px;
  }
}
