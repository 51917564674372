@import 'src/style/colors';
@import 'src/style/fonts';

.dropdownContainer {
  max-width: 200px !important;
  width: auto !important;
}

.selectContainer {
  .selectedItemContainer {
    :global(.ant-select-selection-item) {
      div {
        align-items: center;
        display: flex;
        min-width: 145px !important;
        width: 145px !important;

        .locationName {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis !important;
          white-space: nowrap !important;
          width: 100px;
        }
      }  
    }
  }
}
