@import 'src/style/colors';
@import 'src/style/fonts';

.sidebarLoader {
  height: 100%;
  text-align: center;
  width: 100%;

  h3 {
    font-size: $font-size-14;
    font-weight: 500;
    margin: 40px 0 20px;
  }

  .loaderSpinner {
    animation: spin 2s linear infinite;
    border: 2px solid $color-border-medium;
    border-radius: 50%;
    border-top: 2px solid $color-purple-medium;
    height: 25px;
    margin: auto;
    width: 25px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
