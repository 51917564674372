@import '@/style/colors';
@import 'src/style/fonts';

.modal {
  height: 820px !important;
  width: 760px !important;

  button:nth-child(1) {
    display: none;
  }

  button:nth-child(2) {
    background-color: #008000;
    border-radius: 8px;
    height: 44px;
    width: 142.25px;
  }
}

.inputField {
  height: 38px !important;
}

.timeField {
  border: 1.5px solid #9b9b9b6b !important;
  border-radius: 8px;
  padding: 0;

  :global(.ant-picker-input) {
    margin: 0 0 0 2px;
  }

  &:global(.ant-picker-focused) {
    box-shadow: unset;

    :global(.ant-picker-active-bar) {
      display: none !important;
      opacity: 0 !important;
    }
  }

  :global(.ant-picker-suffix) {
    display: none !important;
  }

  :global(.ant-picker-range-separator) {
    display: none !important;
  }

  :global(.ant-picker-borderless) {
    border: 1px solid #00000005 !important;
    gap: 4px !important;
  }

  :global(.ant-picker-input) {
    height: 30px;
    padding-right: 5px;

    &:first-child {
      border-right: 1.5px solid #9b9b9b6b !important;

      padding-left: 5px;
      padding-right: 0;
    }
  }
}

.selectItem {
  :global(.ant-select-selector) {
    border-radius: 8px !important;
  }
}

.formwrapper {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 24px;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(138, 138, 138, 0.32);
  }

  &::-webkit-scrollbar-track {
    background: rgba(138, 138, 138, 0.12);
  }

  .emailWrapper {
    color: #4e5156;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 15px;

    .email {
      color: #121212;
      margin-left: 15px;
    }
  }

  .amountWrapper {
    color: #4e5156;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    justify-content: flex-end;
    margin-top: 31px;
    padding-right: 115px;
    width: 100%;
    @media screen and (max-width: 500px) {
      align-items: center;
    }

    .total {
      color: #121212;
      font-size: 14px;
      font-weight: 700;
      margin-left: 5px;
    }
  }

  .description {
    color: #4e5156;
    font-size: 12px;
    font-weight: 400;
    margin-top: 15px;
    
    img {
      margin-right: 5px;
    }
  }

  :global(.ant-form) {
    display: flex;
    flex-direction: column;
    min-height: 530px;
  }

  .label {
    color: #4e5156;
    font-size: 12px;
    font-weight: 400;
  }

  :global(.ant-form-item) {
    margin-bottom: 0;

    :global(.ant-form-item-label) {
      padding-bottom: 5px;

      :global(.label) {
        color: #4e5156;
        font-size: 12px;
        font-weight: 400;
      }

      :global(.ant-form-item-required) {
        &::before {
          display: none !important;
        }
      }
    }

    &:global(.ant-form-item-with-help) {
      :global(.ant-form-item-explain) {
        min-height: auto;

        :global(.ant-form-item-explain-error) {
          font-size: 10px;
          font-weight: 500;
        }
      }
    }
  }

  :global(.ant-select) {
    :global(.ant-select-selector) {
      border: 1px solid #d2d2d2;
      border-radius: 8px;
      box-shadow: 0 3px 2px 0 #00000005;
      min-height: 36px;
      padding: 2px 5px;

      :global(.ant-select-selection-item) {
        align-items: center;
        color: rgba(0, 0, 0, 0.85);
        display: flex;
        font-size: 12px;
        padding: 4px 11px;
        // line-height: 1.5715;
      }
    }

    &:hover,
    &:global(.ant-select-focused) {
      :global(.ant-select-selector) {
        border: 1px solid #d2d2d2 !important;
        border-radius: 8px;
        box-shadow: 0 3px 2px 0 #00000005 !important;
      }
    }
  }

  .countryDropdown {
    :global(.ant-select-selector) {
      border-radius: 8px !important;
      max-height: 32px !important;
      min-height: 32px !important;
    }
  }

  :global(.ant-picker) {
    border: 1px solid #d2d2d2;
    border-radius: 8px;
    box-shadow: 0 3px 2px 0 #00000005;
    width: 100%;
  }

  :global(.ant-picker-focused) {
    box-shadow: none;
  }

  .workingHours {
    margin-top: 10px;

    :global(.ant-select) {
      :global(.ant-select-selector) {
        min-height: 32px;

        :global(.ant-select-selection-item) {
          padding: 4px 5px;
        }
      }
    }
  }

  .modalPaymentCard {
    .label {
      align-items: center;
      display: flex;
      gap: 20px;
      justify-content: space-between;
      width: 100%;

      .extraConten {
        display: flex;
      }
    }

    .cardStyle {
      display: flex;
      flex-wrap: wrap;
      @media screen and (max-width: 500px) {
        column-gap: 20px;
      }

      .cardNumberStyle {
        background-color: #fff;
        background-position: 98%;
        background-repeat: no-repeat;
        border: 1px solid $color-white-secondary;
        border-radius: 5px;
        box-sizing: border-box;
        height: 38px;
        margin-top: 8px;
        padding-left: 10px;
        padding-right: 5px;
        padding-top: 10px;
      }
    
      .cardInputStyle {
        background: $color-white-primary;
        border: 1px solid $color-white-secondary;
        border-radius: 5px;
        box-sizing: border-box;
        height: 38px;
        margin-top: 8px;
        padding-left: 10px;
        padding-top: 11px;
      }

      .cardNumber {
        color: $color-gray-dark-secondary;
        flex-grow: 1;
        font-size: $font-size-12;
        line-height: 20px;
        padding-bottom: 8px;
        @media screen and (max-width: 500px) {
          padding-bottom: 2px;
        }
      }

      .cardExpiry,
      .cardCvc {
        width: 20%;
      }
    }
  }
}

:global(.ant-select-dropdown) {
  &.customSelect {
    :global(.ant-select-item) {
      padding: 4px 6px;

      :global(.ant-select-item-option-content) {
        font-size: 11px !important;
      }
    }
  }
}

.paymentCard {
  align-items: center;
  background: $color-white-primary;
  border: 1px solid $color-white-secondary;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  height: 63px;
  justify-content: center;
  margin: 6px;
  width: 273px;
  @media screen and (max-width: 500px) {
    width: 100%;
  }

  &.newPaymentCard {
    border: 1px dashed $color-white-secondary;
  }

  &.paymentCardActive {
    background: $color-white;
    border: 1px solid #b98ce4;
    box-shadow: 0 6px 17px rgba(0, 0, 0, 0.07);
    box-sizing: border-box;
  }


  :global(.ant-radio-wrapper) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: 15px;
    margin-right: 0;
    width: 225px;
  }

  :global(.ant-radio) {
    top: 0;
  }

  :global(.ant-radio-checked .ant-radio-inner) {
    border-color: $color-purple-medium !important;
  }

  :global(.ant-radio-checked .ant-radio-inner::after) {
    background-color: $color-purple-medium;
  }

  :global(.ant-radio:hover .ant-radio-inner) {
    border-color: $color-purple-medium;
  }

  .paymentDetail {

    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 180px;


    h2 {
      color: $color-gray-light;
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      margin-bottom: 0;
    }

    img {
      height: 32px;
      width: 32px;
    }

    .paymentInfo {
      display: flex;
      flex-direction: column;


      span {
        color: $color-gray-dark;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }

      p {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;

        span {
          color: $color-gray-dark-secondary;
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }
  }
}

.inputIcon {
  align-items: center;
  color: #a3a4a4;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 15px;
  width: 40px;

  &:hover {
    cursor: pointer;
  }
}

.inputIconLeft {
  color: #a3a4a4;
  width: 40px;

  &:hover {
    cursor: pointer;
  }
}

.labelBottom {
  color: rgba(163, 164, 164, 1);
  font-size: $font-size-14;
  font-weight: normal;
  padding-right: 10px;
  white-space: nowrap;
}

.removeColon {
  :global(.ant-form-item-label > label::after) {
    content: none !important;
  }
}

.input {
  background: $color-white-primary;
  border: 1px solid $color-white-secondary;
  border-radius: 5px;
  box-sizing: border-box;

  &Select {
    background: $color-white-primary;
    border: 1px solid $color-white-secondary;
    border-radius: 5px;
    box-sizing: border-box;
    display: block;
    height: 42px;
    padding-left: 15px;
    width: 100%;
  }
}

.selectContainer {
  .selectInput {
    color: $color-white;

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-right-width: 0 !important;
    }

    svg {
      height: 16px;
    }

    :global(.ant-select-show-arrow) {
      &:focus,
      &:active,
      &:hover {
        box-shadow: none;
        outline: none;
      }
    }

    :global(.ant-select-focused) {
      &:focus,
      &:active,
      &:hover {
        box-shadow: none;
        outline: none;
      }
    }

    :global(.ant-select-selector) {
      align-items: center;
      background: $color-white-primary;
      border: 1px solid $color-white-secondary;
      border-radius: 5px;
      box-sizing: border-box;
      display: flex;
      height: 42px;
      padding-left: 15px;
      width: 100%;

      &:focus,
      &:active,
      &:hover {
        box-shadow: none;
        outline: none;
      }
    }

    :global(.ant-select-arrow) {
      color: rgba(78, 81, 86, 1);
      top: 46%;
    }

    :global(.ant-select-selection-item) {
      align-items: center;
      color: rgba(78, 81, 86, 1);
      display: flex;
    }
  }
}

.ant-select-item-option-content {
  svg {
    height: 16px;
  }
}

.globeContainer {
  align-items: center;
  display: flex;
}

.globeIcon {
  padding-right: 3px;
  width: 20px;
}

.heading {
  display: flex;
  margin-top: 35px;

  span {
    align-items: center;
    background: #282a2e;
    border-radius: 50%;
    color: $color-white;
    display: flex;
    height: 23px;
    justify-content: center;
    width: 23px;
  }

  h1 {
    color: $color-gray-dark;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin-left: 15px;
  }
}

.content {
  margin-left: 35px;
}

.cardStyle {
  display: flex;
  @media screen and (max-width: 500px) {
    margin-top: 10px;
  }

  label {
    color: #4e5156;
    font-size: 12px;
    line-height: 20px;
    width: 110px;
  }

  .cardNumber {
    margin-right: 20px;
    width: 50%;
    @media screen and (max-width: 500px) {
      margin-right: 0;
    }
  }

  .cardExpiry {
    margin-right: 20px;
    width: 22%;
    @media screen and (max-width: 500px) {
      margin-right: 0;
      min-width: 80px !important;
    }
  }

  .cardCvc {
    width: 20%;
  }
}

.breadCrumb {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}

.quoteimg {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}

.quoteContent {
  border-top: 1px solid #e5e5e5;
  margin-bottom: 40px;
  margin-top: 40px;
  padding-top: 35px;
  position: relative;
}

.extraConten {
  align-items: baseline;
  display: flex;

  .gearIcon {
    border-left: 2px solid #ddd;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 5px;

    span {
      font-size: 15px !important;
    }
  }
}

.notLoggedContainer {
  margin-bottom: 100px;
  margin-top: 15px;

  .loginHeading {
    color: #121212;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }

  .loginText {
    color: #121212;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 17px;
    margin-top: 5px;

    span {
      color: #2d6cca;
      cursor: pointer;
      text-decoration-line: underline;
    }
  }
}

.addressItem {
  .addressNote {
    align-items: center;
    color: #4e5156;
    display: flex;
    font-size: 12px;
    gap: 4px;
    padding-top: 4px;
  }

  p {
    margin-bottom: 0;
  }
}

