.overlay {
  :global(.ant-popover-arrow) {
    display: none !important;
  }
}

.keywordInputTooltip {
  :global(.ant-tooltip-arrow-content) {
    --antd-arrow-background-color: #fff;
  }
}
