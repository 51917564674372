@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/components/common-components/scss/mixins.scroll';

.input {
  background-color: $color-white !important;
  border: 1px solid $color-light-gray-third !important;
}

.generateIdeasButton {
  height: 38px !important;
}


.termsToIncludeContainer {
  @include scrollbars(5px, rgba(138, 138, 138, 0.32), rgba(138, 138, 138, 0.12));
  // margin-top: 20px;
  overflow-y: auto;
}

.numberInput {
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.02);
  height: 24px;
  min-width: 55px;
  padding-left: 10px;

  &:focus-visible {
    outline: none !important;
  }
}


.tableOuterTerms {
  background-color: $color-white;
  padding-bottom: 0 !important;


  &.roundEdgesTable {
    border-radius: 10px;
  }
 

  :global(.ant-table-body) {
    @include scrollbars(5px, rgba(138, 138, 138, 0.32), rgba(138, 138, 138, 0.12));
    max-height: 175px !important;
    overflow: auto auto !important;
  }


  table tr th {
    background-color: #fff;
  }

  .ant-table-content {
    border-radius: 0 !important;
  }



  table {
    th::before {
      width: 0 !important;
    }

    :global(.ant-table-container) {
      padding: 5px;
    }

    :global(.ant-table-column-sorter) {
      display: flex;
      padding-left: 3px !important;
    }

    :global(.ant-table-column-sorters) {
      display: inline-flex !important;
      padding: 5px !important;
    }

    tr td {
      /*   padding-right: 20px !important; */

      &:first-child {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
    }

    :global(.ant-table-tbody) {
      tr:first-child {
        height: 0;

        td {
          border-bottom: 0 solid 'transparent' !important;
        }
      }

      tr:nth-child(even) {
        td {
          background: $color-white !important;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:hover {
          button {
            background: #eef4fe !important;
            border: 0 !important;
            color: #2d6cca;
            font-weight: 500;
          }
        }

        &.purpleRow {
          &:hover {
            :global(button) {
              background-color: transparent !important;
            }
          }
        } 
      }

      tr:nth-child(odd) {
        td {
          background: $color-white !important;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:hover {
          button {
            background: #eef4fe !important;
            border: 0 !important;
            color: #2d6cca;
            font-weight: 500;
          }
        }

        &.purpleRow {
          &:hover {
            :global(button) {
              background-color: transparent !important;
            }
          }
        } 
      }
    }

    :global(.ant-table-thead) {
      background: #f7f7f7 !important;
      border-radius: 8px;

      :global(.ant-table-column-has-sorters) {
        // text-align: right !important;
  
        :global(.ant-table-column-title) {
          text-align: center;
          z-index: 0 !important;
        }
      }

      th {
        background: #f7f7f7 !important;
        color: #121212 !important;
        font-size: $font-size-10 !important;
        font-weight: 600;
        line-height: 14px;
        padding: 0 !important;
      }

      :global(.ant-table-cell-ellipsis) {
        &:first-child {
          padding-left: 20px !important;
        }
      }


      :global(.ant-table-cell-fix-left) {
        text-align: left !important;

        &:first-child {
          border-bottom-left-radius: 8px !important;
          border-top-left-radius: 8px !important;
        }
      }

      th:first-child {
        background: #f7f7f7 !important;
        border-bottom-left-radius: 8px !important;
        border-top-left-radius: 8px !important;
        color: #121212;
        font-size: $font-size-12;
        font-weight: 600;
      }

      th:nth-child(2) {
        background: #f7f7f7 !important;
        color: #121212;
        font-size: $font-size-12;
        font-weight: 600;
      }

      th:nth-child(3) {
        background: #f7f7f7 !important;
        color: #121212;
        font-size: $font-size-12;
        font-weight: 600;
        text-align: center;
      }

      th:last-child {
        background-color: #f7f7f7 !important;
        border-bottom-right-radius: 8px !important;
        border-top-right-radius: 8px !important;
        padding-left: 25px !important;
      }
    }

    :global(.ant-table-cell) {
      white-space: pre-wrap;
    }

    :global(.ant-table-cell-fix-left-last) {
      text-align: left !important;
      // background: $color-white !important;
    }

    tr:first-child td {
      background: $color-white !important;
    }

    tr:nth-child(even) td {
      background: $color-white-primary !important;
    }

    tr:nth-child(odd) td {
      background-color: $color-white !important;
    }

    tr th {
      height: 40pxpx !important;
      line-height: 22px;
      white-space: nowrap;
    }

    :global(.ant-column-sorters) {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    tr td {
      padding-bottom: 15px;
      text-align: center;
    }

    tr th:first-child,
    tr td:first-child {
      padding-left: 25px !important;
      padding-right: 25px !important;
    }

    tr th:last-child,
    tr td:last-child {
      padding-left: 0 !important;
      padding-right: 25px !important;
      text-align: center;
    }
  }
}


