.serviceHealthWrapper {
  align-items: center;
  background: #f07642;
  display: flex;
  height: 46px;
  justify-content: space-between;
  padding-bottom: 7px;
  padding-inline: 26.25px;
  padding-top: 7px;
  width: 100%;
}

.xicon {
  height: 31px;
  width: 27px;
}

.icon {
  cursor: pointer;
}

.main {
  align-items: center;
  display: flex;
  gap: 12px;
}


.heading {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  margin-bottom: 0;
}

.subHeading {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  margin-bottom: 0;

  a {
    color: #fff;
    line-height: 14.52px;
    text-decoration: underline;
  }
}
