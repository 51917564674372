.rootClass {
  :global(.ant-popover-inner) {
    &::-webkit-scrollbar {
      border-radius: 10px;
      width: 7px;
    }
  
    &::-webkit-scrollbar-track {
      background: #d3d3d3;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
    }
   
    &::-webkit-scrollbar-thumb {
      background: #888; 
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background: #555; 
    }
  }
}
