@import 'src/style/colors';

.container {
  //padding: 40px;
}

.Modal {
  max-width: 550px;
  z-index: 3;

  :global(.rc-dialog-header) {
    background-color: $color-body-background;
    padding: 35px 0 0;
  }

  :global(.rc-dialog-body) {
    background-color: $color-body-background;
    border-radius: 0 0 10px 10px;
    padding: 0 25px 25px;
  }

  :global(.rc-dialog-close) {
    color: $color-white;
    font-size: 27px;
    opacity: 1;
    outline: none;
    right: -30px;
    top: -20px;
    @media only screen and (max-width: 768px) {
      color: $color-black-medium;
      right: 6px;
      top: 6px;
    }
  }
}
