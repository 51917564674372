@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/components/common-components/scss/mixins.scroll';

.ContentToolsDrawer {
  // DO NOT REMOVE TRANSFORM FROM HERE, IT WILL BREAK DRAG AND DROP
  transform: none !important;

  :global(.ant-drawer-body) {
    padding: 0 !important;
  }

  .contentIdeasDrawerContent {
    align-items: center;
    display: flex;
    margin-bottom: 15px;
  }


  .contentIdeasTitle {
    align-items: center;
    color: #121212;
    display: flex;
    font-size: 20px;
    font-weight: 600;
  }

  :global(.ant-drawer-close) {
    color: $color-black;
    opacity: 0.5;
    position: absolute;
    right: 630px;
    top: 25px;
    z-index: 1;

    &:hover {
      opacity: 1;
    }

    svg {
      font-size: 30px;
    }
  }

  :global(.ant-drawer-header) {
    background-color: rgb(242, 242, 245) !important;
    padding: 0;
  }

  :global(.ant-drawer-content) {
    background-color: #f9f9fb;
    overflow: visible !important;
    // padding: 30px;
  }

  .closeIcons {
    font-size: 20px;
    right: 0;
  }

  
  .outlineDrawerBackArrow {
    cursor: pointer;
  }

  .contentGeneratorTitle {
    color: $color-black;
    font-size: $font-size-20;
    font-weight: 600;
    margin-top: 20px;
  }
}

.tabsContainer {
  margin-top: 30px;

  :global(.rc-tabs-nav) {
    background-color: transparent !important;
    height: 59px;
    padding: 0;
    padding-top: 6px;

  }

  :global(.rc-tabs-tab-btn) {
    color: #4e5156 !important;
    font-size: 1rem !important;
    font-weight: normal;
    line-height: 26px;

    padding: unset !important;
  }

  :global(.rc-tabs-nav-wrap) {
    border-bottom: 1px solid #e8e8ea;
  }

  :global(.rc-tabs-content-holder) {
    margin-top: 20px;
  }

  :global(.rc-tabs-tabpane) {
    background-color: #f9f9fb;
  }

  :global(.rc-tabs-ink-bar) {
    background-color: $color-blue !important;
  }
}

.settingsPopover {
  :global(.ant-popover-arrow) {
    display: none !important;
  }

  :global(.ant-popover-inner) {
    border: 1px solid #e8e8e8;
    border-radius: 6px;
  }
}


