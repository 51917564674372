@import '@/style/colors';
@import 'src/style/fonts';

.container {
  height: auto;

  .flexContainer {
    border-top: 1px solid #e8e8e8;
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
  }

  .padded {
    padding-left: 20px;
  }

  .Place {
    &::placeholder {
      color: $color-red;
    }
  }

  .ActionButtonsContainer {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
    height: 90px;
    margin-top: 35px;
    position: absolute;
    right: 0;
    top: 10px;
    width: 200px;
    z-index: 1;

    button {
      flex-grow: 1;
    }
  }


  .RowDiv {
    display: flex;
    width: 100%;

    .rowdivInner {
      width: 100%;

      .RowDivtext {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        padding-right: 5px;
        width: 100%;

        p {
          color: #4e5156;
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
        }

      }

      .url {
        font-size: 14px;
        line-height: 14px;
      }
    }

    button {
      background: #eef4fe;
      border-radius: 4px;
      color: #2d6cca;
      font-size: 14px;
      font-weight: 500;
      height: 32px;
      line-height: 17px;
      width: 86px;
    }

    &.disableProject {
      p {
        color: #a3a4a4 !important;
      }

      img {
        background: #f9f9fb !important;
        opacity: 0.2 !important;
      }

      .ComplitedIcon {
        opacity: 0.3 !important;
      }

      .dot {
        background: #a3a4a4 !important;
      }
    }
  }

  .DropStyle {
    margin-top: -20px;
    padding-bottom: 20px;
  }

  .SmallMoreOutline {
    cursor: pointer;
    font-size: 14px;
  }

  .BigMoreOutline {
    cursor: pointer;
    font-size: 16px;
  }

  .MinusOutline {
    cursor: pointer;
    padding-left: 10px;
  }

  .ActionButtonsContainerWebsite {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
    height: 45px;
    margin-top: 35px;
    position: absolute;
    right: 60px;
    width: 200px;

    button {
      flex-grow: 1;
    }
  }

  .RefetchBtn,
  .DisconnectBtn {
    background: transparent;
    font-size: $font-size-14;
    transition: all 0.2s ease;

    &.RefetchBtn {
      color: #121212;
      height: 50%;
      width: 100%;
    }

    &.DisconnectBtn {
      color: #fe6767;
      height: 50%;
      width: 100%;
    }
  }

  :global(.ant-table-container table > thead > tr:first-child th:first-child) {
    padding-left: 34px;
  }

  .bordered {
    border-bottom: 1px solid $color-border;
  }

  .location {
    :global(.ant-select-selector) {
      border: 1px solid #e8e8e8 !important;
      border-radius: 4px !important;
      color: #686868 !important;
      font-size: 12px !important;
      font-weight: 500 !important;
      height: 40px !important;
      margin: 1px !important;
      padding: 0 10px !important;

      &:hover {
        border-color: #7f4ead !important;
        box-shadow: none !important;
      }

      &:visited {
        border-color: #7f4ead !important;
        box-shadow: none !important;
      }

      &:target {
        border-color: #7f4ead !important;
        box-shadow: none !important;
      }
  
      &:focus {
        border-color: #7f4ead !important;
        box-shadow: none !important;
      }

      &:active {
        border-color: #7f4ead !important;
        border-right-width: 1px !important;
        box-shadow: none !important;
        outline: 0 !important;
      }
    }

  }

  .wrapper {
    align-items: center;
    display: flex;
    gap: 10px;

    .infoIcon {
      cursor: pointer;
    }
  }

  .contentTopMargin {
    margin-top: 1em;
  }

  .content {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.03);
    margin-top: 25px;
    padding: 25px 0;

    .title {
      color: $color-gray-dark;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    label {
      color: $color-gray-dark-secondary;
      font-size: 12px;
    }

    input {
      background-color: $color-white-primary;
      border: 1px solid $color-light-gray-third;
      color: #686868;
      font-weight: 400;
      height: 40px;
      width: 100%;
    }

    :global(.flag-dropdown) {
      border: 1px solid $color-light-gray-third;
      border-right-width: 70%;
    }

    input:hover {
      border-color: $color-purple-medium;
      box-shadow: none;
    }

    input:hover + :global(.flag-dropdown) {
      border-bottom-color: $color-purple-medium;
      border-left-color: $color-purple-medium;
      border-top-color: $color-purple-medium;
    }

    input:focus {
      border-color: $color-purple-medium;
      box-shadow: none;
    }

    input:focus + :global(.flag-dropdown) {
      border-bottom-color: $color-purple-medium;
      border-left-color: $color-purple-medium;
      border-top-color: $color-purple-medium;
    }

    :global(.ant-form-item-required)::before {
      display: none !important;
    }

    .passIcon {
      float: right;
      margin-right: 1em;
      margin-top: -4em;
      position: relative;
    }

    .errMsg {
      bottom: 0;
      color: $color-red;
      font-size: 12px;
      position: absolute;
    }

    :global(.common-component-button) {
      font-size: 14px !important;
      margin-top: 19px;
    }

    :global(.common-component-button.disabled) {
      opacity: 0.6 !important;
    }

    .detailTitle {
      color: #121212;
      font-size: $font-size-14;
      font-weight: 400;
    }

    .detail {
      color: #8d8d91;
      font-weight: 300;

      .danger {
        color: #ff7271;
        cursor: pointer;
      }

      .info {
        color: #8150ae;
        text-decoration: none;
      }
    }

    .badge {
      sup {
        background: $color-purple-medium;
        font-size: $font-size-12;
        font-weight: 500;
        margin-right: 1.3em;
        margin-top: 4.6em;
      }
    }

    .formView {
      display: flex;
      justify-content: space-between;

      .panelLeft {
        width: 49%;

        .disabledInput {
          border: 0;
          cursor: not-allowed !important;
        }
      }

      .panelRight {
        width: 49%;
      }

      label {
        color: $color-secondary-text;
        font-weight: 500;
        padding: 20px 0 10px;
      }

      .select {
        width: 100%;

        :global(.ant-select-selector) {
          border-color: $color-purple-medium;
          border-radius: 5px;
          box-shadow: none;
          height: 2.8em;
          padding: 4px 11px;
        }
      }
    }
  }

  .profilesTable {
    margin-bottom: 3.6em;
  }

  .Tabs {
    height: 100%;

    :global(.rc-tabs-nav-more) {
      background-color: rgb(242, 242, 245) !important;
      color: #4e5156;
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 0;
    };

    :global(.rc-tabs-nav) {
      background: transparent;
      background: $color-body-background;
      padding-left: 0;
      padding-top: 0;
    }

    :global(.rc-tabs-ink-bar-animated) {
      transition: none !important;
    }

    :global(.rc-tabs-content-holder) {
      background: $color-body-background;
    }

    :global(.rc-tabs) {
      height: 100%;
    }

    :global(.rc-tabs-tab-active) {
      :global(p) {
        color: $color-gray-dark;

        font-weight: 500;
      }
    }

    :global(.rc-tabs-tab-btn) {
      background: none !important;
      padding: 0 !important;
    }

    :global(.rc-tabs-nav-wrap) {
      border-bottom: 1px solid #dfdfdf;
      height: 100%;
    }

    :global(.rc-tabs-tab) {
      background: transparent;
      border-bottom: 1px solid $color-border;
      margin-right: 25px;
    }

    :global(.rc-tabs-content) {
      background: transparent;
    }

    p {
      color: $color-gray-dark-secondary;
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 0;
    }
  }

  .GscIcon {
    svg {
      height: 18px;
      width: 18px;
    }
  }

  .selectedTab {
    color: $color-purple-medium !important;
  }

  .selectedIcon {
    padding: 0;

    svg {
      stroke: $color-purple-medium;
    }
  }

  .selectedGscIcon {
    /*   padding: 0 15px 0 10px; */

    svg {
      path {
        fill: $color-purple-medium;
      }
    }
  }
}

.dropDown {
  background: #fff;

  :global(.ant-select-item-option-selected) {
    background: $color-purple-medium !important;

    :global(.ant-select-item-option-content) {
      color: #fff !important;
    }
  }
}

.dropDownContainer {
  p {
    color: #98989c !important;
    font-weight: 300;
  }
}

.TabPanContainer {
  background: #fff;
  height: 100%;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }
          
  &::-webkit-scrollbar-thumb {
    background: rgba(138, 138, 138, 0.32);
  }
          
  &::-webkit-scrollbar-track {
    background: rgba(138, 138, 138, 0.12);
  }
}

.SitesIconContainer {
  margin-left: 3em;
}

.SitesCollapsable {
  color: #807c85 !important;
  padding: 15px;
}

.container {
  background-color: #fff !important;
  margin-top: 5px;
  padding: 0 !important;
}

.WrapperDiv {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.03);
  margin-top: 15px;
  padding: 15px;

  ::placeholder {
    background: #fff !important;
  }

  :global(.common-component-input) {
    background: #fff !important;
  }

  a {
    align-items: center;
    display: flex;
  }
}

.ProfileDiv {
  background: #f7f7f7;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 10px;

}

.LinkBtn {
  width: max-content;

}

.disabled {
  color: #8d8d91;
  cursor: not-allowed !important;
  width: 200px;
}

.ProgressIconContainer {
  height: 12px;
  position: relative;
  width: 12px;
}

.ComplitedIcon,
.InProgressIcon {
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  &.ComplitedIcon {
    background-image: url('/img/google-color.svg'),;
    height: 100%;
  }

  &.InProgressIcon {
    background-image: url('/img/google-gray.svg'),;
  }
}

.dot {
  background: $color-secondary-text !important;
  border-radius: 50%;
  display: inline-block;
  height: 5px;
  margin-left: 5px;
  margin-right: 5px;
  width: 5px;

}

.DropDownSites {
  box-shadow: 0 1px 0 #f4f4f4;
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  padding-top: 12px;

  img {
    height: 30px;
    margin-right: 7px;
    width: 30px;

  }

  p {
    color: #121212;
    font-size: 14px;
    font-weight: 500;
  }
}

.Modal {
  margin-top: 450px;

  :global(.rc-dialog-mask) {
    background-color: rgba(25, 26, 29, 0.1);
  }

  :global(.rc-dialog-content) {
    border-radius: 8px;
    box-shadow: 0 4px 21px rgba(0, 0, 0, 0.02);
  }
}

.ConfirmationModal {
  display: block;
  padding-bottom: 25px;

  .ConfirmationGroupName {
    max-width: 100%;
    overflow-wrap: break-word;
  }
}

.Danger {
  background: $color-red;
  color: #ffebea;
  margin-left: 10px;
  width: auto;

  &:hover {
    background: $color-red !important;
    color: #fff !important;
  }
}

.Back {
  background: transparent;
  border: 1px solid #6b6979;
  color: #6b6979;
  width: auto;

  &:hover {
    background: transparent;
  }
}

.ButtonDisplay {
  border-radius: 0 0 5px 5px;
  box-shadow: 0 -1px 0 #ddd, 0 -3px 10px rgba(0, 0, 0, 0.02);
  display: flex;
  justify-content: flex-end;
  padding-top: 25px;
}

.warningTooltip {
  :global(.ant-tooltip-content) {
    :global(.ant-tooltip-inner) {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), $color-gray-dark-medium;
      border-radius: 8px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.08);
      padding: 15px;
      text-align: center;
      width: 240px;

      p {
        color: $color-white;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      button {
        background: $color-blue;
        border-radius: 4px;
        color: $color-white;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
      }
    }
  }
}

.gscIntegration {
  align-items: center;
  background-color: rgba(163, 164, 164, 0.15) !important;

  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 40px;
  width: 100%;

}

.spanStyle { 
  color: #121212;
  text-align: center;
  text-decoration-line: underline;
}

.btnSetup {
  // background: rgba(45, 108, 202, 0.15);
  display: flex;
  justify-content: center;
  margin: 25px 63px;

  .btnFirst {
    background: #2d6cca;
    border-radius: 5px;
    color: #fff;
    margin-right: 14px;
    padding: 8px 20px;
  }

  .btnSecond {
    background: rgba(45, 108, 202, 0.15);
    border-radius: 5px;
    color: #2d6cca;
    margin-right: 14px;
    padding: 8px;
  }

}

.btnFirstLink {
  background: #2d6cca;
  border-radius: 5px;
  color: #fff !important;
  // margin-right: 14px;
}

.paragraph {
  color: #4e5156;
  font-size: 14px;
  margin: 0 63px;
  text-align: center;
}

.setupAccount {
  color: #2d6cca;
  font-size: 14px;
  margin-top: 22px;
  text-align: center;

}

.phoneInput {
  ::placeholder {
    color: rgba(0, 0, 0, 0.14);
  }
}
