@import 'src/style/colors';
@import 'src/style/fonts';

.paymentMethodModal {
  position: relative;
  width: 552px;
  @media screen and (max-width: 570px) {
    width: 92%;

    :global(.rc-dialog-body) {
      padding: 15px;
    }
  }



  :global(.rc-dialog-content) {
    :global(.rc-dialog-close) {
      display: none;
    }
  }

  .container {
    .cardDetail {
      .cardNumberStyle {
        background: url('/img/icon/lock.svg');
        background: $color-white-primary;
        background-position: 98%;
        background-repeat: no-repeat;
        border: 1px solid $color-white-secondary;
        border-radius: 5px;
        box-sizing: border-box;
        height: 38px;
        padding-left: 10px;
        padding-top: 8px;
        @media screen and (max-width: 850px) {
          padding-left: 12px;
          padding-top: 12px;
        }
      }

      .cardInputStyle {
        background: $color-white-primary;
        border: 1px solid $color-white-secondary;
        border-radius: 5px;
        box-sizing: border-box;
        height: 38px;
        padding-left: 10px;
        padding-top: 8px;
        @media screen and (max-width: 850px) {
          padding-top: 12px;
        }

      }

      .cardNumber {
        color: $color-gray-dark-secondary;
        font-size: $font-size-12;
        font-weight: 500;
        line-height: 20px;
      }

      .cardInfo {
        display: flex;
        font-weight: 500;
        justify-content: space-between;
        margin-top: 20px;
        @media screen and (max-width: 570px) {
          gap: 8px;
        }

        label {
          color: $color-gray-dark-secondary;
          font-size: $font-size-12;
          line-height: 20px;
          width: 242px;
        }
      }
    }

    h4 {
      color: #121212;
      font-size: $font-size-20;
      font-weight: 600;
      line-height: 28px;
    }

    .cardLabel {
      color: #4e5156;
      font-size: $font-size-12;
      font-weight: bold;
      line-height: 40px;
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    .cardDescription {
      color: #121212;
      font-size: $font-size-16;
      line-height: 24px;

    }

    .paymentMethodList {
      display: flex;
      justify-content: flex-start;

      button {
        background: $color-white-primary;
        border: 1px solid #e3e3e4;
        border-radius: 5px;
        box-sizing: border-box;
        color: #121212;
        cursor: pointer;
        font-size: $font-size-14;
        font-weight: 500;
        height: 44px;
        line-height: 17px;
        margin-right: 11px;
        width: 116px;

        img {
          margin-right: 8px;
          margin-top: -2px;
        }

        &.activeMethod {
          background: rgba(127, 78, 173, 0.08);
          border: 1px solid #7f4ead !important;
          border-radius: 5px;
          box-sizing: border-box;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .masterCard {
      margin-top: 40px;

      .masterCardLabel {
        color: #4e5156;
        font-size: $font-size-12;
        font-weight: bold;
        line-height: 20px;
        text-transform: uppercase;
      }

      .cardNumber {
        label {
          color: #4e5156;
          font-size: $font-size-12;
          line-height: 20px;
        }

      }

      .cardVerification {
        display: flex;
        justify-content: space-between;
        margin: 25px 0;

        .cardDate {
          width: 298px;

        }

        .cardCVS {
          max-width: 185px;
        }
      }

      input {
        background: $color-white-primary;
        border: 1px solid $color-white-secondary;
        border-radius: 5px;
        box-sizing: border-box;
        height: 44px;
      }
    }

  }

  :global(.ant-form-item-label) {
    text-align: left !important;

    label {
      color: #4e5156 !important;
      font-size: $font-size-12 !important;
      font-weight: 500;
      line-height: 20px !important;

    }
  }

  .cardNumberElement {
    background: $color-white-primary;
    border: 1px solid $color-white-secondary;
    border-radius: 5px;
    box-sizing: border-box;
    height: 38px;
  }

  .cardLockInfo {
    display: flex;
    margin: 25px 0;

    p {
      color: $color-gray-light;
      font-size: $font-size-14;
      line-height: 17px;
      margin-bottom: 0;
      margin-left: 5px;
    }
  }

  .cardFooter {
    display: flex;
    justify-content: flex-end;

    .cardUpdate {
      font-size: $font-size-14;
      line-height: 17px;
      width: 115px;
      @media screen and (max-width: 850px) {
        width: auto;
      }
    }

    .cardCancel {
      border: 1px solid $color-white-secondary;
      border-radius: 5px;
      box-sizing: border-box;
      margin-right: 10px;
    }
  }
}
