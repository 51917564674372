@import '@/style/colors';

@mixin input-default-style {
  background: $color-body-background;
  border-radius: 5px;
  border-width: 0;
  box-sizing: border-box;
  color: $color-secondary-text;
  font-family: Inter;
  height: 38px;
  outline: none;
  padding: 0 9px;
  width: 100%;
}

@mixin textarea-default-style {
  height: auto;
}
