@import 'src/style/colors';
@import 'src/style/fonts';
@import './../../scss/mixins.button';

.button {
  @include button-default-styles;

  &:hover,
  &:focus {
    background-color: #777676;
    color: $color-white;
  }

  &:hover,
  &:focus {
    background-color: #777676;
    color: $color-white;
  }

  &:focus,
  &:active {
    outline: none;
  }

  &.buttonTypePrimary {
    @include button-primary;
  }

  &.buttonSizeLg {
    @include button-size-lg;
  }

  &.buttonSizeSm {
    @include button-size-sm;
  }

  &.buttonSizeXs {
    @include button-size-xs;
  }

  .buttonLoading {
    display: inline-block;
    margin-right: 7px;
    position: relative;
    top: 1px;
  }

  &.disabled {
    @include button-disabled;
  }
}

.buttonList {
  > button {
    margin-right: 10px;
  }
}

.buttonTypeTransparent {
  background: transparent;
  color: $color-secondary-text;

  &:focus,
  &:hover,
  &:active {
    background: transparent;

  }
}

.buttonTypePurple {
  background: $color-purple-medium;

  &:focus,
  &:hover,
  &:active {
    background: transparent;
    border: 1px solid $color-purple-medium;
    color: #cdacee;
  }
}

.outlinePrimary {
  background-color: transparent !important;
  color: #cdacee !important;
  font-family: Inter;
  font-size: $font-size-14;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
}

.buttonTypeGreenOpaque {
  background: #52d5771a !important;
  border: 1px solid #52d577 !important;
  border-radius: 5px;
  color: #fff !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  text-decoration: none !important;
  transition: background 0.3s ease-in-out;

  &:focus,
  &:hover,
  &:active {
    background: darken(#52d5771a, 30%) !important;
  }
}
